import { defineComponent } from 'vue';
export default defineComponent({
    el: '.form-check',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            required: false,
            type: Boolean,
            default: false
        },
        label: {
            required: false,
            type: String,
            default: null
        },
        id: {
            required: false,
            type: String,
            default: null
        },
        name: {
            required: false,
            type: String,
            default: null
        },
        required: {
            required: false,
            type: Boolean,
            default: false
        },
        readOnly: {
            required: false,
            type: Boolean,
            default: false
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        isSwitch: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasLabel() {
            return this.label !== null && this.label !== '';
        },
        computedId() {
            return this.id !== null && this.id !== '' ? this.id : null;
        }
    }
});
