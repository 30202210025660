import { defineComponent } from 'vue';
import WorksheetBuilder from '@/components/document/documentManagement/WorksheetBuilder.vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppCheckbox from '@/components/shared/AppCheckbox.vue';
import DocumentService from '@/services/DocumentService';
import Notifications from '@/hooks/Notifications';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
export default defineComponent({
    emits: ['disableSubmit', 'enableSubmit'],
    components: {
        WorksheetBuilder,
        AppCollapsingCardWithButtons,
        BSButton,
        AppCheckbox
    },
    props: {
        documentId: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            inspectionSheets: null
        };
    },
    methods: {
        loadData() {
            this.$emit('disableSubmit');
            DocumentService.getInspectionSheetsData(this.documentId)
                .then(response => {
                if (response !== null) {
                    this.inspectionSheets = response;
                    this.$emit('enableSubmit');
                }
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            });
        }
    },
    created() {
        this.loadData();
    }
});
