import { UserAccountStatus } from '@/services/models/shared/UserAccountStatus';
import { PagingFilter } from '@/services/models/shared/PagingFilter';
export class UserAccountsFilter extends PagingFilter {
    organizationType;
    organizationId;
    userAccountStatus;
    constructor(returnAll = false) {
        super(returnAll);
        this.organizationType = null;
        this.organizationId = null;
        this.userAccountStatus = UserAccountStatus.any;
    }
    options() {
        return Object.assign({
            organizationType: this.organizationType,
            organizationId: this.organizationId,
            userAccountStatus: this.userAccountStatus
        }, super.options());
    }
}
