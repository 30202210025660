export default {
    install: (app) => {
        const stringEnumUtilities = {
            getKeyFromValue: (enumType, value) => {
                const valueIndex = (Object.values(enumType)).map((val) => {
                    return val.toString().toUpperCase();
                }).indexOf(value.toUpperCase());
                if (valueIndex > -1) {
                    return Object.keys(enumType)[valueIndex];
                }
                return null;
            },
            numberEquals: (enumType, value, enumValue) => {
                const enumValueIndex = Object.values(enumType).indexOf(enumValue);
                return value === enumValueIndex;
            },
            getListOfKeys: (enumType) => {
                return Object.keys(enumType).filter(x => isNaN(parseInt(x)));
            }
        };
        const bitwiseEnumUtilities = {
            hasFlag: (originalValue, flagCheckValue) => {
                if ((originalValue & flagCheckValue) === flagCheckValue) {
                    return true;
                }
                return false;
            },
            getKeyFromValueHasFlag: (enumType, value) => {
                const valueIndex = (Object.values(enumType)).findIndex((val) => {
                    return bitwiseEnumUtilities.hasFlag(value, val);
                });
                if (valueIndex > -1) {
                    return Object.keys(enumType)[valueIndex];
                }
                return null;
            },
            getValueFromValueHasFlag: (enumType, value) => {
                const valueIndex = (Object.values(enumType)).findIndex((val) => {
                    return bitwiseEnumUtilities.hasFlag(value, val);
                });
                if (valueIndex > -1) {
                    return Object.values(enumType)[valueIndex];
                }
                return null;
            }
        };
        app.config.globalProperties.$stringEnumUtilities = stringEnumUtilities;
        app.config.globalProperties.$bitwiseEnumUtilities = bitwiseEnumUtilities;
    }
};
