export var DocumentTypeStatusPermission;
(function (DocumentTypeStatusPermission) {
    DocumentTypeStatusPermission[DocumentTypeStatusPermission["read"] = 0] = "read";
    DocumentTypeStatusPermission[DocumentTypeStatusPermission["readEdit"] = 1] = "readEdit";
    DocumentTypeStatusPermission[DocumentTypeStatusPermission["readWrite"] = 2] = "readWrite";
    DocumentTypeStatusPermission[DocumentTypeStatusPermission["readEditWrite"] = 3] = "readEditWrite";
    DocumentTypeStatusPermission[DocumentTypeStatusPermission["readMove"] = 4] = "readMove";
    DocumentTypeStatusPermission[DocumentTypeStatusPermission["readEditMove"] = 5] = "readEditMove";
    DocumentTypeStatusPermission[DocumentTypeStatusPermission["readWriteMove"] = 6] = "readWriteMove";
    DocumentTypeStatusPermission[DocumentTypeStatusPermission["readEditWriteMove"] = 7] = "readEditWriteMove";
})(DocumentTypeStatusPermission || (DocumentTypeStatusPermission = {}));
export var DocumentTypeStatusOpenPermissionLabel;
(function (DocumentTypeStatusOpenPermissionLabel) {
    DocumentTypeStatusOpenPermissionLabel[DocumentTypeStatusOpenPermissionLabel["View Request"] = 0] = "View Request";
    DocumentTypeStatusOpenPermissionLabel[DocumentTypeStatusOpenPermissionLabel["Edit Request Details"] = 1] = "Edit Request Details";
    DocumentTypeStatusOpenPermissionLabel[DocumentTypeStatusOpenPermissionLabel["Manage Request"] = 2] = "Manage Request";
    DocumentTypeStatusOpenPermissionLabel[DocumentTypeStatusOpenPermissionLabel["Change Request Status"] = 4] = "Change Request Status";
})(DocumentTypeStatusOpenPermissionLabel || (DocumentTypeStatusOpenPermissionLabel = {}));
export var DocumentTypeStatusDraftOpenPermissionOptions;
(function (DocumentTypeStatusDraftOpenPermissionOptions) {
    DocumentTypeStatusDraftOpenPermissionOptions[DocumentTypeStatusDraftOpenPermissionOptions["Edit Request Details"] = 1] = "Edit Request Details";
    DocumentTypeStatusDraftOpenPermissionOptions[DocumentTypeStatusDraftOpenPermissionOptions["Manage Request"] = 2] = "Manage Request";
    DocumentTypeStatusDraftOpenPermissionOptions[DocumentTypeStatusDraftOpenPermissionOptions["Change Request Status"] = 4] = "Change Request Status";
})(DocumentTypeStatusDraftOpenPermissionOptions || (DocumentTypeStatusDraftOpenPermissionOptions = {}));
export var DocumentTypeStatusClosedIgnoredPermissionOptions;
(function (DocumentTypeStatusClosedIgnoredPermissionOptions) {
    DocumentTypeStatusClosedIgnoredPermissionOptions[DocumentTypeStatusClosedIgnoredPermissionOptions["Change Request Status"] = 4] = "Change Request Status";
})(DocumentTypeStatusClosedIgnoredPermissionOptions || (DocumentTypeStatusClosedIgnoredPermissionOptions = {}));
