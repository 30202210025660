export var BackgroundColor;
(function (BackgroundColor) {
    BackgroundColor["primary"] = "bg-primary";
    BackgroundColor["secondary"] = "bg-secondary";
    BackgroundColor["success"] = "bg-success";
    BackgroundColor["danger"] = "bg-danger";
    BackgroundColor["warning"] = "bg-warning";
    BackgroundColor["info"] = "bg-info";
    BackgroundColor["light"] = "bg-light";
    BackgroundColor["dark"] = "bg-dark";
    BackgroundColor["darkblue"] = "bg-darkblue";
    BackgroundColor["maroon"] = "bg-maroon";
    BackgroundColor["darkslategray"] = "bg-darkslategray";
})(BackgroundColor || (BackgroundColor = {}));
