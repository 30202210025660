import { defineComponent } from 'vue';
import BSPagination from '@/components/shared/bootstrap/Pagination.vue';
export default defineComponent({
    components: {
        BSPagination
    },
    emits: ['updatePagination'],
    props: {
        showPageSizeOption: {
            required: false,
            type: Boolean,
            default: true
        },
        page: {
            required: false,
            type: Number,
            default: 1
        },
        pageSize: {
            required: false,
            type: Number,
            default: 10
        },
        totalCount: {
            required: false,
            type: Number,
            default: 0
        },
        pageSizeOptions: {
            required: false,
            type: Object,
            default: [10, 20, 50, 100]
        }
    }
});
