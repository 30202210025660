import { PagingFilter } from '@/services/models/shared/PagingFilter';
export class CaseItemsFilter extends PagingFilter {
    itemNumber;
    itemManufacturer;
    caseId;
    itemDescription;
    serialNumbers;
    includeDeleted;
    constructor(returnAll = false) {
        super(returnAll);
        this.itemNumber = null;
        this.itemManufacturer = null;
        this.caseId = null;
        this.itemDescription = null;
        this.serialNumbers = null;
        this.includeDeleted = false;
    }
    options() {
        return Object.assign({
            itemNumber: this.itemNumber,
            itemManufacturer: this.itemManufacturer,
            caseId: this.caseId,
            itemDescription: this.itemDescription,
            serialNumbers: this.serialNumbers,
            includeDeleted: this.includeDeleted
        }, super.options());
    }
}
