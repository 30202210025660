import { defineComponent } from 'vue';
import SiteHorizontalNavigation from '@/components/layout/SiteHorizontalNavigation.vue';
import SiteVerticalNavigation from '@/components/layout/SiteVerticalNavigation.vue';
import AuthenticationService from '@/services/AuthenticationService';
export default defineComponent({
    components: {
        SiteHorizontalNavigation,
        SiteVerticalNavigation
    },
    props: {
        routeList: {
            required: false,
            default: []
        }
    },
    methods: {
        logout() {
            AuthenticationService.logout();
        }
    }
});
