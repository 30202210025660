export var TextAlignment;
(function (TextAlignment) {
    TextAlignment["start"] = "text-start";
    TextAlignment["center"] = "text-center";
    TextAlignment["end"] = "text-end";
    TextAlignment["smStart"] = "text-sm-start";
    TextAlignment["smCenter"] = "text-sm-center";
    TextAlignment["smEnd"] = "text-sm-end";
    TextAlignment["mdStart"] = "text-md-start";
    TextAlignment["mdCenter"] = "text-md-center";
    TextAlignment["mdEnd"] = "text-md-end";
    TextAlignment["lgStart"] = "text-lg-start";
    TextAlignment["lgCenter"] = "text-lg-center";
    TextAlignment["lgEnd"] = "text-lg-end";
    TextAlignment["xlStart"] = "text-xl-start";
    TextAlignment["xlCenter"] = "text-xl-center";
    TextAlignment["xlEnd"] = "text-xl-end";
})(TextAlignment || (TextAlignment = {}));
