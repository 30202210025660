import { defineComponent } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import AppTextArea from '@/components/shared/AppTextArea.vue';
import BSProgress from '@/components/shared/bootstrap/Progress.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import DocumentService from '@/services/DocumentService';
import Notifications from '@/hooks/Notifications';
export default defineComponent({
    emits: ['uploadCompleted'],
    components: {
        AppTextArea,
        BSProgress,
        BSButton
    },
    props: {
        headerTitle: {
            required: true,
            type: String
        },
        fileInputId: {
            required: true,
            type: String
        },
        notesInputId: {
            required: true,
            type: String
        },
        multiple: {
            required: false,
            type: Boolean,
            default: false
        },
        performSubmissionInComponent: {
            required: false,
            type: Boolean,
            default: false
        },
        documentId: {
            required: false,
            type: String,
            default: ''
        },
        documentAttachmentTypeId: {
            required: false,
            type: String,
            default: ''
        },
        submittedByUserId: {
            required: false,
            type: String,
            default: ''
        },
        areCustomerProvided: {
            required: false,
            type: Boolean,
            default: false
        },
        accept: {
            required: false,
            type: String,
            default: null
        },
        submissionProgress: {
            required: false,
            type: Number,
            default: 0
        },
        isSubmitting: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    computed: {
        progress() {
            if (this.performSubmissionInComponent) {
                return {
                    label: this.internalSubmissionProgress.toString() + '%',
                    valueNow: this.internalSubmissionProgress,
                    valueMin: 0,
                    valueMax: 100
                };
            }
            return {
                label: this.submissionProgress.toString() + '%',
                valueNow: this.submissionProgress,
                valueMin: 0,
                valueMax: 100
            };
        }
    },
    data() {
        return {
            files: [],
            notes: null,
            submitDisabled: false,
            internalSubmissionProgress: 0,
            clearForm: false
        };
    },
    methods: {
        onFilesChanged(e) {
            this.files = e.target.files;
        },
        async uploadAttachments() {
            this.$loadingBackdrop.data.isVisible = true;
            this.submitDisabled = true;
            if (this.files && this.files.length > 0) {
                let indexVal = 1;
                const numOfFiles = this.files.length;
                for (const file of this.files) {
                    var formData = new FormData();
                    if (this.notes && this.notes !== null) {
                        formData.append('notes', this.notes ?? '');
                    }
                    formData.append('documentAttachmentTypeId', this.documentAttachmentTypeId);
                    formData.append('submittedByUserId', this.$store.getters.user.id);
                    formData.append('isCustomerProvided', this.areCustomerProvided.toString());
                    formData.append('file', file);
                    await DocumentService.submitAttachments(this.documentId, formData)
                        .catch(() => {
                        Notifications.methods.addToast(ToastType.danger, file.name + ' could not be uploaded');
                    })
                        .finally(() => {
                        this.internalSubmissionProgress = Math.floor((indexVal / numOfFiles) * 100);
                        indexVal++;
                    });
                }
            }
            this.submitDisabled = false;
            this.internalSubmissionProgress = 0;
            this.notes = null;
            this.clearForm = !this.clearForm;
            this.$loadingBackdrop.data.isVisible = false;
            this.$emit('uploadCompleted');
        }
    }
});
