import AveHttpService from '@/services/AveHttpService';
class DocumentTypeStatusesService {
    /**
     *  @description Retrieves all document types in the application
     */
    getDocumentTypes(filter) {
        return AveHttpService.get('api/v1/documents/types', { params: filter }).then((response) => {
            return response.data;
        });
    }
}
export default new DocumentTypeStatusesService();
