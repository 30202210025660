import { defineComponent, ref } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
import { AcceptedItemVehiclesFilter, AcceptedItemDetailsFilter, AcceptedItemDocumentsFilter } from '@/services/models/AcceptedItem';
import AppTable from '@/components/shared/AppTable.vue';
import AppRadioButtons from '@/components/shared/AppRadioButtons.vue';
import VehiclesService from '@/services/VehiclesService';
import AcceptedItemsService from '@/services/AcceptedItemsService';
import ManufacturersService from '@/services/ManufacturersService';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppSelect from '@/components/shared/AppSelect.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppTextArea from '@/components/shared/AppTextArea.vue';
import AppCheckbox from '@/components/shared/AppCheckbox.vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
var ActiveSection;
(function (ActiveSection) {
    ActiveSection[ActiveSection["details"] = 0] = "details";
    ActiveSection[ActiveSection["vehicles"] = 1] = "vehicles";
    ActiveSection[ActiveSection["documents"] = 2] = "documents";
})(ActiveSection || (ActiveSection = {}));
export default defineComponent({
    name: 'AcceptedItem',
    components: {
        AppTable,
        BSModal,
        BSButton,
        AppSelect,
        AppInput,
        AppTextArea,
        AppCheckbox,
        AppCollapsingCardWithButtons,
        AppRadioButtons
    },
    setup() {
        const editModal = ref();
        return { editModal };
    },
    data() {
        return {
            activeSection: ActiveSection.details,
            acceptedItem: {},
            acceptedItemDetails: [],
            acceptedItemVehicles: [],
            acceptedItemDocuments: [],
            acceptedItemDetailsFilter: new AcceptedItemDetailsFilter(false),
            acceptedItemVehiclesFilter: new AcceptedItemVehiclesFilter(false),
            acceptedItemDocumentsFilter: new AcceptedItemDocumentsFilter(false),
            submitButton: {
                text: 'Save',
                show: true
            },
            cancelButton: {
                text: 'Cancel',
                show: true
            },
            updatedAcceptedItemDetail: null,
            updatedAcceptedItemVehicle: null,
            updatedAcceptedItemDocument: null,
            acceptedItemDetailTypeSelection: [],
            conversionTypeSelection: [{ item: '(None)', value: 0 }, { item: 'Side Entry', value: 1 }, { item: 'Rear Entry', value: 2 }],
            vehicles: [],
            vehicleMakeSelection: [],
            vehicleMakeSelected: null,
            vehicleSelected: null,
            addVehicleYearRange: false,
            vehicleStartYear: null,
            vehicleEndYear: null,
            vehicleYear: null,
            vehicleYears: [],
            editingInformation: false,
            itemManufacturerSelection: [],
            addModalKey: false
        };
    },
    computed: {
        addModalTitle() {
            return this.activeSection === ActiveSection.details ? 'Add Detail' : this.activeSection === ActiveSection.vehicles ? 'Add Vehicle' : this.activeSection === ActiveSection.documents ? 'Add Document' : '';
        },
        editModalTitle() {
            if (this.updatedAcceptedItemDetail !== null && this.activeSection === ActiveSection.details) {
                return 'Edit ' + this.updatedAcceptedItemDetail.acceptedItemDetailType + ' Detail';
            }
            return this.activeSection === ActiveSection.vehicles ? 'Edit Vehicle' : this.activeSection === ActiveSection.documents ? 'Edit Document' : '';
        },
        acceptedItemHumanReadableId() {
            return this.$route.params.humanReadableId.toString();
        },
        vehicleSelection() {
            return this.vehicleMakeSelection === null ? [] : this.vehicles.filter((v) => { return v.make === this.vehicleMakeSelected; })
                .map((v) => ({ item: v.model, value: v.id }));
        },
        allowAddVehicle() {
            return this.vehicles.length > 0;
        }
    },
    methods: {
        async loadAcceptedItem() {
            await AcceptedItemsService.getAcceptedItemByHumanReadableId(this.acceptedItemHumanReadableId)
                .then(response => {
                this.acceptedItem = {
                    id: response.id,
                    humanReadableId: response.humanReadableId,
                    itemManufacturerId: response.itemManufacturerId,
                    itemManufacturer: response.itemManufacturer,
                    conversionType: response.conversionType,
                    conversionTypeName: response.conversionTypeName,
                    itemDescription: response.itemDescription,
                    itemNumber: response.itemNumber,
                    acceptedPrice: response.acceptedPrice,
                    isIncludedOnReport: response.isIncludedOnReport
                };
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        loadDetails() {
            AcceptedItemsService.getAcceptedItemDetails(this.acceptedItem.id, this.acceptedItemDetailsFilter.options())
                .then(response => {
                if (!this.acceptedItemDetailsFilter.update({ totalItems: response.totalCount })) {
                    this.loadDetails();
                    return;
                }
                this.acceptedItemDetails = response.data;
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        loadVehicles() {
            AcceptedItemsService.getAcceptedItemVehicles(this.acceptedItem.id, this.acceptedItemVehiclesFilter.options())
                .then(response => {
                if (!this.acceptedItemVehiclesFilter.update({ totalItems: response.totalCount })) {
                    this.loadVehicles();
                    return;
                }
                this.acceptedItemVehicles = response.data;
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        loadDocuments() {
            AcceptedItemsService.getAcceptedItemDocuments(this.acceptedItem.id, this.acceptedItemDocumentsFilter.options())
                .then(response => {
                if (!this.acceptedItemDocumentsFilter.update({ totalItems: response.totalCount })) {
                    this.loadDocuments();
                    return;
                }
                this.acceptedItemDocuments = response.data;
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        loadAddModal() {
            switch (this.activeSection) {
                case ActiveSection.details:
                    AcceptedItemsService.getAcceptedItemDetailTypes()
                        .then(response => {
                        if (response) {
                            if (response.length === 0) {
                                this.acceptedItemDetailTypeSelection = [];
                                return;
                            }
                            this.acceptedItemDetailTypeSelection = response.map((e) => ({ item: e.name, value: e.id }));
                        }
                    });
                    break;
                case ActiveSection.vehicles:
                    this.loadVehicleSelection(true);
                    break;
                case ActiveSection.documents:
                    break;
            }
        },
        loadEditModal(id) {
            switch (this.activeSection) {
                case ActiveSection.details:
                    this.loadDetail(id);
                    break;
                case ActiveSection.vehicles:
                    this.loadVehicle(id);
                    break;
                case ActiveSection.documents:
                    this.loadDocument(id);
                    break;
            }
        },
        loadDetail(id) {
            AcceptedItemsService.getAcceptedItemDetail(id)
                .then(response => {
                this.updatedAcceptedItemDetail = {
                    id: id,
                    acceptedItemDetailTypeId: response.acceptedItemDetailTypeId,
                    acceptedItemDetailType: response.acceptedItemDetailType,
                    detail: response.detail,
                    isIncludedOnReport: response.isIncludedOnReport
                };
                if (this.editModal) {
                    this.editModal.show();
                }
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        loadVehicle(id) {
            AcceptedItemsService.getAcceptedItemVehicle(id)
                .then(response => {
                this.updatedAcceptedItemVehicle = {
                    id: id,
                    acceptedItemId: response.acceptedItemId,
                    vehicleId: response.vehicleId,
                    vehicleMake: response.vehicleMake,
                    vehicleModel: response.vehicleModel,
                    year: response.year,
                    notes: response.notes,
                    isIncludedOnReport: response.isIncludedOnReport
                };
                if (this.editModal) {
                    this.editModal.show();
                }
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        loadDocument(id) {
            AcceptedItemsService.getAcceptedItemDocument(id)
                .then(response => {
                this.updatedAcceptedItemDocument = {
                    id: id,
                    fileName: response.fileName
                };
                if (this.editModal) {
                    this.editModal.show();
                }
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        loadVehicleSelection(loadSelectionLists) {
            VehiclesService.getVehicleList({
                page: 1,
                pageSize: 10,
                returnAll: true,
                make: null,
                model: null
            })
                .then(response => {
                this.vehicles = response.data;
                if (loadSelectionLists) {
                    if (response.data.length === 0) {
                        this.vehicleMakeSelection = [];
                        return;
                    }
                    this.vehicleMakeSelection = this.vehicles.map((e) => { return e.make; }).filter((m, index, self) => {
                        return self.indexOf(m) === index;
                    }).map((v) => ({ item: v, value: v }));
                }
            });
        },
        loadItemManufacturersSelection() {
            ManufacturersService.getItemManufacturerList({
                page: 1,
                pageSize: 10,
                returnAll: true,
                name: null,
                onlyAssignedToAcceptedItems: false,
                onlyViewableOnAcceptedProductsReport: false
            })
                .then(response => {
                if (response.data) {
                    if (response.data.length === 0) {
                        this.itemManufacturerSelection = [];
                        return;
                    }
                    this.itemManufacturerSelection = response.data.map((e) => ({ item: e.name, value: e.id }));
                    this.itemManufacturerSelection.unshift({ item: '(None)', value: null });
                }
            });
        },
        clearAddModal() {
            this.acceptedItemDetailTypeSelection = [];
            this.vehicleMakeSelection = [];
            this.vehicleMakeSelected = null;
            this.vehicleSelected = null;
            this.vehicleYears = [];
            this.vehicleYear = null;
            this.vehicleStartYear = null;
            this.vehicleEndYear = null;
            this.addVehicleYearRange = false;
            this.addModalKey = !this.addModalKey;
        },
        updatePagination(page, pageSize) {
            switch (this.activeSection) {
                case ActiveSection.details:
                    this.acceptedItemDetailsFilter.update({ page: page, pageSize: pageSize });
                    this.loadDetails();
                    break;
                case ActiveSection.vehicles:
                    this.acceptedItemVehiclesFilter.update({ page: page, pageSize: pageSize });
                    this.loadVehicles();
                    break;
                case ActiveSection.documents:
                    this.acceptedItemDocumentsFilter.update({ page: page, pageSize: pageSize });
                    this.loadDocuments();
                    break;
            }
        },
        updateAcceptedItemInformation() {
            this.editingInformation = false;
            var form = document.forms.namedItem('acceptedItemInformationForm');
            if (form !== null) {
                var formData = new FormData(form);
                AcceptedItemsService.updateAcceptedItem(this.acceptedItem.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadAcceptedItem();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        add() {
            switch (this.activeSection) {
                case ActiveSection.details:
                    this.addDetail();
                    break;
                case ActiveSection.vehicles:
                    this.addVehicle();
                    break;
                case ActiveSection.documents:
                    this.submitDocument();
                    break;
            }
        },
        addDetail() {
            var form = document.forms.namedItem('addDetailForm');
            if (form !== null) {
                var formData = new FormData(form);
                AcceptedItemsService.addAcceptedItemDetail(this.acceptedItem.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadDetails();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        addVehicle() {
            var form = document.forms.namedItem('addVehicleForm');
            if (form !== null) {
                var formData = new FormData(form);
                AcceptedItemsService.addAcceptedItemVehicle(this.acceptedItem.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadVehicles();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        submitDocument() {
            var form = document.forms.namedItem('addDocumentForm');
            if (form !== null) {
                var formData = new FormData(form);
                AcceptedItemsService.submitAcceptedItemDocument(this.acceptedItem.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                    this.loadDocuments();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        update() {
            switch (this.activeSection) {
                case ActiveSection.details:
                    this.updateDetail();
                    break;
                case ActiveSection.vehicles:
                    this.updateVehicle();
                    break;
                case ActiveSection.documents:
                    this.updateDocument();
                    break;
            }
        },
        updateDetail() {
            var form = document.forms.namedItem('updateDetailForm');
            if (form !== null && this.updatedAcceptedItemDetail !== null) {
                var formData = new FormData(form);
                AcceptedItemsService.updateAcceptedItemDetail(this.updatedAcceptedItemDetail.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadDetails();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        updateVehicle() {
            var form = document.forms.namedItem('updateVehicleForm');
            if (form !== null && this.updatedAcceptedItemVehicle !== null) {
                var formData = new FormData(form);
                AcceptedItemsService.updateAcceptedItemVehicle(this.updatedAcceptedItemVehicle.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadVehicles();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        updateDocument() {
            var form = document.forms.namedItem('updateDocumentForm');
            if (form !== null && this.updatedAcceptedItemDocument !== null) {
                var formData = new FormData(form);
                AcceptedItemsService.updateAcceptedItemDocument(this.updatedAcceptedItemDocument.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadDocuments();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        removeDetail(id) {
            AcceptedItemsService.removeAcceptedItemDetail(id)
                .then(response => {
                Notifications.methods.addToast(ToastType.success, response);
                this.loadDetails();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        removeVehicle(id) {
            AcceptedItemsService.removeAcceptedItemVehicle(id)
                .then(response => {
                Notifications.methods.addToast(ToastType.success, response);
                this.loadVehicles();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        removeDocument(id) {
            AcceptedItemsService.removeAcceptedItemDocument(id)
                .then(response => {
                Notifications.methods.addToast(ToastType.success, response);
                this.loadDocuments();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        downloadAcceptedItemDocument(id) {
            AcceptedItemsService.getAcceptedItemDocumentFile(id)
                .then(response => {
                const data = window.URL.createObjectURL(response.blob);
                var anchor = document.createElement('a');
                anchor.download = response.name;
                anchor.href = data;
                anchor.click();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            });
        },
        updateYearRange() {
            if (this.addVehicleYearRange) {
                this.vehicleYear = null;
                if (this.vehicleStartYear !== null && +this.vehicleStartYear === 0) {
                    this.vehicleStartYear = null;
                }
                if (this.vehicleEndYear !== null && +this.vehicleEndYear === 0) {
                    this.vehicleEndYear = null;
                }
                if (this.vehicleStartYear !== null) {
                    if (this.vehicleEndYear !== null && +(this.vehicleEndYear ?? 0) > +(this.vehicleStartYear ?? 0)) {
                        var length = (this.vehicleEndYear ?? 0) - (this.vehicleStartYear ?? 0) + 1;
                        this.vehicleYears = [...Array(length).keys()].map(i => { return +i + +(this.vehicleStartYear ?? 0); });
                        return;
                    }
                    this.vehicleYears = [(this.vehicleStartYear ?? 0)];
                    return;
                }
            }
            else {
                this.vehicleStartYear = null;
                this.vehicleEndYear = null;
            }
            this.vehicleYears = [];
        }
    },
    async created() {
        await this.loadAcceptedItem()
            .then(() => {
            this.loadDetails();
            this.loadVehicles();
            this.loadVehicleSelection(false);
            this.loadItemManufacturersSelection();
            this.loadDocuments();
        });
    }
});
