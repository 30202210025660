import FormService from '@/services/FormService';
import { defineComponent } from 'vue';
import AppCarouselForm from '@/components/shared/AppCarouselForm.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppSelect from '@/components/shared/AppSelect.vue';
export default defineComponent({
    components: {
        AppCarouselForm,
        AppInput,
        AppSelect
    },
    data() {
        return {
            yesNoOptions: [{ value: true, item: 'Yes' }, { value: false, item: 'No' }],
            vehicleInspectionRequest: {
                formVersion: null,
                formVersionDate: null,
                caseId: null,
                sa: null,
                saExpirationDate: null,
                customer: {
                    firstName: null,
                    lastName: null,
                    weight: null,
                    driverOrPassenger: null,
                    hasMobilityDevice: null,
                    mobilityAid: []
                },
                vendor: {
                    contactName: null,
                    contactEmailAddress: null,
                    addressLineOne: null,
                    addressLineTwo: null,
                    city: null,
                    state: null,
                    zipCode: null,
                    phoneNumber: null,
                    faxNumber: null,
                    name: null,
                    contactConfirmedVehicleReadyName: null
                },
                vehicle: {
                    type: null,
                    year: null,
                    make: null,
                    model: null
                },
                attachments: {
                    ttiInspectionSA: null,
                    twcIlc3408: null
                },
                hasUpdated3408: null,
                representativeCounselor: {
                    name: null,
                    office: null,
                    phoneNumber: null,
                    faxNumber: null,
                    emailAddress: null
                },
                ats: {
                    name: null,
                    office: null,
                    phoneNumber: null,
                    faxNumber: null,
                    emailAddress: null
                },
                vehicleIsGettingSpecialConversion: null,
                vehicleModificationType: null,
                proposedVehicleGawr: null,
                concernsOfCombinedWeightExceedingGawr: null
            },
            submissionSuccessful: null,
            returnedMessage: null
        };
    },
    computed: {
        formSections() {
            return [{ name: 'contactInformationSlide', title: 'Contact Information' }, { name: 'customerInformationSlide', title: 'Customer Information' }, { name: 'vendorSlide', title: 'Vendor' }];
        }
    },
    methods: {
        onSubmit() {
            var formData = new FormData(document.forms[0]);
            FormService.submitVehicleInspectionRequestForm(formData)
                .then(() => {
                this.submissionSuccessful = true;
            })
                .catch(error => {
                this.returnedMessage = error.response.data;
                this.submissionSuccessful = false;
            });
        }
    }
});
