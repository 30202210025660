import AveHttpService from '@/services/AveHttpService';
class ManufacturersService {
    /**
     *  @description Retrieves a specific item manufacturer's data
     */
    getItemManufacturer(itemManufacturerId) {
        return AveHttpService.get(`api/v1/items/manufacturers/${itemManufacturerId}`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all item manufacturers based on filter requirements
     */
    getItemManufacturerList(filter) {
        let params = {};
        if (typeof filter !== 'undefined') {
            params = filter;
        }
        else {
            params = { returnAll: true };
        }
        return AveHttpService.get('api/v1/items/manufacturers', { params: params }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Adds a new item manufacturer record
     */
    addItemManufacturer(data) {
        return (AveHttpService.post('api/v1/items/manufacturers/add', data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Updates an item manufacturer record
     */
    updateItemManufacturer(itemManufacturerId, data) {
        return (AveHttpService.patch(`api/v1/items/manufacturers/${itemManufacturerId}/update`, data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Removes an item manufacturer record
     */
    removeItemManufacturer(itemManufacturerId) {
        return (AveHttpService.delete(`api/v1/items/manufacturers/${itemManufacturerId}/remove`)).then((response) => {
            return response.data;
        });
    }
}
export default new ManufacturersService();
