import { defineComponent, ref } from 'vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import DocumentService from '@/services/DocumentService';
import Notifications from '@/hooks/Notifications';
import AssignEmployee from '@/components/document/documentManagement/shared/AssignEmployee.vue';
import ReviewLetter from '@/components/document/documentManagement/shared/ReviewLetter.vue';
import ReviewReviewLetter from '@/components/document/documentManagement/shared/ReviewReviewLetter.vue';
import ReviewInspectionReport from '@/components/document/documentManagement/shared/ReviewInspectionReport.vue';
import SubmitCarFax from '@/components/document/documentManagement/shared/SubmitCarFax.vue';
import InvoiceUpload from '@/components/document/documentManagement/shared/InvoiceUpload.vue';
import VehicleInspectionProcessing from '@/components/document/documentManagement/VehicleInspectionProcessing.vue';
import { DocumentAttachmentType } from '@/services/models/shared/DocumentAttachmentType';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import { DocumentStatus } from '@/services/models/shared/DocumentStatus';
import { DocumentType } from '@/services/models/shared/DocumentType';
import { ApplicationRole } from '@/services/models/Role';
export default defineComponent({
    emits: ['reloadWorkAttachments'],
    components: {
        BSButton,
        AssignEmployee,
        ReviewLetter,
        ReviewReviewLetter,
        ReviewInspectionReport,
        SubmitCarFax,
        InvoiceUpload,
        VehicleInspectionProcessing
    },
    props: {
        clientName: {
            required: true,
            type: String
        },
        caseId: {
            required: true,
            type: String
        },
        documentId: {
            required: true,
            type: String
        },
        humanReadableId: {
            required: true,
            type: String
        },
        documentTypeId: {
            required: true,
            type: String
        },
        documentType: {
            required: true,
            type: String
        },
        documentStatusId: {
            required: true,
            type: String
        },
        createdDate: {
            required: true
        },
        employeeAssigneeUserFullName: {
            required: true,
            type: String
        }
    },
    setup() {
        const assignEmployee = ref();
        const reviewLetter = ref();
        const reviewReviewLetter = ref();
        const vehicleInspectionProcessing = ref();
        const submitCarFax = ref();
        const invoiceUpload = ref();
        return { assignEmployee, reviewLetter, reviewReviewLetter, vehicleInspectionProcessing, submitCarFax, invoiceUpload };
    },
    data() {
        return {
            submitDisabled: false,
            clearChildKey: false,
            submissionProgress: 2
        };
    },
    methods: {
        showStatusSection(sectionId) {
            return this.$stringUtilities.compareIds(this.documentStatusId, sectionId);
        },
        async submitData() {
            this.$loadingBackdrop.data.isVisible = true;
            this.submitDisabled = true;
            switch (this.documentStatusId) {
                case DocumentStatus.Assigning:
                    this.submitAssignEmployee();
                    break;
                case DocumentStatus.Processing:
                    switch (this.documentTypeId) {
                        case DocumentType.carFax:
                            this.uploadCarFax();
                            break;
                        case DocumentType.priceReview:
                        case DocumentType.repair:
                        case DocumentType.modification:
                            this.completeReviewLetter();
                            break;
                        case DocumentType.inspection:
                            switch (this.vehicleInspectionProcessing?.$data.activeTab) {
                                case 'supportingDocuments':
                                    await this.uploadSupportingDocuments();
                                    break;
                                case 'inspectionPhotos':
                                    await this.uploadInspectionPhotos();
                                    break;
                                case 'inspectionSheet':
                                    this.submitInspectionWorksheet();
                                    break;
                                case 'inspectionReport':
                                    this.submitInspectionReport();
                                    break;
                            }
                    }
                    break;
                case DocumentStatus.Review:
                    if (this.documentTypeId === DocumentType.inspection) {
                        this.approveInspectionReport();
                    }
                    if (this.documentTypeId === DocumentType.modification) {
                        this.signReviewedReviewLetter();
                    }
                    break;
                case DocumentStatus.Billed:
                    this.uploadInvoice();
                    break;
            }
        },
        submitAssignEmployee() {
            var form = document.forms.namedItem('managementForm');
            if (form !== null) {
                var formData = new FormData(form);
                DocumentService.changeDocumentStatus(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                    if (this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole['AVE Administrator'] || this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole.Administrator) {
                        this.$router.push({ name: 'RequestsDocumentDetails', params: { humanReadableId: this.humanReadableId } });
                    }
                    else {
                        if (this.assignEmployee && this.assignEmployee.$data.employeeAssigneeUserId === this.$store.getters.user.id) {
                            this.$router.push({ name: 'InboxDocumentDetails', params: { humanReadableId: this.humanReadableId } });
                        }
                        this.$router.push({ name: 'OutboxDocumentDetails', params: { humanReadableId: this.humanReadableId } });
                    }
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.clearChildKey = !this.clearChildKey;
                    this.submitDisabled = false;
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        uploadCarFax() {
            var form = document.forms.namedItem('managementForm');
            if (form !== null) {
                var formData = new FormData(form);
                DocumentService.submitAttachment(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.reponse.data);
                })
                    .finally(() => {
                    this.clearChildKey = !this.clearChildKey;
                    this.$emit('reloadWorkAttachments');
                    this.submitDisabled = false;
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        async uploadSupportingDocuments() {
            var notes = this.vehicleInspectionProcessing?.vehicleInspectionSupportingDocuments?.$data.notes;
            var files = this.vehicleInspectionProcessing?.vehicleInspectionSupportingDocuments?.$data.files;
            if (files && files.length > 0) {
                let indexVal = 1;
                const numOfFiles = files.length;
                for (const file of files) {
                    var formData = new FormData();
                    if (notes) {
                        formData.append('notes', notes);
                    }
                    formData.append('documentAttachmentTypeId', DocumentAttachmentType.supportingDocument);
                    formData.append('submittedByUserId', this.$store.getters.user.id);
                    formData.append('isCustomerProvided', 'false');
                    formData.append('file', file);
                    await DocumentService.submitAttachments(this.documentId, formData)
                        .catch(() => {
                        Notifications.methods.addToast(ToastType.danger, 'Supporting Document ' + file.name + ' could not be uploaded');
                    })
                        .finally(() => {
                        this.submissionProgress = Math.floor((indexVal / numOfFiles) * 100);
                        indexVal++;
                    });
                }
                this.clearChildKey = !this.clearChildKey;
                this.$emit('reloadWorkAttachments');
            }
            this.submitDisabled = false;
            this.submissionProgress = 2;
            this.$loadingBackdrop.data.isVisible = false;
        },
        async uploadInspectionPhotos() {
            this.$loadingBackdrop.data.isVisible = true;
            var notes = this.vehicleInspectionProcessing?.vehicleInspectionPhotos?.$data.notes;
            var files = this.vehicleInspectionProcessing?.vehicleInspectionPhotos?.$data.files;
            if (files && files.length > 0) {
                let indexVal = 1;
                const numOfFiles = files.length;
                for (const file of files) {
                    var formData = new FormData();
                    if (notes) {
                        formData.append('notes', notes);
                    }
                    formData.append('documentAttachmentTypeId', DocumentAttachmentType.inspectionPhoto);
                    formData.append('submittedByUserId', this.$store.getters.user.id);
                    formData.append('isCustomerProvided', 'false');
                    formData.append('file', file);
                    await DocumentService.submitAttachments(this.documentId, formData)
                        .catch(() => {
                        Notifications.methods.addToast(ToastType.danger, 'Image ' + file.name + ' could not be uploaded');
                    })
                        .finally(() => {
                        this.submissionProgress = Math.floor((indexVal / numOfFiles) * 100);
                        indexVal++;
                    });
                }
                this.clearChildKey = !this.clearChildKey;
                this.$emit('reloadWorkAttachments');
            }
            this.submitDisabled = false;
            this.submissionProgress = 2;
            this.$loadingBackdrop.data.isVisible = false;
        },
        submitInspectionWorksheet() {
            var form = document.forms.namedItem('managementForm');
            var jsonData = this.vehicleInspectionProcessing?.vehicleInspectionSheet?.$data.inspectionSheets;
            if (form !== null && jsonData !== null) {
                var formData = new FormData(form);
                formData.append('jsonData', JSON.stringify(jsonData));
                DocumentService.submitInspectionSheets(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.clearChildKey = !this.clearChildKey;
                    this.$emit('reloadWorkAttachments');
                    this.submitDisabled = false;
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        submitInspectionReport() {
            var form = document.forms.namedItem('managementForm');
            if (form !== null) {
                var formData = new FormData(form);
                formData.append('jsonData', JSON.stringify(this.vehicleInspectionProcessing?.vehicleInspectionReport?.$data.inspectionReport));
                DocumentService.submitInspectionReport(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.clearChildKey = !this.clearChildKey;
                    this.$emit('reloadWorkAttachments');
                    this.submitDisabled = false;
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        completeReviewLetter() {
            var form = document.forms.namedItem('managementForm');
            if (form !== null) {
                var formData = new FormData(form);
                formData.append('jsonData', JSON.stringify(this.reviewLetter?.$data.reviewLetter));
                DocumentService.submitReviewLetter(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.clearChildKey = !this.clearChildKey;
                    this.$emit('reloadWorkAttachments');
                    this.submitDisabled = false;
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        signReviewedReviewLetter() {
            var form = document.forms.namedItem('managementForm');
            if (form !== null) {
                var formData = new FormData(form);
                DocumentService.submitReviewLetter(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                    if (this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole['AVE Administrator'] || this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole.Administrator) {
                        this.$router.push({ name: 'RequestsDocumentDetails', params: { humanReadableId: this.$route.params.humanReadableId } });
                    }
                    else {
                        this.$router.push({ name: 'OutboxDocumentDetails', params: { humanReadableId: this.$route.params.humanReadableId } });
                    }
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.clearChildKey = !this.clearChildKey;
                    this.submitDisabled = false;
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        approveInspectionReport() {
            var form = document.forms.namedItem('managementForm');
            if (form !== null) {
                var formData = new FormData(form);
                DocumentService.changeDocumentStatus(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                    if (this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole['AVE Administrator'] || this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole.Administrator) {
                        this.$router.push({ name: 'RequestsDocumentDetails', params: { humanReadableId: this.humanReadableId } });
                    }
                    else {
                        if (this.assignEmployee && this.assignEmployee.$data.employeeAssigneeUserId === this.$store.getters.user.id) {
                            this.$router.push({ name: 'InboxDocumentDetails', params: { humanReadableId: this.humanReadableId } });
                        }
                        this.$router.push({ name: 'OutboxDocumentDetails', params: { humanReadableId: this.humanReadableId } });
                    }
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.clearChildKey = !this.clearChildKey;
                    this.submitDisabled = false;
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        uploadInvoice() {
            var form = document.forms.namedItem('managementForm');
            if (form !== null) {
                var formData = new FormData(form);
                DocumentService.submitAttachment(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.reponse.data);
                })
                    .finally(() => {
                    this.clearChildKey = !this.clearChildKey;
                    this.$emit('reloadWorkAttachments');
                    this.submitDisabled = false;
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        }
    }
});
