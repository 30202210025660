import { defineComponent } from 'vue';
import AppNotifications from '@/components/shared/AppNotifications.vue';
import SiteHeader from '@/components/layout/SiteHeader.vue';
import SiteFooter from '@/components/layout/SiteFooter.vue';
import { AdministratorRoutes, EmployeeRoutes } from '@/router/index';
import { ApplicationRole } from '@/services/models/Role';
export default defineComponent({
    inject: ['siteIsInMaintenance', 'maintenanceMessage'],
    name: 'TaskOrderManagementSystemApp',
    components: {
        AppNotifications,
        SiteHeader,
        SiteFooter
    },
    computed: {
        hasUserData() {
            return this.$store.getters.userIsEmpty;
        },
        routes() {
            if (this.siteIsInMaintenance) {
                return [];
            }
            if (this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole.Administrator ||
                this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole['AVE Administrator']) {
                return AdministratorRoutes;
            }
            return EmployeeRoutes;
        }
    },
    created() {
        document.title = 'Task Order Management System';
        if (this.siteIsInMaintenance && this.$route.name !== 'Maintenance') {
            this.$router.replace({ name: 'Maintenance' });
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.$store.state.window.width = window.innerWidth;
            this.$store.state.window.height = window.innerHeight;
        });
    }
});
