import { defineComponent } from 'vue';
export default defineComponent({
    emits: ['valueUpdate', 'removeRow', 'addRow'],
    props: {
        tableInitialData: {
            type: Object,
            required: false,
            default: {
                label: '',
                headers: [
                    'Header Name'
                ],
                rows: [
                    [
                        'Cell Value'
                    ]
                ]
            }
        }
    },
    data() {
        return {
            isLoading: true,
            tableData: {}
        };
    },
    computed: {
        columnLength() {
            return this.tableInitialData.headers.length === 1 ? this.tableInitialData.headers.length : this.tableInitialData.headers.length + 1;
        }
    },
    methods: {
        addTableColumn() {
            this.tableData.headers.push('New Column');
            this.tableData.rows.forEach((rowObject) => {
                rowObject.push('New Cell');
            });
        },
        removeTableColumn(columnIndex) {
            this.tableData.headers.splice(columnIndex, 1);
            for (var i = 0; i < this.tableData.rows.length; i++) {
                this.tableData.rows[i].splice(columnIndex, 1);
            }
        },
        addTableRow() {
            var numberOfColumns = this.tableData.headers.length;
            var cellArray = [];
            for (var i = 0; i < numberOfColumns; i++) {
                cellArray.push('New Cell');
            }
            this.tableData.rows.push(cellArray);
            this.$emit('valueUpdate', this.tableData);
        },
        removeTableRow(rowIndex) {
            this.tableData.rows.splice(rowIndex, 1);
            this.$emit('valueUpdate', this.tableData);
        }
    },
    mounted() {
        this.tableData = this.tableInitialData;
        this.isLoading = false;
    }
});
