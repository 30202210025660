import { reactive } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import { uuid } from 'vue-uuid';
const state = reactive({
    notifications: []
});
const methods = {
    addToast(type, message, title = '') {
        let options = {};
        if (title === '' || title === null) {
            switch (type) {
                case ToastType.success:
                    options = {
                        id: uuid.v4(),
                        toastType: type,
                        title: 'Success',
                        message: message
                    };
                    break;
                case ToastType.info:
                    options = {
                        id: uuid.v4(),
                        toastType: type,
                        title: 'Information',
                        message: message
                    };
                    break;
                case ToastType.warning:
                    options = {
                        id: uuid.v4(),
                        toastType: type,
                        title: 'Warning',
                        message: message
                    };
                    break;
                case ToastType.danger:
                    options = {
                        id: uuid.v4(),
                        toastType: type,
                        title: 'Error',
                        message: message
                    };
                    break;
            }
        }
        else {
            options = {
                id: uuid.v4(),
                toastType: type,
                title: title,
                message: message
            };
        }
        state.notifications.push(options);
        window.scrollTo(0, 0);
    }
};
export default {
    state,
    methods
};
