import { defineComponent } from 'vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppRichTextEditor from '@/components/shared/AppRichTextEditor.vue';
import AppSimpleDynamicTable from '@/components/shared/AppSimpleDynamicTable.vue';
import DocumentService from '@/services/DocumentService';
import OrganizationService from '@/services/OrganizationsService';
import Notifications from '@/hooks/Notifications';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import { ReviewLetterSignatureType } from '@/services/models/shared/ReviewLetterSignature';
import { AnswerType } from '@/services/models/shared/AnswerObject';
import { OrganizationsFilter } from '@/services/models/Organization';
export default defineComponent({
    name: 'ReviewLetter',
    emits: ['disableSubmit', 'enableSubmit'],
    components: {
        BSButton,
        AppRichTextEditor,
        AppSimpleDynamicTable
    },
    props: {
        documentId: {
            required: true,
            type: String
        },
        documentType: {
            required: true,
            type: String
        },
        clientName: {
            required: true,
            type: String
        },
        caseId: {
            required: true,
            type: String
        },
        documentCreatedDate: {
            required: true
        },
        employeeAssigneeUserFullName: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            isLoading: true,
            reviewLetter: {
                questions: [{
                        question: '',
                        answer: []
                    }],
                signatures: [{
                        signees: [''],
                        signatureType: ReviewLetterSignatureType.Reviewer
                    }]
            },
            reviewLetterExists: false,
            reviewLetterEditingQuestion: null,
            reviewLetterEditingSignature: null,
            ttiContactInformation: null,
            reviewNotes: '',
            isInPreview: false,
            isInEdit: true
        };
    },
    computed: {
        currentEditingQuestionIndex() {
            if (!this.isLoading) {
                return this.reviewLetterEditingQuestion;
            }
            return null;
        },
        currentEditingSignatureIndex() {
            if (!this.isLoading) {
                return this.reviewLetterEditingSignature;
            }
            return null;
        },
        isCurrentlyEditing() {
            if (this.currentEditingQuestionIndex !== null || this.currentEditingSignatureIndex !== null) {
                return true;
            }
            return false;
        }
    },
    methods: {
        loadData() {
            DocumentService.getReviewLetterData(this.documentId)
                .then(response => {
                if (response !== null) {
                    this.reviewLetter.questions = response.questions;
                    this.reviewLetter.signatures = response.signatures;
                    this.reviewLetterExists = response.exists;
                }
            });
        },
        loadTTIContactInformation() {
            var filter = new OrganizationsFilter(true);
            filter.organizationType = 8;
            filter.excludeInternal = false;
            OrganizationService.getOrganizations(filter.options())
                .then(response => {
                this.ttiContactInformation = response.data[0];
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        addQuestion() {
            this.reviewLetter.questions.push({
                question: 'Edit to change question',
                answer: [{
                        type: AnswerType.TextObject,
                        html: '<p>Edit to change answer.</p>'
                    }]
            });
        },
        addSignatures(signatureType) {
            this.reviewLetter.signatures.push({
                signatureType: signatureType,
                signees: ['New Signee']
            });
        },
        moveQuestion(fromIndex, toIndex) {
            var question = this.reviewLetter.questions[fromIndex];
            this.reviewLetter.questions.splice(fromIndex, 1);
            this.reviewLetter.questions.splice(toIndex, 0, question);
        },
        moveSignatures(fromIndex, toIndex) {
            var signatures = this.reviewLetter.signatures[fromIndex];
            this.reviewLetter.signatures.splice(fromIndex, 1);
            this.reviewLetter.signatures.splice(toIndex, 0, signatures);
        },
        disableEditMode() {
            this.reviewLetterEditingQuestion = null;
            this.reviewLetterEditingSignature = null;
            this.$emit('enableSubmit');
        },
        editQuestion(index) {
            this.$emit('disableSubmit');
            this.reviewLetterEditingQuestion = index;
        },
        editSignatures(index) {
            this.$emit('disableSubmit');
            this.reviewLetterEditingSignature = index;
        },
        deleteQuestion(index) {
            this.reviewLetter.questions.splice(index, 1);
        },
        deleteSignatures(index) {
            this.reviewLetter.signatures.splice(index, 1);
        },
        isTypeText(object) {
            return object.type === AnswerType.TextObject;
        },
        isTypeTable(object) {
            return object.type === AnswerType.TableObject;
        },
        addAnswerObject(type, questionIndex) {
            switch (type) {
                case AnswerType.TextObject:
                    this.reviewLetter.questions[questionIndex].answer.push({
                        type: type,
                        html: '<p>Edit to change answer.</p>'
                    });
                    break;
                case AnswerType.TableObject:
                    this.reviewLetter.questions[questionIndex].answer.push({
                        type: type,
                        label: '',
                        headers: ['Change Header Value'],
                        rows: [
                            [
                                'Change Cell Value'
                            ]
                        ]
                    });
            }
        },
        addSignee(signatureIndex) {
            this.reviewLetter.signatures[signatureIndex].signees.push('New Signee');
        },
        moveAnswerObject(questionIndex, fromIndex, toIndex) {
            var answerObject = this.reviewLetter.questions[questionIndex].answer[fromIndex];
            this.reviewLetter.questions[questionIndex].answer.splice(fromIndex, 1);
            this.reviewLetter.questions[questionIndex].answer.splice(toIndex, 0, answerObject);
        },
        moveSignee(signatureIndex, fromIndex, toIndex) {
            var signee = this.reviewLetter.signatures[signatureIndex].signees[fromIndex];
            this.reviewLetter.signatures[signatureIndex].signees.splice(fromIndex, 1);
            this.reviewLetter.signatures[signatureIndex].signees.splice(toIndex, 0, signee);
        },
        deleteAnswerObject(questionIndex, answerIndex) {
            this.reviewLetter.questions[questionIndex].answer.splice(answerIndex, 1);
        },
        deleteSignee(signatureIndex, signeeIndex) {
            this.reviewLetter.signatures[signatureIndex].signees.splice(signeeIndex, 1);
        },
        tableValueUpdate(tableData, questionIndex, answerIndex) {
            this.reviewLetter.questions[questionIndex].answer[answerIndex] = tableData;
        },
        previewReviewLetter() {
            this.isInPreview = !this.isInPreview;
            if (this.isInPreview) {
                this.isInEdit = false;
                this.$emit('disableSubmit');
            }
            else {
                this.isInEdit = true;
                this.$emit('enableSubmit');
            }
        },
        signReviewLetter(isShowing) {
            if (isShowing) {
                this.isInPreview = false;
                this.isInEdit = false;
                this.$emit('disableSubmit');
            }
            else {
                var file = document.getElementById('signedFile');
                file.value = '';
                this.isInEdit = true;
                this.$emit('enableSubmit');
            }
        },
        currentSignedFile() {
            var currentValue = document.getElementById('signedFile').value;
            if (currentValue !== null && currentValue !== '') {
                this.$emit('enableSubmit');
            }
            else {
                this.$emit('disableSubmit');
            }
        },
        signeeList(val) {
            var length = val.length;
            if (length > 2) {
                return val.slice(0, length - 1).join(', ') + ', and ' + (val)[length - 1];
            }
            if (length === 1) {
                return val[0];
            }
            if (length === 2) {
                return val[0] + ' and ' + val[1];
            }
            return '';
        },
        generateAndDownloadReviewLetter() {
            this.$loadingBackdrop.data.isVisible = true;
            DocumentService.generateReviewLetterPdf(this.documentId)
                .then(response => {
                const data = window.URL.createObjectURL(response.blob);
                var anchor = document.createElement('a');
                anchor.download = response.name;
                anchor.href = data;
                anchor.click();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            })
                .finally(() => {
                this.$loadingBackdrop.data.isVisible = false;
            });
        }
    },
    created() {
        this.loadData();
        this.loadTTIContactInformation();
    }
});
