import { defineComponent } from 'vue';
import { uuid } from 'vue-uuid';
export default defineComponent({
    el: '.form-check',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            required: false,
            type: Object,
            default: null
        },
        options: {
            required: false,
            type: Object,
            default: []
        },
        label: {
            required: false,
            type: String,
            default: null
        },
        id: {
            required: false,
            type: String,
            default: null
        },
        name: {
            required: false,
            type: String,
            default: null
        },
        required: {
            required: false,
            type: Boolean,
            default: false
        },
        readOnly: {
            required: false,
            type: Boolean,
            default: false
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        isSwitch: {
            required: false,
            type: Boolean,
            default: false
        },
        validate: {
            required: false,
            type: Boolean,
            default: false
        },
        showInvalidMessage: {
            required: false,
            type: Boolean,
            default: false
        },
        indicateIfValid: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uniqueId: ''
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        },
        hasLabel() {
            return this.label !== null && this.label !== '';
        }
    },
    methods: {
        validation(e) {
            document.getElementsByName(this.name).forEach((x) => {
                x.classList.remove('is-invalid');
                x.classList.remove('is-valid');
            });
            if (this.validate) {
                this.$inputValidationUtilities.validateInput(e, this.indicateIfValid, this.showInvalidMessage, this.showInvalidMessage, true);
            }
        }
    },
    created() {
        this.uniqueId = this.id ?? uuid.v4();
    }
});
