import { PagingFilter } from '@/services/models/shared/PagingFilter';
export class GroupsFilter extends PagingFilter {
    permissions;
    externalUserGroups;
    constructor(returnAll = false) {
        super(returnAll);
    }
    options() {
        return Object.assign({
            permissions: this.permissions,
            externalUserGroups: this.externalUserGroups
        }, super.options());
    }
}
