export const Configuration = {
    API: {
        BaseUrl: process.env.VUE_APP_API_BASE_UR
    },
    OpenIdConnect: {
        Tenant: process.env.VUE_APP_TENANT,
        Hostname: process.env.VUE_APP_TENANT_HOSTNAME,
        PolicySignIn: process.env.VUE_APP_POLICY_SIGNIN,
        PolicyEditProfile: process.env.VUE_APP_POLICY_EDIT_PROFILE,
        PolicyResetPassword: process.env.VUE_APP_POLICY_RESET_PASSWORD,
        ClientId: process.env.VUE_APP_CLIENT_ID,
        RequestScope: process.env.VUE_APP_REQUEST_SCOPE,
        TenantId: process.env.VUE_APP_TENANT_ID,
        RedirectUrl: process.env.VUE_APP_API_REDIRECT_URL
    },
    Version: process.env.VUE_APP_VERSION_NUMBER,
    Maintenance: {
        Internal: {
            Active: process.env.VUE_APP_MAINTENANCE_INTERNAL_ACTIVE === 'true',
            Message: process.env.VUE_APP_MAINTENANCE_INTERNAL_MESSAGE.trim() === '' ? null : process.env.VUE_APP_MAINTENANCE_INTERNAL_MESSAGE
        },
        External: {
            Active: process.env.VUE_APP_MAINTENANCE_EXTERNAL_ACTIVE === 'true',
            Message: process.env.VUE_APP_MAINTENANCE_EXTERNAL_MESSAGE.trim() === '' ? null : process.env.VUE_APP_MAINTENANCE_EXTERNAL_MESSAGE
        },
        GlobalMessage: process.env.VUE_APP_MAINTENANCE_GLOBALMESSAGE.trim() === '' ? null : process.env.VUE_APP_MAINTENANCE_GLOBALMESSAGE
    }
};
