import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: true
        }
    }
});
