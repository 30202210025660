import { defineComponent } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
import AppTable from '@/components/shared/AppTable.vue';
import DocumentService from '@/services/DocumentService';
import { DocumentsFilter } from '@/services/models/Document';
import { DocumentTypeString } from '@/services/models/shared/DocumentType';
import { ArchivedDocumentStatus, NotArchivedDocumentStatus } from '@/services/models/shared/DocumentStatus';
import { DocumentFilterOrganizationType } from '@/services/models/shared/OrganizationType';
import AppSelect from '@/components/shared/AppSelect.vue';
import AppInput from '@/components/shared/AppInput.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
export default defineComponent({
    inject: ['userIsCustomer', 'userIsVendor'],
    data() {
        return {
            total: 0,
            isLoading: true,
            documents: [],
            filter: new DocumentsFilter()
        };
    },
    components: {
        AppTable,
        AppCollapsingCardWithButtons,
        AppSelect,
        AppInput,
        BSButton
    },
    computed: {
        documentTypeFilterOptions() {
            const list = this.$enumUtilities.enumToSelectOptions(DocumentTypeString);
            list.unshift({ item: 'All', value: null });
            return list;
        },
        documentStatusFilterOptions() {
            let list = [{ item: 'All', value: null }];
            if (this.parentBox === 'Archive') {
                list = list.concat(this.$enumUtilities.enumToSelectOptions(ArchivedDocumentStatus));
            }
            else {
                list = list.concat(this.$enumUtilities.enumToSelectOptions(NotArchivedDocumentStatus));
            }
            return list;
        },
        organizationTypeFilterOptions() {
            let list = [{ item: 'All', value: null }];
            list = list.concat(this.$enumUtilities.enumToSelectOptions(DocumentFilterOrganizationType));
            return list;
        },
        parentBox() {
            return this.$route.matched[0].name?.toString() ?? '';
        }
    },
    methods: {
        /**
        * @description Loads the data from the API bound to the component
        */
        loadData() {
            this.isLoading = true;
            let returnedPromise = null;
            switch (this.parentBox) {
                case 'Requests':
                    returnedPromise = DocumentService.getActive(this.filter.options());
                    break;
                case 'Inbox':
                    returnedPromise = DocumentService.getInbox(this.$store.getters.user.id, this.filter.options());
                    break;
                case 'Outbox':
                    returnedPromise = DocumentService.getOutbox(this.$store.getters.user.id, this.filter.options());
                    break;
                case 'Archive':
                    returnedPromise = DocumentService.getArchive(this.$store.getters.user.id, this.filter.options());
                    break;
            }
            if (returnedPromise !== null) {
                returnedPromise
                    .then(response => {
                    if (!this.filter.update({ totalItems: response.totalCount })) {
                        this.loadData();
                        return;
                    }
                    var documentList = [];
                    response.data.forEach((x) => {
                        var newRow = {
                            route: {
                                text: x.humanReadableId,
                                to: {
                                    name: this.parentBox?.toString() + 'DocumentDetails',
                                    params: {
                                        id: x.id,
                                        humanReadableId: x.humanReadableId
                                    }
                                }
                            },
                            humanReadableId: x.humanReadableId,
                            caseId: x.caseId,
                            documentType: x.documentType,
                            documentStatus: x.documentStatus,
                            submittedDate: x.submittedDate,
                            createdDate: x.createdDate
                        };
                        documentList.push(newRow);
                    });
                    this.documents = documentList;
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error);
                })
                    .finally(() => {
                    this.isLoading = false;
                });
            }
        },
        updatePagination(page, pageSize) {
            this.filter.update({ page: page, pageSize: pageSize });
            this.loadData();
        }
    },
    created() {
        this.loadData();
    }
});
