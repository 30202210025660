import { PagingFilter } from '@/services/models/shared/PagingFilter';
export class OrganizationsFilter extends PagingFilter {
    name;
    primaryContactName;
    address;
    city;
    organizationType;
    excludeInternal;
    onlyHasUsers;
    constructor(returnAll = false) {
        super(returnAll);
    }
    options() {
        return Object.assign({
            name: this.name,
            primaryContactName: this.primaryContactName,
            address: this.address,
            city: this.city,
            organizationType: this.organizationType,
            excludeInternal: this.excludeInternal,
            onlyHasUsers: this.onlyHasUsers
        }, super.options());
    }
}
