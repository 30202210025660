import { defineComponent } from 'vue';
import { DocumentAttachmentType } from '@/services/models/shared/DocumentAttachmentType';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
import DocumentService from '@/services/DocumentService';
import Notifications from '@/hooks/Notifications';
import AppTablePagination from '@/components/shared/AppTablePagination.vue';
export default defineComponent({
    inject: ['userIsEmployee', 'userIsAdministrator'],
    components: {
        BSButton,
        BSModal,
        AppTablePagination
    },
    props: {
        documentId: {
            required: true,
            type: String
        },
        allowUpload: {
            required: true,
            type: Boolean
        },
        documentAttachmentTypeId: {
            required: false,
            type: String,
            default: null
        },
        documentAttachmentStatus: {
            required: false
        },
        returnAllVersions: {
            required: true,
            type: Boolean
        },
        areCustomerProvided: {
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            attachmentFile: null,
            attachmentUploadNotes: '',
            uploadTypeId: '',
            submitButton: {
                text: 'Save',
                show: true
            },
            cancelButton: {
                text: 'Cancel',
                show: true
            },
            documentAttachments: [{
                    documentAttachmentTypeId: '',
                    documentAttachmentType: '',
                    documentAttachmentVersionList: [{
                            id: '',
                            fileName: '',
                            mimeType: '',
                            createdDate: new Date()
                        }],
                    filterOptions: {
                        page: 1,
                        pageSize: 5
                    }
                }],
            total: 0,
            filterOptions: {
                onlyNotDeleted: false,
                documentAttachmentTypeId: this.documentAttachmentTypeId,
                isCustomerProvided: this.areCustomerProvided,
                documentAttachmentStatus: null,
                returnAllVersions: this.returnAllVersions,
                page: 1,
                pageSize: 10
            },
            documentAttachmentTypeVersionsShowing: [],
            uploadModalKey: false
        };
    },
    computed: {
        uploadType() {
            switch (this.uploadTypeId) {
                case DocumentAttachmentType.carFaxReport:
                    return 'Car Fax Report';
                case DocumentAttachmentType.driverEvaluation:
                    return 'Driver Evaluation';
                case DocumentAttachmentType.driverLicense:
                    return 'Driver License';
                case DocumentAttachmentType.fcaMedicalDocumentation:
                    return 'FCA/Medical Documentation';
                case DocumentAttachmentType.mechanicEvaluation:
                    return 'Mechanic Evaluation';
                case DocumentAttachmentType.notes:
                    return 'Notes';
                case DocumentAttachmentType.reviewLetter:
                    return 'Review Letter';
                case DocumentAttachmentType.ttiReviewSA:
                    return 'TTI Review S.A.';
                case DocumentAttachmentType.twcIlc3408:
                    return '3408';
                case DocumentAttachmentType.vendorEstimate:
                    return 'Vendor Estimate';
                case DocumentAttachmentType.ttiInspectionSA:
                    return 'TTI Inspection S.A.';
                case DocumentAttachmentType.inspectionSheet:
                    return 'Inspection Sheet';
                case DocumentAttachmentType.inspectionReport:
                    return 'Inspection Report';
                case DocumentAttachmentType.inspectionPhoto:
                    return 'Inspection Photos';
                case DocumentAttachmentType.supportingDocument:
                    return 'Supporting Document';
                default:
                    return '';
            }
        },
        showComponent() {
            return this.documentAttachments.length > 0 && this.documentAttachments[0].documentAttachmentTypeId !== '';
        }
    },
    methods: {
        loadData() {
            DocumentService.getDocumentAttachments(this.documentId, this.filterOptions)
                .then(response => {
                this.total = response.totalCount;
                this.documentAttachments = response.data;
                this.documentAttachments.forEach((e) => {
                    e.filterOptions = {
                        page: 1,
                        pageSize: 5
                    };
                });
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            });
        },
        downloadAttachment(id) {
            DocumentService.getDocumentAttachmentFile(id)
                .then(response => {
                const data = window.URL.createObjectURL(response.blob);
                var anchor = document.createElement('a');
                anchor.download = response.name;
                anchor.href = data;
                anchor.click();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            });
        },
        deleteAttachment(id) {
            var form = new FormData();
            form.append('submittedByUserId', this.$store.getters.user.id);
            DocumentService.removeDocumentAttachment(id, form)
                .then(response => {
                Notifications.methods.addToast(ToastType.success, response);
                this.loadData();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        documentAttachmentTypeVersionsExpanded(id) {
            return this.documentAttachmentTypeVersionsShowing.includes(id);
        },
        toggleDocumentAttachmentTypeVersionsShowing(id) {
            var index = this.documentAttachmentTypeVersionsShowing.findIndex((x) => {
                return x === id;
            });
            if (index > -1) {
                this.documentAttachmentTypeVersionsShowing.splice(index, 1);
            }
            else {
                this.documentAttachmentTypeVersionsShowing.push(id);
            }
        },
        replaceAttachment() {
            var form = document.forms.namedItem('replaceAttachmentForm');
            if (form !== null) {
                var formData = new FormData(form);
                DocumentService.submitAttachment(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                    this.loadData();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.attachmentFile = null;
                    this.attachmentUploadNotes = '';
                });
            }
        },
        addFile(e) {
            this.attachmentFile = {
                documentAttachmentTypeId: this.uploadTypeId,
                fileName: e.target.files[0].name,
                mimeType: e.target.files[0].type,
                file: e.target.files[0],
                isCustomerProvided: this.areCustomerProvided
            };
        },
        valChange(id) {
            this.uploadTypeId = id;
        },
        updateDocumentAttachmentTypeVersionsPagination(id, page, pageSize) {
            var documentAttachmentTypeIndex = this.documentAttachments.findIndex((x) => {
                return x.documentAttachmentTypeId === id;
            });
            if (documentAttachmentTypeIndex > -1) {
                this.documentAttachments[documentAttachmentTypeIndex].filterOptions.page = page;
                this.documentAttachments[documentAttachmentTypeIndex].filterOptions.pageSize = pageSize;
            }
        },
        updateDocumentAttachmentTypePagination(page, pageSize) {
            this.filterOptions.page = page;
            this.filterOptions.pageSize = pageSize;
            this.loadData();
        },
        recordPage(index, id) {
            return Math.ceil((index + 1) / this.currentPageSize(id));
        },
        currentPage(id) {
            if (this.documentAttachments.length > 0) {
                var attachmentTypeIndex = this.documentAttachments.findIndex((x) => {
                    return x.documentAttachmentTypeId.toLowerCase() === id.toLowerCase();
                });
                if (attachmentTypeIndex > -1) {
                    return this.documentAttachments[attachmentTypeIndex].filterOptions.page;
                }
            }
            return 1;
        },
        currentPageSize(id) {
            if (this.documentAttachments.length > 0) {
                var attachmentTypeIndex = this.documentAttachments.findIndex((x) => {
                    return x.documentAttachmentTypeId.toLowerCase() === id.toLowerCase();
                });
                if (attachmentTypeIndex > -1) {
                    return this.documentAttachments[attachmentTypeIndex].filterOptions.pageSize;
                }
            }
            return 5;
        },
        showPage(index, id) {
            return this.recordPage(index, id) === this.currentPage(id);
        },
        refreshAttachment(index, id, documentAttachmentId) {
            DocumentService.getDocumentAttachment(documentAttachmentId)
                .then(response => {
                var attachmentTypeIndex = this.documentAttachments.findIndex((x) => {
                    return x.documentAttachmentTypeId.toLowerCase() === id.toLowerCase();
                });
                if (attachmentTypeIndex > -1) {
                    this.documentAttachments[attachmentTypeIndex].documentAttachmentVersionList[index] = response;
                }
            });
        }
    },
    created() {
        this.loadData();
    }
});
