import { h, render } from 'vue';
import AppLoadingBackdrop from '@/components/shared/AppLoadingBackdrop.vue';
export default {
    install: (app, startVisible) => {
        const vNode = h(AppLoadingBackdrop);
        const container = document.createElement('div');
        const mainApp = document.getElementById('app');
        if (mainApp) {
            mainApp.appendChild(container);
        }
        render(vNode, container);
        if (vNode && vNode.component && startVisible) {
            vNode.component.data.isVisible = true;
            vNode.component.data.message = '';
        }
        app.config.globalProperties.$loadingBackdrop = vNode.component;
    }
};
