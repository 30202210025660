import AveHttpService from '@/services/AveHttpService';
class FormService {
    /**
     *  @description Posts a request's data
     */
    submitVehicleModificationRequestForm(data) {
        return AveHttpService.post('api/v1/forms/vehicleModificationRequest', data);
    }
    /**
     *  @description Posts a request's data
     */
    submitRepairReviewRequestForm(data) {
        return AveHttpService.post('api/v1/forms/repairReviewRequest', data);
    }
    /**
     *  @description Posts a request's data
     */
    submitPricingReviewRequestForm(data) {
        return AveHttpService.post('api/v1/forms/pricingReviewRequest', data);
    }
    /**
     *  @description Posts a request's data
     */
    submitCarFaxRequestForm(data) {
        return AveHttpService.post('api/v1/forms/carFaxRequest', data);
    }
    /**
     *  @description Posts a request's data
     */
    submitVehicleInspectionRequestForm(data) {
        return AveHttpService.post('api/v1/forms/vehicleInspectionRequest', data);
    }
}
export default new FormService();
