import { createApp } from 'vue';
import CustomerApp from './CustomerApp.vue';
import TaskOrderManagementSystemApp from './TaskOrderManagementSystemApp.vue';
import PublicApp from './PublicApp.vue';
import VendorApp from './VendorApp.vue';
import router, { AdministratorRoutes, EmployeeRoutes, CustomerRoutes, VendorRoutes, AccessDeniedRoute, UnauthenticatedRoute, UnauthorizedRoute } from './router';
import store from './store/index';
import withUUID from 'vue-uuid';
import LoadingContainer from '@/components/shared/LoadingContainer.vue';
import LoadingBackdropPlugin from '@/plugins/LoadingBackdropPlugin';
import InputsPlugin from '@/plugins/InputsPlugin';
import EnumsPlugin from '@/plugins/EnumsPlugin';
import AppUtilitiesPlugin from '@/plugins/AppUtilitiesPlugin';
import { ButtonColor } from '@/services/models/shared/bootstrap/ButtonColor';
import { TableColor } from '@/services/models/shared/bootstrap/TableColor';
import { DocumentType } from '@/services/models/shared/DocumentType';
import { DocumentStatus } from '@/services/models/shared/DocumentStatus';
import { DocumentAttachmentType } from '@/services/models/shared/DocumentAttachmentType';
import { SiteType } from '@/services/models/shared/SiteType';
import { ApplicationRole } from '@/services/models/Role';
import { AnswerType } from '@/services/models/shared/AnswerObject';
import { ReviewLetterSignatureType } from '@/services/models/shared/ReviewLetterSignature';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@progress/kendo-theme-default/dist/all.css';
import './assets/styles.css';
import { EmailTemplateType } from './services/models/shared/EmailTemplateType';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import { InputColumnType, TableColumnType, TextColumnType, ListColumnFormat } from '@/services/models/shared/TableColumn';
import { BackgroundColor } from '@/services/models/shared/bootstrap/BackgroundColor';
import { TableActionType } from '@/services/models/shared/TableOptions';
import { TextAlignment } from '@/services/models/shared/bootstrap/TextAlignment';
import { VerticalAlignment } from '@/services/models/shared/bootstrap/VerticalAlignment';
import { InputType } from '@/services/models/shared/bootstrap/InputType';
import { MaskType } from './services/models/shared/MaskType';
import { BorderColor, BorderWidth } from './services/models/shared/bootstrap/BorderStyle';
import { CommonIcons } from '@/services/models/shared/CommonIcons';
import { BodyObjectType } from '@/services/models/shared/inspectionSheets/BodyObjectType';
import { InputObjectType } from '@/services/models/shared/inspectionSheets/InputObjectType';
import { TextFormat } from '@/services/models/shared/inspectionSheets/TextFormat';
import { ListFormat } from '@/services/models/shared/inspectionSheets/ListFormat';
import { ItemType } from '@/services/models/shared/inspectionSheets/ItemType';
import { AlertType } from '@/services/models/shared/bootstrap/AlertType';
import { DocumentNotificationRecipientType } from '@/services/models/DocumentNotification';
import { DocumentTypeStatusPermission, DocumentTypeStatusOpenPermissionLabel } from '@/services/models/shared/DocumentTypeStatusPermission';
import { CaseItemPartyApproval, CaseItemPartyApprovalString } from '@/services/models/shared/CaseItemPartyApproval';
import AveHttpService from '@/services/AveHttpService';
import AuthenticationService from '@/services/AuthenticationService';
import { DocumentState } from '@/services/models/shared/DocumentState';
import { CaseItemHistoryAction } from '@/services/models/shared/CaseItemHistoryAction';
import { Configuration as AVEConfiguration } from '@/Configuration';
(async () => {
    document.title = 'Roadside Safety and Physical Security Division';
    const backdrop = createApp(LoadingContainer);
    backdrop.mount('#app');
    let mainApp = createApp(PublicApp).provide('siteIsInMaintenance', AVEConfiguration.Maintenance.Internal.Active && AVEConfiguration.Maintenance.External.Active).provide('maintenanceMessage', AVEConfiguration.Maintenance.GlobalMessage);
    if (AVEConfiguration.Maintenance.Internal.Active && AVEConfiguration.Maintenance.External.Active) {
        if (self === top) {
            backdrop.unmount();
            mainApp.use(store).use(router).use(AppUtilitiesPlugin).use(LoadingBackdropPlugin, false);
            mainApp.config.globalProperties.$CommonIcons = CommonIcons;
            mainApp.config.globalProperties.$ButtonColor = ButtonColor;
            mainApp.config.globalProperties.$BorderColor = BorderColor;
            mainApp.config.globalProperties.$BorderWidth = BorderWidth;
            mainApp.config.globalProperties.$BackgroundColor = BackgroundColor;
            mainApp.config.globalProperties.$SiteType = SiteType;
            mainApp.config.globalProperties.$ToastType = ToastType;
            mainApp.config.globalProperties.$Roles = ApplicationRole;
            mainApp.config.globalProperties.$AlertType = AlertType;
            mainApp.config.unwrapInjectedRef = true; // Temporary until vue version 3.3.x to use with provide/inject. Removes current warnings for computed provided properties
            mainApp.mount('#app');
        }
    }
    else {
        await AuthenticationService.authenticate()
            .then(async (userAccount) => {
            AveHttpService.defaults.headers.common.Authorization = 'Bearer ' + userAccount.accessToken;
            await store.dispatch('login', { username: userAccount.username });
            // Set routes and app for current role
            if (store.getters.userIsEmpty) {
                router.addRoute(AccessDeniedRoute);
            }
            else if (!store.getters.userIsAuthorized) {
                router.addRoute(UnauthorizedRoute);
            }
            else {
                switch (store.getters.currentRoleId) {
                    case ApplicationRole.Administrator:
                    case ApplicationRole['AVE Administrator']:
                        AdministratorRoutes.forEach((route) => {
                            router.addRoute(route);
                        });
                        mainApp = createApp(TaskOrderManagementSystemApp).provide('userIsAdministrator', true).provide('userIsEmployee', false).provide('userIsCustomer', false).provide('userIsVendor', false).provide('siteIsInMaintenance', AVEConfiguration.Maintenance.Internal.Active).provide('maintenanceMessage', AVEConfiguration.Maintenance.Internal.Message);
                        break;
                    case ApplicationRole.Employee:
                        EmployeeRoutes.forEach((route) => {
                            router.addRoute(route);
                        });
                        mainApp = createApp(TaskOrderManagementSystemApp).provide('userIsAdministrator', false).provide('userIsEmployee', true).provide('userIsCustomer', false).provide('userIsVendor', false).provide('siteIsInMaintenance', AVEConfiguration.Maintenance.Internal.Active).provide('maintenanceMessage', AVEConfiguration.Maintenance.Internal.Message);
                        break;
                    case ApplicationRole.Customer:
                        CustomerRoutes.forEach((route) => {
                            router.addRoute(route);
                        });
                        mainApp = createApp(CustomerApp, { isTWC: store.getters.user.isTWC }).provide('userIsAdministrator', false).provide('userIsEmployee', false).provide('userIsCustomer', true).provide('userIsVendor', false).provide('siteIsInMaintenance', AVEConfiguration.Maintenance.External.Active).provide('maintenanceMessage', AVEConfiguration.Maintenance.External.Message);
                        break;
                    case ApplicationRole.Vendor:
                        VendorRoutes.forEach((route) => {
                            router.addRoute(route);
                        });
                        mainApp = createApp(VendorApp).provide('userIsAdministrator', false).provide('userIsEmployee', false).provide('userIsCustomer', false).provide('userIsVendor', true).provide('siteIsInMaintenance', AVEConfiguration.Maintenance.External.Active).provide('maintenanceMessage', AVEConfiguration.Maintenance.External.Message);
                        break;
                }
            }
        })
            .catch(async () => {
            await store.dispatch('emptyUser');
            router.addRoute(UnauthenticatedRoute);
        })
            .finally(() => {
            if (self === top) {
                backdrop.unmount();
                mainApp.use(store).use(router).use(withUUID).use(EnumsPlugin).use(InputsPlugin).use(AppUtilitiesPlugin).use(LoadingBackdropPlugin, false);
                mainApp.config.globalProperties.$CommonIcons = CommonIcons;
                mainApp.config.globalProperties.$ButtonColor = ButtonColor;
                mainApp.config.globalProperties.$BorderColor = BorderColor;
                mainApp.config.globalProperties.$BorderWidth = BorderWidth;
                mainApp.config.globalProperties.$BackgroundColor = BackgroundColor;
                mainApp.config.globalProperties.$TableColor = TableColor;
                mainApp.config.globalProperties.$TableColumnType = TableColumnType;
                mainApp.config.globalProperties.$InputColumnType = InputColumnType;
                mainApp.config.globalProperties.$ListColumnFormat = ListColumnFormat;
                mainApp.config.globalProperties.$TableActionType = TableActionType;
                mainApp.config.globalProperties.$TableActionType = TableActionType;
                mainApp.config.globalProperties.$MaskType = MaskType;
                mainApp.config.globalProperties.$TextColumnType = TextColumnType;
                mainApp.config.globalProperties.$InputType = InputType;
                mainApp.config.globalProperties.$DocumentType = DocumentType;
                mainApp.config.globalProperties.$DocumentStatus = DocumentStatus;
                mainApp.config.globalProperties.$DocumentAttachmentType = DocumentAttachmentType;
                mainApp.config.globalProperties.$EmailTemplateType = EmailTemplateType;
                mainApp.config.globalProperties.$SiteType = SiteType;
                mainApp.config.globalProperties.$ToastType = ToastType;
                mainApp.config.globalProperties.$Roles = ApplicationRole;
                mainApp.config.globalProperties.$AnswerType = AnswerType;
                mainApp.config.globalProperties.$ReviewLetterSignatureType = ReviewLetterSignatureType;
                mainApp.config.globalProperties.$TextAlignment = TextAlignment;
                mainApp.config.globalProperties.$VerticalAlignment = VerticalAlignment;
                mainApp.config.globalProperties.$BodyObjectType = BodyObjectType;
                mainApp.config.globalProperties.$InputObjectType = InputObjectType;
                mainApp.config.globalProperties.$TextFormat = TextFormat;
                mainApp.config.globalProperties.$ListFormat = ListFormat;
                mainApp.config.globalProperties.$ItemType = ItemType;
                mainApp.config.globalProperties.$DocumentNotificationRecipientType = DocumentNotificationRecipientType;
                mainApp.config.globalProperties.$AlertType = AlertType;
                mainApp.config.globalProperties.$DocumentTypeStatusPermission = DocumentTypeStatusPermission;
                mainApp.config.globalProperties.$DocumentTypeStatusOpenPermissionLabel = DocumentTypeStatusOpenPermissionLabel;
                mainApp.config.globalProperties.$CaseItemPartyApproval = CaseItemPartyApproval;
                mainApp.config.globalProperties.$CaseItemPartyApprovalString = CaseItemPartyApprovalString;
                mainApp.config.globalProperties.$DocumentState = DocumentState;
                mainApp.config.globalProperties.$CaseItemHistoryAction = CaseItemHistoryAction;
                mainApp.config.unwrapInjectedRef = true; // Temporary until vue version 3.3.x to use with provide/inject. Removes current warnings for computed provided properties
                mainApp.mount('#app');
            }
        });
    }
})();
