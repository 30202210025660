import { defineComponent } from 'vue';
import { OrganizationType } from '@/services/models/shared/OrganizationType';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppSelect from '@/components/shared/AppSelect.vue';
import AppTable from '@/components/shared/AppTable.vue';
import AppMultipleCheckbox from '@/components/shared/AppMultipleCheckbox.vue';
export default defineComponent({
    inject: ['userCanEdit', 'documentInformationNotEditable'],
    components: {
        AppCollapsingCardWithButtons,
        AppInput,
        AppMultipleCheckbox,
        AppTable,
        AppSelect
    },
    props: {
        initialDocumentData: {
            required: true,
            type: Object
        },
        organizationType: {
            required: true,
            type: Number
        }
    },
    computed: {
        isTWC() {
            if (this.organizationType & OrganizationType.twc) {
                return true;
            }
            return false;
        }
    },
    data() {
        return {
            documentData: {},
            isLoading: true,
            yesNoOptions: [{ value: true, item: 'Yes' }, { value: false, item: 'No' }],
            resetMobilityAid: false
        };
    },
    methods: {
        updateMobilityAid(e) {
            this.documentData.customer.mobilityAid = e;
            if (this.documentData.customer.hasMobilityDevice && this.documentData.customer.mobilityAid.length === 0) {
                this.documentData.customer.mobilityAid.push({
                    type: 'Manual Wheelchair',
                    make: null,
                    model: null,
                    weight: null,
                    seatMeasurement: null,
                    sittingHeight: null,
                    chairDepth: null,
                    chairWidth: null,
                    eyeHeight: null,
                    floorToSeatHeight: null,
                    specialFeatures: null
                });
                this.resetMobilityAid = !this.resetMobilityAid;
            }
        },
        toggleMobilityAidList(e) {
            if (e) {
                if (this.documentData.customer.mobilityAid === null || this.documentData.customer.mobilityAid.length < 1) {
                    this.documentData.customer.mobilityAid.push({
                        type: 'Manual Wheelchair',
                        make: null,
                        model: null,
                        weight: null,
                        seatMeasurement: null,
                        sittingHeight: null,
                        chairDepth: null,
                        chairWidth: null,
                        eyeHeight: null,
                        floorToSeatHeight: null,
                        specialFeatures: null
                    });
                }
            }
            else {
                this.documentData.customer.mobilityAid = [];
            }
        },
        resetFieldsVehicleIsGettingSpecialConversion() {
            if (!this.documentData.vehicleIsGettingSpecialConversion) {
                this.documentData.vehicleModificationType = [];
            }
        }
    },
    mounted() {
        this.documentData = this.initialDocumentData;
        this.isLoading = false;
    }
});
