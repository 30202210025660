import { defineComponent, ref } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
import AppTable from '@/components/shared/AppTable.vue';
import ManufacturersService from '@/services/ManufacturersService';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
export default defineComponent({
    emits: ['updateItemManufacturers'],
    components: {
        AppTable,
        BSModal,
        BSButton,
        AppInput,
        AppCollapsingCardWithButtons
    },
    setup() {
        const editModal = ref();
        return { editModal };
    },
    data() {
        return {
            manufacturerList: [],
            total: 0,
            isLoading: true,
            filterOptions: {
                page: 1,
                pageSize: 10,
                returnAll: false,
                name: null,
                onlyAssignedToAcceptedItems: false
            },
            submitButton: {
                text: 'Save',
                show: true
            },
            cancelButton: {
                text: 'Cancel',
                show: true
            },
            updateItemManufacturerListItem: null,
            addModalKey: false
        };
    },
    methods: {
        /**
        * @description Loads the data from the API bound to the component
        */
        loadData() {
            this.isLoading = true;
            ManufacturersService.getItemManufacturerList(this.filterOptions)
                .then(response => {
                this.total = response.totalCount;
                this.manufacturerList = response.data;
                this.$emit('updateItemManufacturers', response.data);
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        updatePagination(page, pageSize) {
            this.filterOptions.page = page;
            this.filterOptions.pageSize = pageSize;
            this.loadData();
        },
        addItemManufacturer() {
            var form = document.forms.namedItem('addItemManufacturerForm');
            if (form !== null) {
                var formData = new FormData(form);
                ManufacturersService.addItemManufacturer(formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadData();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        editItemManufacturer(id) {
            ManufacturersService.getItemManufacturer(id)
                .then(response => {
                this.updateItemManufacturerListItem = {
                    id: response.id,
                    name: response.name
                };
                if (this.editModal) {
                    this.editModal.show();
                }
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        updateItemManufacturer() {
            var form = document.forms.namedItem('updateItemManufacturerForm');
            if (form !== null && this.updateItemManufacturerListItem !== null) {
                var formData = new FormData(form);
                ManufacturersService.updateItemManufacturer(this.updateItemManufacturerListItem.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadData();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        removeItemManufacturer(id) {
            ManufacturersService.removeItemManufacturer(id)
                .then(response => {
                Notifications.methods.addToast(ToastType.success, response);
                this.loadData();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        }
    },
    created() {
        this.loadData();
    }
});
