import { defineComponent } from 'vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import { DocumentAttachmentStatus } from '@/services/models/shared/DocumentAttachmentStatus';
import DocumentService from '@/services/DocumentService';
import { OrganizationType } from '@/services/models/shared/OrganizationType';
import AppTable from '@/components/shared/AppTable.vue';
import AppSelect from '@/components/shared/AppSelect.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppTextArea from '@/components/shared/AppTextArea.vue';
import AppMultipleCheckbox from '@/components/shared/AppMultipleCheckbox.vue';
import BSAlert from '@/components/shared/bootstrap/Alert.vue';
import Notifications from '@/hooks/Notifications';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
export default defineComponent({
    inject: ['userIsVendor', 'userCanEdit', 'userCanRead', 'userCanWrite', 'documentState', 'documentInformationNotEditable', 'userIsAdministrator'],
    emits: ['informationFinishedLoading'],
    components: {
        AppCollapsingCardWithButtons,
        AppTable,
        AppSelect,
        AppInput,
        AppTextArea,
        AppMultipleCheckbox,
        BSAlert
    },
    props: {
        documentId: {
            required: true,
            type: String
        },
        initialDocumentData: {
            required: true,
            type: Object
        },
        organizationType: {
            required: true,
            type: Number
        },
        documentStatusId: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            documentData: {},
            isLoading: true,
            yesNoOptions: [{ value: true, item: 'Yes' }, { value: false, item: 'No' }],
            documentAttachments: [{
                    documentAttachmentTypeId: '',
                    documentAttachmentType: '',
                    documentAttachmentVersionList: [{
                            id: '',
                            fileName: '',
                            mimeType: '',
                            createdDate: new Date()
                        }],
                    filterOptions: {
                        page: 1,
                        pageSize: 5
                    }
                }],
            filterOptions: {
                documentAttachmentTypeId: null,
                isCustomerProvided: true,
                documentAttachmentStatus: DocumentAttachmentStatus.Saved,
                returnAllVersions: false,
                onlyNotDeleted: true,
                page: 1,
                pageSize: 10
            },
            resetMobilityAid: false
        };
    },
    computed: {
        isTWC() {
            if (this.organizationType & OrganizationType.twc) {
                return true;
            }
            return false;
        }
    },
    methods: {
        loadAttachmentsData() {
            DocumentService.getDocumentAttachments(this.documentId, this.filterOptions)
                .then(response => {
                if (response.data.length === 0) {
                    this.documentAttachments = [];
                }
                else {
                    this.documentAttachments = response.data;
                    this.documentAttachments.forEach((e) => {
                        e.filterOptions = {
                            page: 1,
                            pageSize: 5
                        };
                    });
                }
                this.isLoading = false;
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            })
                .finally(() => {
                this.$emit('informationFinishedLoading');
            });
        },
        updateMobilityAid(e) {
            this.documentData.customer.mobilityAid = e;
            if (this.documentData.customer.hasMobilityDevice && this.documentData.customer.mobilityAid.length === 0) {
                this.documentData.customer.mobilityAid.push({
                    type: 'Manual Wheelchair',
                    make: null,
                    model: null,
                    weight: null,
                    seatMeasurement: null,
                    sittingHeight: null,
                    chairDepth: null,
                    chairWidth: null,
                    eyeHeight: null,
                    floorToSeatHeight: null,
                    specialFeatures: null
                });
                this.resetMobilityAid = !this.resetMobilityAid;
            }
        },
        toggleMobilityAidList(e) {
            if (e) {
                if (this.documentData.customer.mobilityAid === null || this.documentData.customer.mobilityAid.length < 1) {
                    this.documentData.customer.mobilityAid.push({
                        type: 'Manual Wheelchair',
                        make: null,
                        model: null,
                        weight: null,
                        seatMeasurement: null,
                        sittingHeight: null,
                        chairDepth: null,
                        chairWidth: null,
                        eyeHeight: null,
                        floorToSeatHeight: null,
                        specialFeatures: null
                    });
                }
            }
            else {
                this.documentData.customer.mobilityAid = [];
            }
        },
        toggleVinNumberField(e) {
            if (!e) {
                this.documentData.vehicle.vinNumber = null;
            }
        },
        resetFields() {
            if (this.documentData.customer !== null &&
                this.documentData.customer &&
                this.documentData.customer.drivesAdaptiveEquipment !== null &&
                this.documentData.customer.drivesAdaptiveEquipment) {
                this.documentData.customer.adaptiveEquipment = {
                    year: 1900,
                    make: null,
                    model: null,
                    description: null
                };
            }
        },
        resetFieldsVehicleIsGettingSpecialConversion() {
            if (!this.documentData.vehicleIsGettingSpecialConversion) {
                this.documentData.vehicleModificationType = [];
            }
        },
        resetFieldsDriverLicenseIsCurrent() {
            if (!this.documentData.customer.driverLicenseIsCurrent) {
                this.documentData.customer.hasAppropriateRestrictions = null;
            }
        },
        hasDocumentAttachmentFileOfType(documentAttachmentTypeId) {
            var attachment = this.documentAttachments.filter((e) => {
                return e.documentAttachmentTypeId === documentAttachmentTypeId;
            });
            if (attachment && attachment.length === 1) {
                return true;
            }
            return false;
        }
    },
    mounted() {
        this.documentData = this.initialDocumentData;
        this.loadAttachmentsData();
    }
});
