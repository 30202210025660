import { defineComponent, ref } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
import AppTable from '@/components/shared/AppTable.vue';
import VehiclesService from '@/services/VehiclesService';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
export default defineComponent({
    emits: ['updateVehicles'],
    components: {
        AppTable,
        BSModal,
        BSButton,
        AppInput,
        AppCollapsingCardWithButtons
    },
    setup() {
        const editModal = ref();
        return { editModal };
    },
    data() {
        return {
            vehicleList: [],
            total: 0,
            isLoading: true,
            filterOptions: {
                page: 1,
                pageSize: 10,
                returnAll: false,
                make: null,
                model: null
            },
            submitButton: {
                text: 'Save',
                show: true
            },
            cancelButton: {
                text: 'Cancel',
                show: true
            },
            updatedVehicle: null,
            addModalKey: false
        };
    },
    methods: {
        /**
        * @description Loads the data from the API bound to the component
        */
        loadData() {
            this.isLoading = true;
            VehiclesService.getVehicleList(this.filterOptions)
                .then(response => {
                this.total = response.totalCount;
                this.vehicleList = response.data;
                this.$emit('updateVehicles', response.data);
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        updatePagination(page, pageSize) {
            this.filterOptions.page = page;
            this.filterOptions.pageSize = pageSize;
            this.loadData();
        },
        addVehicle() {
            var form = document.forms.namedItem('addVehicleForm');
            if (form !== null) {
                var formData = new FormData(form);
                VehiclesService.addVehicle(formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadData();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        editVehicle(id) {
            VehiclesService.getVehicle(id)
                .then(response => {
                this.updatedVehicle = {
                    id: id,
                    make: response.make,
                    model: response.model
                };
                if (this.editModal) {
                    this.editModal.show();
                }
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        updateVehicle() {
            var form = document.forms.namedItem('updateVehicleForm');
            if (form !== null && this.updatedVehicle !== null) {
                var formData = new FormData(form);
                VehiclesService.updateVehicle(this.updatedVehicle.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadData();
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        removeVehicle(id) {
            VehiclesService.removeVehicle(id)
                .then(response => {
                Notifications.methods.addToast(ToastType.success, response);
                this.loadData();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        }
    },
    created() {
        this.loadData();
    }
});
