import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        page: {
            type: Number,
            required: true
        },
        pageSize: {
            type: Number,
            required: true
        },
        totalCount: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            currentPage: 1
        };
    },
    emits: ['updatePagination'],
    computed: {
        totalPages() {
            return Math.ceil(this.totalCount / this.pageSize);
        },
        disablePrevious() {
            return this.currentPage === 1;
        },
        disableNext() {
            return this.currentPage === Math.ceil(this.totalCount / this.pageSize);
        }
    },
    methods: {
        updatePagination(page, pageSize) {
            this.currentPage = page;
            this.$emit('updatePagination', { page: page, pageSize: pageSize });
        },
        previousPage(pageNum) {
            return pageNum === 1 ? 0 : pageNum - 1;
        },
        nextPage(pageNum) {
            return pageNum === this.totalPages ? 0 : pageNum + 1;
        },
        isPreviousPage(pageNum) {
            return pageNum === this.currentPage - 1;
        },
        isNextPage(pageNum) {
            return pageNum === this.currentPage + 1;
        },
        isSecondPage(pageNum) {
            return pageNum === 2;
        },
        isSecondToLastPage(pageNum) {
            return pageNum === this.totalPages - 1;
        }
    },
    mounted() {
        this.currentPage = this.page;
    }
});
