import { defineComponent } from 'vue';
import WorksheetItem from '@/components/document/documentManagement/worksheetBuilderSections/WorksheetItem.vue';
export default defineComponent({
    name: 'WorksheetLine',
    emits: ['update:line'],
    components: {
        WorksheetItem
    },
    props: {
        line: {
            required: true,
            type: Object
        },
        parentId: {
            required: false,
            type: String,
            default: ''
        }
    },
    data() {
        return {
            lineInternal: {}
        };
    },
    created() {
        this.lineInternal = this.line;
    }
});
