import { defineComponent } from 'vue';
export default defineComponent({
    emits: ['disableSubmit', 'enableSubmit'],
    data() {
        return {
            signed: false
        };
    },
    methods: {
        currentSignedFile() {
            var currentValue = document.getElementById('signedFile').value;
            if (currentValue !== null && currentValue !== '') {
                this.$emit('enableSubmit');
            }
            else {
                this.$emit('disableSubmit');
            }
        }
    }
});
