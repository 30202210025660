import { defineComponent } from 'vue';
import { AlertType } from '@/services/models/shared/bootstrap/AlertType';
import { Alert } from 'bootstrap';
export default defineComponent({
    name: 'BSAlert',
    emits: ['destroyAlert'],
    props: {
        alertType: {
            required: true,
            type: String
        },
        message: {
            required: false,
            type: String,
            default: ''
        },
        title: {
            required: false,
            type: String,
            default: ''
        },
        dismissable: {
            required: false,
            type: Boolean,
            default: false
        },
        animate: {
            required: false,
            type: Boolean,
            default: true
        },
        showIcon: {
            required: false,
            type: Boolean,
            default: false
        },
        smaller: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            thisAlert: Object
        };
    },
    computed: {
        classes() {
            const classes = [];
            classes.push(this.alertType);
            classes.push(this.dismissable ? 'alert-dismissible' : null);
            classes.push(this.animate ? 'fade show' : null);
            return classes;
        },
        iconType() {
            switch (this.alertType) {
                case AlertType.success:
                    return 'fas fa-check-circle';
                case AlertType.warning:
                    return 'fas fa-exclamation-triangle';
                case AlertType.danger:
                    return 'fas fa-times-circle';
                case AlertType.info:
                    return 'fas fa-info-circle';
            }
            return null;
        }
    },
    mounted() {
        this.thisAlert = new Alert(this.$el);
        this.$el.addEventListener('closed.bs.alert', (e) => {
            this.$emit('destroyAlert');
        });
    },
    unmounted() {
        this.$el.removeEventListener('closed.bs.alert', (e) => {
            this.$emit('destroyAlert');
        });
    }
});
