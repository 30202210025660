export class InputType {
    static text = 'text';
    static email = 'email';
    static password = 'password';
    static color = 'color';
    static number = 'number';
    static date = 'date';
    static hidden = 'hidden';
    static file = 'file';
    static time = 'time';
}
