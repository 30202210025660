import { defineComponent } from 'vue';
import AppInput from '@/components/shared/AppInput.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import DocumentService from '@/services/DocumentService';
export default defineComponent({
    emits: ['update:modelValue'],
    components: {
        AppInput,
        BSButton,
        AppCollapsingCardWithButtons
    },
    props: {
        modelValue: {
            required: true,
            type: Object
        },
        documentId: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            options: []
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        loadData() {
            DocumentService.getInspectionPhotos(this.documentId)
                .then(response => {
                this.options = response;
            });
        },
        imgUrl(fileContent, mimeType) {
            return `data:${mimeType};base64, ${fileContent}`;
        },
        getImgUrl(value) {
            const photoOptionValue = this.options.find((x) => { return x.value === value; });
            if (photoOptionValue) {
                return this.imgUrl(photoOptionValue.content, photoOptionValue.mimeType);
            }
            return '';
        },
        isSelected(value) {
            const valueIndex = this.value.findIndex((x) => { return x.file === value; });
            return valueIndex > -1;
        },
        moveUp(index) {
            const value = this.value[index];
            const switchingValue = this.value[index - 1];
            if (value && switchingValue) {
                this.value.splice(index - 1, 2, value, switchingValue);
            }
        },
        moveDown(index) {
            const value = this.value[index];
            const switchingValue = this.value[index + 1];
            if (value && switchingValue) {
                this.value.splice(index, 2, switchingValue, value);
            }
        },
        updateValue(value) {
            const valueIndex = this.value.findIndex((x) => { return x.file === value; });
            if (valueIndex > -1) {
                this.value.splice(valueIndex, 1);
            }
            else {
                this.value.push({
                    file: value,
                    description: ''
                });
            }
        }
    },
    created() {
        this.loadData();
    }
});
