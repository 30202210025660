import AveHttpService from '@/services/AveHttpService';
class OrganizationsService {
    /**
     *  @description Retrieves a specific organization's data
     */
    getOrganization(organizationId) {
        return AveHttpService.get(`api/v1/organizations/${organizationId}`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all organizations based on filter requirements
     */
    getOrganizations(filter) {
        return AveHttpService.get('api/v1/organizations', { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Adds a new organization record
     */
    addOrganization(data) {
        return (AveHttpService.post('api/v1/organizations/add', data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Updates an organization record
     */
    updateOrganization(organizationId, data) {
        return (AveHttpService.patch(`api/v1/organizations/${organizationId}/update`, data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Removes an organization record
     */
    removeOrganization(organizationId) {
        return (AveHttpService.delete(`api/v1/organizations/${organizationId}/remove`)).then((response) => {
            return response.data;
        });
    }
}
export default new OrganizationsService();
