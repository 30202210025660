import { defineComponent, ref } from 'vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import DocumentService from '@/services/DocumentService';
import Notifications from '@/hooks/Notifications';
import VehicleModificationInformation from '@/components/document/information/VehicleModificationInformation.vue';
import PricingReviewInformation from '@/components/document/information/PricingReviewInformation.vue';
import RepairReviewInformation from '@/components/document/information/RepairReviewInformation.vue';
import CarFaxInformation from '@/components/document/information/CarFaxInformation.vue';
import VehicleInspectionInformation from '@/components/document/information/VehicleInspectionInformation.vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import { DocumentType } from '@/services/models/shared/DocumentType';
import { DocumentStatus } from '@/services/models/shared/DocumentStatus';
export default defineComponent({
    inject: ['userCanEdit', 'userCanRead', 'userCanWrite', 'userIsEmployee', 'userIsAdministrator', 'documentState', 'documentInformationNotEditable', 'formVersion'],
    emits: ['requestDataCompleted', 'refreshDocumentAttachmentsList', 'reloadCustomerAttachments', 'generateAndSave3408Form'],
    components: {
        BSButton,
        VehicleModificationInformation,
        PricingReviewInformation,
        RepairReviewInformation,
        CarFaxInformation,
        VehicleInspectionInformation
    },
    props: {
        documentId: {
            required: true,
            type: String
        },
        documentTypeId: {
            required: true,
            type: String
        },
        documentStatusId: {
            required: true,
            type: String
        },
        organizationType: {
            required: true,
            type: Number
        }
    },
    setup() {
        const carFaxInformation = ref();
        const priceReviewInformation = ref();
        const repairReviewInformation = ref();
        const modificationInformation = ref();
        const inspectionInformation = ref();
        return { carFaxInformation, priceReviewInformation, repairReviewInformation, modificationInformation, inspectionInformation };
    },
    data() {
        return {
            requestData: null,
            submitDisabled: false
        };
    },
    methods: {
        loadData() {
            DocumentService.getDocumentInformation(this.documentId)
                .then(response => {
                this.requestData = response;
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        showRequestSection(requestTypeId) {
            return this.$stringUtilities.compareIds(this.documentTypeId, requestTypeId);
        },
        submitData(validateRequiredInputs) {
            var hasErrors = false;
            var hasRequiredErrors = false;
            var formElement = document.getElementsByName('informationForm')[0];
            if (formElement) {
                var formInputs = formElement.querySelectorAll('input:not([type="hidden"]), select');
                if (formInputs.length > 0) {
                    formInputs.forEach((input) => {
                        if (!this.$inputValidationUtilities.validateInput(input, false, true, true, validateRequiredInputs)) {
                            hasErrors = true;
                        }
                        if (!this.$inputValidationUtilities.validateInput(input, false, false, false, true)) {
                            hasRequiredErrors = true;
                        }
                    });
                }
                if (hasErrors || hasRequiredErrors) {
                    this.$emit('requestDataCompleted', false);
                    if (hasErrors) {
                        Notifications.methods.addToast(ToastType.danger, hasRequiredErrors && validateRequiredInputs ? 'Please fill out required fields before submitting' : 'Please check format of input information');
                        return;
                    }
                }
                else if (this.$stringUtilities.compareIds(DocumentStatus.Pending, this.documentStatusId) || this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)) {
                    this.$emit('requestDataCompleted', true);
                }
            }
            var form = document.forms.namedItem('informationForm');
            if (form !== null) {
                var formData = new FormData(form);
                switch (this.documentTypeId) {
                    case DocumentType.carFax:
                        formData.append('jsonData', JSON.stringify(this.carFaxInformation?.$data.documentData));
                        break;
                    case DocumentType.priceReview:
                        formData.append('jsonData', JSON.stringify(this.priceReviewInformation?.$data.documentData));
                        break;
                    case DocumentType.repair:
                        formData.append('jsonData', JSON.stringify(this.repairReviewInformation?.$data.documentData));
                        break;
                    case DocumentType.modification:
                        formData.append('jsonData', JSON.stringify(this.modificationInformation?.$data.documentData));
                        break;
                    case DocumentType.inspection:
                        formData.append('jsonData', JSON.stringify(this.inspectionInformation?.$data.documentData));
                        break;
                }
                this.$loadingBackdrop.data.message = 'This may take up to 5 minutes...';
                this.$loadingBackdrop.data.isVisible = true;
                DocumentService.changeRequestInformation(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                    if (this.formVersion !== '1.0' && ((this.userIsEmployee && this.userCanEdit) || this.userIsAdministrator)) {
                        this.$emit('generateAndSave3408Form');
                    }
                    else {
                        this.$emit('refreshDocumentAttachmentsList');
                    }
                    this.requestData = null;
                    this.loadData();
                })
                    .catch(error => {
                    this.$emit('requestDataCompleted', false);
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.$loadingBackdrop.data.isVisible = false;
                    this.$loadingBackdrop.data.message = '';
                });
            }
        },
        checkRequestForm() {
            var hasErrors = false;
            var formElement = document.getElementsByName('informationForm')[0];
            if (formElement) {
                var formInputs = formElement.querySelectorAll('input:not([type="hidden"]), select');
                if (formInputs.length > 0) {
                    formInputs.forEach((input) => {
                        if (!this.$inputValidationUtilities.validateInput(input, false, false, false, true)) {
                            hasErrors = true;
                        }
                    });
                }
                if (!hasErrors) {
                    this.$emit('requestDataCompleted', true);
                }
                else {
                    this.$emit('requestDataCompleted', false);
                }
            }
        }
    },
    created() {
        this.loadData();
    }
});
