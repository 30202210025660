import { defineComponent, ref, computed } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
import DocumentService from '@/services/DocumentService';
import UsersService from '@/services/UsersService';
import DocumentAttachmentsDownload from '@/components/document/shared/DocumentAttachmentsDownload.vue';
import DocumentManagement from '@/components/document/DocumentManagement.vue';
import DocumentHistoryComp from '@/components/document/DocumentHistory.vue';
import DocumentInformation from '@/components/document/DocumentInformation.vue';
import DocumentNotifications from '@/components/document/DocumentNotifications.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
import AppTagContainer from '@/components/shared/AppTagContainer.vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import { DocumentState } from '@/services/models/shared/DocumentState';
import { DocumentStatus } from '@/services/models/shared/DocumentStatus';
import { DocumentType } from '@/services/models/shared/DocumentType';
import { DocumentNotificationRecipientType } from '@/services/models/DocumentNotification';
import { EmailTemplateType } from '@/services/models/shared/EmailTemplateType';
import { DocumentTypeStatusPermission } from '@/services/models/shared/DocumentTypeStatusPermission';
import { ApplicationRole } from '@/services/models/Role';
import { DocumentAttachmentStatus } from '@/services/models/shared/DocumentAttachmentStatus';
import { OrganizationType } from '@/services/models/shared/OrganizationType';
export default defineComponent({
    inject: ['userIsEmployee', 'userIsAdministrator', 'userIsVendor', 'userIsCustomer'],
    provide() {
        return {
            userCanRead: computed(() => this.userCanRead),
            userCanWrite: computed(() => this.userCanWrite),
            userCanEdit: computed(() => this.userCanEdit),
            userCanMove: computed(() => this.userCanMove),
            documentIsArchived: computed(() => this.documentIsArchived),
            documentState: computed(() => this.document.documentState),
            formVersion: computed(() => this.formVersion)
        };
    },
    components: {
        DocumentAttachmentsDownload,
        DocumentManagement,
        DocumentHistoryComp,
        DocumentNotifications,
        BSButton,
        BSModal,
        AppTagContainer,
        AppCollapsingCardWithButtons,
        DocumentInformation
    },
    setup() {
        const workAttachmentsDownload = ref();
        const customerAttachmentsDownload = ref();
        return { workAttachmentsDownload, customerAttachmentsDownload };
    },
    data() {
        return {
            isLoading: true,
            activeTab: 'information',
            documentTypeStatusUserPermissions: null,
            document: {},
            changedStatus: null,
            changedAssignee: null,
            recipientName: '',
            recipientEmailAddress: '',
            ccName: '',
            ccEmailAddress: '',
            subject: '',
            body: '',
            sendSeparately: true,
            notificationAttachments: [],
            submitButton: {
                text: 'Save',
                show: true
            },
            cancelButton: {
                text: 'Cancel',
                show: true
            },
            processingEmployees: [],
            emailTemplates: [],
            emailTemplate: 0,
            recipientList: [],
            recipients: [],
            sendOnBehalfOfSubmitter: true,
            documentAttachmentsPdfDistinct: [],
            addModalKey: false,
            forceReloadFlag: false
        };
    },
    computed: {
        documentIsArchived() {
            return this.document.documentState !== DocumentState.Open && this.document.documentState !== DocumentState.Draft;
        },
        selectedRecipients() {
            return this.recipients.filter(function (item) {
                return item.type === DocumentNotificationRecipientType.normal;
            }).map(function (item) {
                return item.fullName + ' <' + item.emailAddress + '>';
            });
        },
        selectedCCs() {
            return this.recipients.filter(function (item) {
                return item.type === DocumentNotificationRecipientType.cc;
            }).map(function (item) {
                return item.fullName + ' <' + item.emailAddress + '>';
            });
        },
        userCanRead() {
            return this.documentTypeStatusUserPermissions !== null && this.documentTypeStatusUserPermissions.includes(DocumentTypeStatusPermission.read);
        },
        userCanWrite() {
            return this.documentTypeStatusUserPermissions !== null && this.documentTypeStatusUserPermissions.includes(DocumentTypeStatusPermission.readWrite);
        },
        userCanEdit() {
            return this.documentTypeStatusUserPermissions !== null && this.documentTypeStatusUserPermissions.includes(DocumentTypeStatusPermission.readEdit);
        },
        userCanMove() {
            return this.documentTypeStatusUserPermissions !== null && this.documentTypeStatusUserPermissions.includes(DocumentTypeStatusPermission.readMove);
        },
        userHasDocumentPermissions() {
            return this.documentTypeStatusUserPermissions !== null && this.documentTypeStatusUserPermissions.length > 0;
        },
        formVersion() {
            return this.document.requestData === null ? '1.0' : this.document.requestData.formVersion;
        },
        isTWC() {
            if (this.document.organizationType & OrganizationType.twc) {
                return true;
            }
            return false;
        }
    },
    methods: {
        /**
        * @description Loads the data from the API bound to the component
        */
        async loadData() {
            await DocumentService.getDocumentByHumanReadableId(this.$route.params.humanReadableId.toString())
                .then(response => {
                this.document = response;
                // Get current usser's permissions for specific document type and status
                UsersService.getUserDocumentPermissions(this.$store.getters.user.id, { currentRoleId: this.$store.getters.currentRoleId, documentId: this.document.id })
                    .then(response => {
                    this.documentTypeStatusUserPermissions = response;
                    if (this.document.documentState !== DocumentState.Open && this.document.documentState !== DocumentState.Draft) {
                        if (this.$route.matched[0].name !== 'Archive') {
                            this.$router.replace({ name: 'ArchiveDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                        }
                    }
                    else if ((this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole['AVE Administrator'] || this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole.Administrator)) {
                        if (this.$route.matched[0].name !== 'Requests') {
                            this.$router.replace({ name: 'RequestsDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                        }
                    }
                    else if ((this.document.documentState === DocumentState.Draft && this.documentTypeStatusUserPermissions.includes(DocumentTypeStatusPermission.readEdit)) || (this.document.documentState === DocumentState.Open && this.documentTypeStatusUserPermissions.includes(DocumentTypeStatusPermission.readWrite))) {
                        if (this.$route.matched[0].name !== 'Inbox') {
                            this.$router.replace({ name: 'InboxDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                        }
                    }
                    else if (this.$route.matched[0].name !== 'Outbox') {
                        this.$router.replace({ name: 'OutboxDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                    }
                })
                    .catch(() => {
                    Notifications.methods.addToast(ToastType.danger, 'Unable to load document. Please contact the site administrators if the issue persists.');
                    this.documentTypeStatusUserPermissions = null;
                });
                // Get processing status employees
                UsersService.getStatusUsers(this.document.documentTypeId, DocumentStatus.Processing)
                    .then(response => {
                    this.processingEmployees = response;
                })
                    .catch(() => {
                    this.processingEmployees = [];
                });
                // Get email template list
                var keys = Object.keys(EmailTemplateType);
                keys.forEach((e) => {
                    this.emailTemplates.push(EmailTemplateType[e]);
                });
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        loadPossibleNotificationAttachments() {
            DocumentService.getDocumentAttachments(this.document.id, {
                documentAttachmentTypeId: null,
                onlyNotDeleted: false,
                isCustomerProvided: null,
                documentAttachmentStatus: DocumentAttachmentStatus.Saved,
                returnAllVersions: false,
                page: 1,
                pageSize: 10
            })
                .then(response => {
                this.documentAttachmentsPdfDistinct = response.data;
            })
                .catch(() => {
                this.documentAttachmentsPdfDistinct = [];
            });
        },
        loadContacts() {
            UsersService.getContacts(this.$store.getters.user.id)
                .then(response => {
                response.forEach((user) => {
                    this.recipientList.push({
                        item: user.fullName,
                        value: user.emailAddress
                    });
                });
                this.recipientList.push({
                    item: this.document.submitterFullName,
                    value: this.document.submitterEmailAddress
                });
            })
                .catch(() => {
                this.recipientList = [];
            });
        },
        clearSendNotificationModal() {
            this.emailTemplate = 0;
            this.recipientList = [];
            this.recipientName = '';
            this.sendOnBehalfOfSubmitter = true;
            this.recipientEmailAddress = '';
            this.recipients = [];
            this.sendSeparately = true;
            this.ccName = '';
            this.ccEmailAddress = '';
            this.subject = '';
            this.body = '';
            this.notificationAttachments = [];
            this.documentAttachmentsPdfDistinct = [];
        },
        changeStatus(changedStatus, changedAssignee) {
            var form = document.forms.namedItem('changeStatusForm');
            if (form !== null) {
                this.$loadingBackdrop.data.isVisible = true;
                var formData = new FormData(form);
                DocumentService.changeDocumentStatus(this.document.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                    if (changedStatus === DocumentStatus.Completed) {
                        if (this.$route.name === 'ArchiveDocumentDetails')
                            this.forceReloadFlag = !this.forceReloadFlag;
                        else
                            this.$router.push({ name: 'ArchiveDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                    }
                    else if (this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole['AVE Administrator'] || this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole.Administrator) {
                        if (this.$route.name === 'RequestsDocumentDetails')
                            this.forceReloadFlag = !this.forceReloadFlag;
                        else
                            this.$router.push({ name: 'RequestsDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                    }
                    else {
                        if (changedStatus === DocumentStatus.Processing) {
                            if (this.$store.getters.user.id.toLowerCase() === changedAssignee) {
                                if (this.$route.name === 'InboxDocumentDetails')
                                    this.forceReloadFlag = !this.forceReloadFlag;
                                else
                                    this.$router.push({ name: 'InboxDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                            }
                            else {
                                if (this.$route.name === 'OutboxDocumentDetails')
                                    this.forceReloadFlag = !this.forceReloadFlag;
                                else
                                    this.$router.push({ name: 'OutboxDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                            }
                            return;
                        }
                        UsersService.getStatusUsers(this.document.documentTypeId, this.document.documentStatusId)
                            .then(response => {
                            if (response.length === 0) {
                                this.$router.push({ name: 'Home' });
                            }
                            var user = response.findIndex((x) => {
                                return x.id.toLowerCase() === this.$store.getters.user.id.toLowerCase();
                            });
                            if (user < 0) {
                                if (this.$route.name === 'OutboxDocumentDetails')
                                    this.forceReloadFlag = !this.forceReloadFlag;
                                else
                                    this.$router.push({ name: 'OutboxDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                                return;
                            }
                            if (this.$route.name === 'InboxDocumentDetails')
                                this.forceReloadFlag = !this.forceReloadFlag;
                            else
                                this.$router.push({ name: 'InboxDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                        });
                    }
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        rejectRequest() {
            var form = document.forms.namedItem('rejectForm');
            if (form !== null) {
                this.$loadingBackdrop.data.isVisible = true;
                var formData = new FormData(form);
                DocumentService.changeDocumentStatus(this.document.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                    this.$router.push({ name: 'ArchiveDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        cancelRequest() {
            var form = document.forms.namedItem('cancelForm');
            if (form !== null) {
                this.$loadingBackdrop.data.isVisible = true;
                var formData = new FormData(form);
                DocumentService.changeDocumentStatus(this.document.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                    this.$router.push({ name: 'ArchiveDocumentDetails', params: { humanReadableId: this.document.humanReadableId } });
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        sendNotification() {
            var form = document.forms.namedItem('sendNotificationForm');
            if (form !== null) {
                this.$loadingBackdrop.data.isVisible = true;
                var formData = new FormData(form);
                DocumentService.sendNotification(this.document.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        statusShow(status) {
            switch (this.document.documentTypeId) {
                case DocumentType.carFax:
                    if (this.document.documentState === DocumentState.Ignored) {
                        return false;
                    }
                    switch (status) {
                        case DocumentStatus.Approved:
                        case DocumentStatus.Bill:
                        case DocumentStatus.Billed:
                        case DocumentStatus.Billing:
                        case DocumentStatus.Review:
                            return false;
                        default:
                            if (status === this.document.documentStatusId && status !== DocumentStatus.Processing) {
                                return false;
                            }
                            return true;
                    }
                case DocumentType.inspection:
                    if (this.document.documentState === DocumentState.Ignored) {
                        return false;
                    }
                    if (status === this.document.documentStatusId && status !== DocumentStatus.Processing) {
                        return false;
                    }
                    return true;
                case DocumentType.modification:
                    if (this.document.documentState === DocumentState.Ignored) {
                        return false;
                    }
                    if (status === this.document.documentStatusId && status !== DocumentStatus.Processing) {
                        return false;
                    }
                    return true;
                case DocumentType.priceReview:
                    if (this.document.documentState === DocumentState.Ignored) {
                        return false;
                    }
                    switch (status) {
                        case DocumentStatus.Approved:
                        case DocumentStatus.Bill:
                        case DocumentStatus.Billed:
                        case DocumentStatus.Billing:
                        case DocumentStatus.Review:
                            return false;
                        default:
                            if (status === this.document.documentStatusId && status !== DocumentStatus.Processing) {
                                return false;
                            }
                            return true;
                    }
                case DocumentType.repair:
                    if (this.document.documentState === DocumentState.Ignored) {
                        return false;
                    }
                    switch (status) {
                        case DocumentStatus.Approved:
                        case DocumentStatus.Review:
                            return false;
                        default:
                            if (status === this.document.documentStatusId && status !== DocumentStatus.Processing) {
                                return false;
                            }
                            return true;
                    }
            }
        },
        clearData() {
            this.document = {};
            this.recipientName = '';
            this.recipientEmailAddress = '';
            this.ccName = '';
            this.ccEmailAddress = '';
            this.subject = '';
            this.body = '';
            this.sendSeparately = true;
            this.notificationAttachments = [];
            this.processingEmployees = [];
            this.emailTemplates = [];
            this.emailTemplate = 0;
            this.recipientList = [];
        },
        insertRecipient(emailAddress, fullName, type) {
            var existingIndex = this.recipients.findIndex((x) => {
                return x.emailAddress.toLowerCase() === emailAddress.toLowerCase() && x.type === type;
            });
            if (existingIndex === -1 && !(emailAddress.replace(/\s+/g, '').length === 0 || fullName.replace(/\s+/g, '').length === 0)) {
                this.recipients.push({
                    emailAddress: emailAddress,
                    fullName: fullName,
                    type: type
                });
            }
            if (type === DocumentNotificationRecipientType.cc) {
                this.ccName = '';
                this.ccEmailAddress = '';
            }
            else {
                this.recipientName = '';
                this.recipientEmailAddress = '';
            }
        },
        removeRecipient(val, type) {
            var indexOfEmail = val.indexOf('<');
            if (indexOfEmail > -1) {
                var emailAddress = val.substring(indexOfEmail + 1, val.length - 1);
                var existingIndex = this.recipients.findIndex((x) => {
                    return x.emailAddress.toLowerCase() === emailAddress.toLowerCase() && x.type === type;
                });
                if (existingIndex > -1) {
                    this.recipients.splice(existingIndex, 1);
                }
            }
        },
        reloadWorkAttachments() {
            if (this.workAttachmentsDownload) {
                this.workAttachmentsDownload.loadData();
            }
        },
        reloadCustomerAttachments() {
            if (this.customerAttachmentsDownload) {
                this.customerAttachmentsDownload.loadData();
            }
        }
    },
    created() {
        this.loadData()
            .finally(() => {
            this.isLoading = false;
        });
    }
});
