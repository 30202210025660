export var DocumentStatus;
(function (DocumentStatus) {
    DocumentStatus["Draft"] = "12034c25-dd57-4815-bb56-25b8a0a222a1";
    DocumentStatus["Escalated"] = "34be9ce9-8989-4de2-b404-7b3b3cc1af18";
    DocumentStatus["Pending"] = "59c6514b-db59-415e-b27e-17764edea4da";
    DocumentStatus["Approved"] = "79ebc722-5cb2-4433-bbcf-090ee4b538c1";
    DocumentStatus["Review"] = "e305c366-4969-4728-9fc6-15687b03e3a9";
    DocumentStatus["Assigning"] = "25aaead7-ca72-41be-8e42-2e386bf3f3d7";
    DocumentStatus["Cancelled"] = "ec76b5f5-af70-4084-93ed-3ae17d57e1bb";
    DocumentStatus["Billing"] = "e03beb91-19e9-46a1-a2f2-4f36ca1cf52b";
    DocumentStatus["Bill"] = "f5002acd-f7fb-4849-9ecc-eee852bff937";
    DocumentStatus["Billed"] = "b74e68a4-a785-40b4-8475-f23022e48985";
    DocumentStatus["Rejected"] = "a3920aa0-b27b-4463-8436-6000dd61ab8d";
    DocumentStatus["Processing"] = "728a7308-885c-45c8-b6f7-b994188596e0";
    DocumentStatus["Completed"] = "5facd878-40ac-46af-bf5b-c0c0614bc0a2";
})(DocumentStatus || (DocumentStatus = {}));
export var ArchivedDocumentStatus;
(function (ArchivedDocumentStatus) {
    ArchivedDocumentStatus["Cancelled"] = "ec76b5f5-af70-4084-93ed-3ae17d57e1bb";
    ArchivedDocumentStatus["Rejected"] = "a3920aa0-b27b-4463-8436-6000dd61ab8d";
    ArchivedDocumentStatus["Completed"] = "5facd878-40ac-46af-bf5b-c0c0614bc0a2";
})(ArchivedDocumentStatus || (ArchivedDocumentStatus = {}));
export var NotArchivedDocumentStatus;
(function (NotArchivedDocumentStatus) {
    NotArchivedDocumentStatus["Draft"] = "12034c25-dd57-4815-bb56-25b8a0a222a1";
    NotArchivedDocumentStatus["Escalated"] = "34be9ce9-8989-4de2-b404-7b3b3cc1af18";
    NotArchivedDocumentStatus["Pending"] = "59c6514b-db59-415e-b27e-17764edea4da";
    NotArchivedDocumentStatus["Approved"] = "79ebc722-5cb2-4433-bbcf-090ee4b538c1";
    NotArchivedDocumentStatus["Review"] = "e305c366-4969-4728-9fc6-15687b03e3a9";
    NotArchivedDocumentStatus["Assigning"] = "25aaead7-ca72-41be-8e42-2e386bf3f3d7";
    NotArchivedDocumentStatus["Billing"] = "e03beb91-19e9-46a1-a2f2-4f36ca1cf52b";
    NotArchivedDocumentStatus["Bill"] = "f5002acd-f7fb-4849-9ecc-eee852bff937";
    NotArchivedDocumentStatus["Billed"] = "b74e68a4-a785-40b4-8475-f23022e48985";
    NotArchivedDocumentStatus["Processing"] = "728a7308-885c-45c8-b6f7-b994188596e0";
})(NotArchivedDocumentStatus || (NotArchivedDocumentStatus = {}));
export var ExternalUserDocumentStatus;
(function (ExternalUserDocumentStatus) {
    ExternalUserDocumentStatus["Draft"] = "12034c25-dd57-4815-bb56-25b8a0a222a1";
    ExternalUserDocumentStatus["Escalated"] = "34be9ce9-8989-4de2-b404-7b3b3cc1af18";
    ExternalUserDocumentStatus["Pending"] = "59c6514b-db59-415e-b27e-17764edea4da";
})(ExternalUserDocumentStatus || (ExternalUserDocumentStatus = {}));
