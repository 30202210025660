import AveHttpService from '@/services/AveHttpService';
class DocumentService {
    /**
     *  @description Retrieves a specific document's data
     */
    getDocumentByHumanReadableId(humanReadableId) {
        return AveHttpService.get(`api/v1/documents/details/${humanReadableId}`).then((response) => {
            const document = response.data;
            return document;
        });
    }
    /**
     *  @description Retrieves all document history for a specific document id
     */
    getDocumentHistory(documentId, filter) {
        return AveHttpService.get(`api/v1/documents/${documentId}/history`, { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves a specific document's data
     */
    getDocumentInformation(documentId) {
        return AveHttpService.get(`api/v1/documents/${documentId}/request`).then((response) => {
            const jsonData = response.data;
            return (JSON.parse(jsonData.toString()));
        });
    }
    /**
     *  @description Retrieves a specific document attachment for downlaod
     */
    getDocumentAttachmentFile(documentAttachmentId) {
        return AveHttpService.get(`api/v1/documents/${documentAttachmentId}/attachment/download`, { responseType: 'arraybuffer' }).then((response) => {
            return {
                blob: new Blob([new Uint8Array(response.data)], { type: 'application/pdf' }),
                name: (response.headers['content-disposition'].split('filename=')[1].split(';')[0]).replace(/['"]+/g, '')
            };
        })
            .catch(error => {
            throw new TextDecoder('utf-8').decode(new Uint8Array(error.response.data)).replace(/['"]+/g, '');
        });
    }
    /**
     *  @description Retrieves a specific document attachment data
     */
    getDocumentAttachment(documentAttachmentId) {
        return AveHttpService.get(`api/v1/documents/${documentAttachmentId}/attachment`).then((response) => {
            return response.data;
        });
    }
    getDocumentAttachments(documentId, filter) {
        return AveHttpService.get(`api/v1/documents/${documentId}/attachments`, { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     * @description Retrieves the document notifications for a document
     */
    getDocumentNotifications(documentId, filter) {
        return AveHttpService.get(`api/v1/documents/${documentId}/notifications`, { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     * @description Retrieves the documetn notification with the specified id
     */
    getDocumentNotification(documentNotificationId) {
        return AveHttpService.get(`api/v1/documents/${documentNotificationId}/notification`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all active documents
     */
    getActive(filter) {
        return AveHttpService.get('api/v1/documents/active', { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all documents for a specific user's inbox
     */
    getInbox(userId, filter) {
        return AveHttpService.get(`api/v1/documents/inbox/${userId}`, { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all documents for a specific user's outbox
     */
    getOutbox(userId, filter) {
        return AveHttpService.get(`api/v1/documents/outbox/${userId}`, { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all documents that are archived
     */
    getArchive(userId, filter) {
        return AveHttpService.get(`api/v1/documents/archive/${userId}`, { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Change's document's status
     */
    changeDocumentStatus(documentId, data) {
        return AveHttpService.put(`api/v1/documents/${documentId}/changestatus`, data);
    }
    /**
     *  @description Submit 3408 form
     */
    submit3408(documentId, data) {
        return AveHttpService.put(`api/v1/documents/${documentId}/3408/submit`, data);
    }
    /**
     *  @description Save review letter for request and sign if indicated
     */
    submitReviewLetter(documentId, data) {
        return AveHttpService.put(`api/v1/documents/${documentId}/reviewletter/submit`, data);
    }
    /**
     *  @description Submits a document attachment for a document
     */
    submitAttachment(documentId, data) {
        return AveHttpService.put(`api/v1/documents/${documentId}/attachment/submit`, data);
    }
    /**
     *  @description Submits a document attachments for a document
     */
    submitAttachments(documentId, data) {
        return AveHttpService.put(`api/v1/documents/${documentId}/attachments/submit`, data);
    }
    /**
     *  @description Sends email notification to recipients listed
     */
    sendNotification(documentId, data) {
        return (AveHttpService.post(`api/v1/documents/${documentId}/sendNotification`, data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves a review letter's data for a specific document
     */
    getReviewLetterData(documentId) {
        return AveHttpService.get(`api/v1/documents/${documentId}/reviewLetter`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves the generated review letter in pdf form, unsigned
     */
    generateReviewLetterPdf(documentId) {
        return AveHttpService.get(`api/v1/documents/${documentId}/reviewLetter/generate`, { responseType: 'arraybuffer' }).then((response) => {
            return {
                blob: new Blob([response.data], { type: 'application/pdf' }),
                name: (response.headers['content-disposition'].split('filename=')[1].split(';')[0]).replace(/['"]+/g, '')
            };
        })
            .catch(error => {
            throw new TextDecoder('utf-8').decode(new Uint8Array(error.response.data)).replace(/['"]+/g, '');
        });
    }
    /**
     * @description Submits updated request information data
     */
    changeRequestInformation(documentId, data) {
        return AveHttpService.post(`api/v1/documents/${documentId}/information/submit`, data);
    }
    /**
     * @description Retrieves the json data for an inspection sheet
     */
    getInspectionSheetsData(documentId) {
        return AveHttpService.get(`api/v1/documents/${documentId}/inspectionSheetsData`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Save inspection sheets for request
     */
    submitInspectionSheets(documentId, data) {
        return AveHttpService.put(`api/v1/documents/${documentId}/inspectionSheets/submit`, data);
    }
    /**
     *  @description Retrieves a inspection report's data for a specific document
     */
    getInspectionReportData(documentId) {
        return AveHttpService.get(`api/v1/documents/${documentId}/inspectionReport`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves a inspection photos
     */
    getInspectionPhotos(documentId) {
        return AveHttpService.get(`api/v1/documents/${documentId}/inspectionPhotos`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves the generated inspection report in pdf form, unsigned
     */
    generateInspectionReportPdf(documentId) {
        return AveHttpService.get(`api/v1/documents/${documentId}/inspectionReport/generate`, { responseType: 'arraybuffer' }).then((response) => {
            return {
                blob: new Blob([response.data], { type: 'application/pdf' }),
                name: (response.headers['content-disposition'].split('filename=')[1].split(';')[0]).replace(/['"]+/g, '')
            };
        })
            .catch(error => {
            throw new TextDecoder('utf-8').decode(new Uint8Array(error.response.data)).replace(/['"]+/g, '');
        });
    }
    /**
     *  @description Save inspection report for request and sign if indicated
     */
    submitInspectionReport(documentId, data) {
        return AveHttpService.put(`api/v1/documents/${documentId}/inspectionReport/submit`, data);
    }
    /**
     *  @description Remove document attachment
     */
    removeDocumentAttachment(documentAttachmentId, data) {
        return AveHttpService.delete(`api/v1/documents/attachments/${documentAttachmentId}/remove`, { data: data }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves the generated 3408 form in pdf form, unsigned
     */
    generate3408FormPdf(documentId) {
        return AveHttpService.get(`api/v1/documents/${documentId}/3408Form/generate`, { responseType: 'arraybuffer' }).then((response) => {
            return {
                blob: new Blob([response.data], { type: 'application/pdf' }),
                name: (response.headers['content-disposition'].split('filename=')[1].split(';')[0]).replace(/['"]+/g, '')
            };
        })
            .catch(error => {
            throw new TextDecoder('utf-8').decode(new Uint8Array(error.response.data)).replace(/['"]+/g, '');
        });
    }
    /**
     *  @description Updates the 3408 form by generating a new one and replacing current
     */
    generateAndSave3408FormPdf(documentId, data) {
        return AveHttpService.put(`api/v1/documents/${documentId}/3408Form/generate`, data);
    }
}
export default new DocumentService();
