import { defineComponent } from 'vue';
import { TableActionType } from '@/services/models/shared/TableOptions';
import { InputColumnType, TableColumnType, TextColumnType, ListColumnFormat } from '@/services/models/shared/TableColumn';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppTablePagination from '@/components/shared/AppTablePagination.vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppSelect from '@/components/shared/AppSelect.vue';
import { CommonIcons } from '@/services/models/shared/CommonIcons';
import { ButtonColor } from '@/services/models/shared/bootstrap/ButtonColor';
import uuid from 'uuid';
// TODO: Change so prop with data for table is reactive
// #region Types
var TextFormat;
(function (TextFormat) {
    TextFormat[TextFormat["date"] = 0] = "date";
    TextFormat[TextFormat["currency"] = 1] = "currency";
    TextFormat[TextFormat["yesNo"] = 2] = "yesNo";
})(TextFormat || (TextFormat = {}));
var ListFormat;
(function (ListFormat) {
    ListFormat["none"] = "none";
    ListFormat["number"] = "decimal";
    ListFormat["bullet"] = "disc";
})(ListFormat || (ListFormat = {}));
// #endregion
export default defineComponent({
    emits: ['valueUpdate', 'updatePagination', 'deleteRow', 'editRow', 'editRowEnabled', 'editRowSubmit', 'customEvent', 'tableMounted', 'tableUpdated'],
    components: {
        AppCollapsingCardWithButtons,
        BSButton,
        AppSelect,
        AppInput,
        AppTablePagination
    },
    props: {
        options: {
            required: false,
            type: Object,
            default: {
                title: null,
                style: {
                    backgroundColor: null,
                    headerBackgroundColor: null,
                    hover: false,
                    striped: false,
                    bordered: false,
                    borderless: false,
                    borderStyle: null
                },
                small: false,
                responsive: true,
                pagination: false,
                paginationOptions: null,
                squashMobile: true,
                mobileOptions: {
                    titleFormat: null,
                    style: {
                        backgroundColor: null,
                        headerBackgroundColor: null,
                        borderStyle: null
                    },
                    collapsable: false,
                    spaceRecords: false
                },
                actions: null,
                customActionButtons: null
            }
        },
        columns: {
            required: true,
            type: Object
        },
        initialData: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            completed: false,
            tableId: '',
            isLoading: true,
            cells: [],
            rows: [],
            internalColumns: new Map(),
            table: {
                title: false,
                isForm: false,
                squashMobile: false,
                configuration: {
                    tableWrapper: {
                        attributes: {
                            class: null
                        }
                    },
                    table: {
                        attributes: {
                            class: null
                        }
                    },
                    tableHeader: {
                        attributes: {
                            class: null
                        }
                    }
                }
            },
            events: []
        };
    },
    methods: {
        createTableColumn(c) {
            return {
                attributes: {
                    class: this.$emptyValueUtilities.emptyStringToNull(c.alignment.headerTextAlignment),
                    scoped: 'col'
                },
                label: this.$emptyValueUtilities.emptyStringToNull(c.label),
                configuration: {
                    actionButtons: undefined,
                    label: {
                        hideWhenSquashed: c.hideLabelInMobile,
                        attributes: {
                            class: c.type === TableColumnType.input && c.config.required ? 'required' : null
                        }
                    }
                }
            };
        },
        createTableColumnAdd() {
            return {
                attributes: {
                    class: null,
                    scoped: null
                },
                label: null,
                configuration: {
                    actionButtons: ((this.options.actions !== null && this.options.actions.length > 0) || (this.options.customActionButtons !== null)) ? {
                        add: this.options.actions !== null && this.options.actions.length > 0 && this.options.actions.includes(TableActionType.add) ? {
                            onClick: () => { const rowId = this.createBlankRecord(); this.$emit('valueUpdate', { data: this.rows.map(x => x.data), tableRowId: rowId }); },
                            attributes: {
                                class: 'btn-sm'
                            },
                            backgroundColor: ButtonColor.success,
                            icon: CommonIcons.add
                        } : undefined
                    } : undefined,
                    label: undefined
                }
            };
        },
        createTableCell(columnId, c, currentIndex) {
            const columnLastIndex = this.columns.length - (this.options.actions !== null && this.options.actions.length > 0 ? 0 : 1);
            return {
                id: uuid.v4(),
                key: this.$emptyValueUtilities.emptyStringToNull(c.key),
                columnId: columnId,
                attributes: {
                    class: this.cellAlignment(c)
                },
                configuration: {
                    routeButton: c.type === TableColumnType.route ? {
                        backgroundColor: c.config.buttonColor
                    } : undefined,
                    textDisplay: c.type === TableColumnType.text ? {
                        format: c.config.textColumnType === TextColumnType.date ? TextFormat.date : c.config.textColumnType === TextColumnType.currency ? TextFormat.currency : c.config.textColumnType === TextColumnType.yesNo ? TextFormat.yesNo : null
                    } : undefined,
                    listDisplay: c.type === TableColumnType.list ? {
                        format: c.config.format === ListColumnFormat.none ? ListFormat.none : c.config.format === ListColumnFormat.number ? ListFormat.number : c.config.format === ListColumnFormat.bullet ? ListFormat.bullet : null
                    } : undefined,
                    formField: c.type === TableColumnType.input ? {
                        toggleEdit: this.options.actions !== null && this.options.actions.length > 0 && this.options.actions.includes(TableActionType.editToggle),
                        required: c.config.required,
                        input: c.config.inputType === InputColumnType.text ? {
                            mask: c.config.input.maskType,
                            maxLength: c.config.input.maxLength,
                            inputType: c.config.input.textInputType
                        } : undefined,
                        select: c.config.inputType === InputColumnType.dropdown ? {
                            options: c.config.input.options
                        } : undefined
                    } : undefined,
                    cardCell: {
                        attributes: currentIndex !== columnLastIndex || this.table.isForm ? {
                            style: 'border-bottom: 1px solid grey'
                        } : undefined
                    }
                }
            };
        },
        createTableRow(x, index) {
            return {
                id: uuid.v4(),
                inEdit: false,
                data: x,
                configuration: {
                    attributes: this.options.pagination && this.options.paginationOptions !== null && this.options.paginationOptions.localPagination ? {
                        class: this.options.pagination && this.table.configuration.pagination.page === Math.ceil((index + 1) / this.table.configuration.pagination.pageSize) ? null : 'd-none'
                    } : undefined,
                    actionButtons: this.options.actions !== null && this.options.actions.length > 0 ? {
                        edit: this.options.actions !== null && this.options.actions.length > 0 && (this.options.actions.includes(TableActionType.editExternal) || this.options.actions.includes(TableActionType.editToggle)) ? {
                            onClick: (rowId) => {
                                var row = this.rows.find((x) => {
                                    return x.id === rowId;
                                });
                                if (row) {
                                    if (row.configuration.actionButtons && row.configuration.actionButtons.edit) {
                                        if (row.configuration.actionButtons.edit.toggle) {
                                            row.inEdit = !row.inEdit;
                                            row.configuration.actionButtons.edit.icon = row.inEdit ? CommonIcons.done : CommonIcons.edit;
                                            row.configuration.actionButtons.edit.backgroundColor = row.inEdit ? ButtonColor.success : ButtonColor.warning;
                                            if (!row.inEdit) {
                                                this.$emit('editRowSubmit', { data: row.data, tableRowId: row.id });
                                            }
                                            else {
                                                this.$emit('editRowEnabled', { data: row.data, tableRowId: row.id });
                                            }
                                        }
                                        else if (row.configuration.actionButtons.edit.external) {
                                            this.$emit('editRow', { data: row.data, tableRowId: row.id });
                                        }
                                        else {
                                            this.$emit('valueUpdate', { data: this.rows.map(x => x.data), tableRowId: row.id });
                                        }
                                    }
                                }
                            },
                            toggle: this.options.actions !== null && this.options.actions.length > 0 ? this.options.actions.includes(TableActionType.editToggle) : false,
                            external: this.options.actions !== null && this.options.actions.length > 0 ? this.options.actions.includes(TableActionType.editExternal) : false,
                            attributes: {
                                class: 'btn-sm'
                            },
                            backgroundColor: ButtonColor.warning,
                            icon: CommonIcons.edit
                        } : undefined,
                        delete: this.options.actions !== null && this.options.actions.length > 0 && (this.options.actions.includes(TableActionType.delete) || this.options.actions.includes(TableActionType.deleteExternal)) ? {
                            onClick: (rowId) => {
                                var row = this.rows.find((x) => {
                                    return x.id === rowId;
                                });
                                if (row) {
                                    if (row.configuration.actionButtons && row.configuration.actionButtons.delete && row.configuration.actionButtons.delete.external) {
                                        this.$emit('deleteRow', row.data);
                                    }
                                    else {
                                        var rowIndex = this.rows.findIndex((x) => {
                                            return x.id === rowId;
                                        });
                                        this.rows.splice(rowIndex, 1);
                                        var movedRow = null;
                                        if (this.rows.length > 0) {
                                            if (this.rows.length === 1) {
                                                movedRow = this.rows[0];
                                                if (movedRow.configuration.actionButtons && movedRow.configuration.actionButtons.moveUp && movedRow.configuration.actionButtons.moveDown) {
                                                    movedRow.configuration.actionButtons.moveUp.attributes.class = 'btn-sm d-none';
                                                    movedRow.configuration.actionButtons.moveDown.attributes.class = 'btn-sm d-none';
                                                }
                                            }
                                            else if (rowIndex === 0) {
                                                movedRow = this.rows[rowIndex];
                                                if (movedRow.configuration.actionButtons && movedRow.configuration.actionButtons.moveUp && movedRow.configuration.actionButtons.moveDown) {
                                                    movedRow.configuration.actionButtons.moveUp.attributes.class = 'btn-sm d-none';
                                                    movedRow.configuration.actionButtons.moveDown.attributes.class = 'btn-sm';
                                                }
                                            }
                                            else if (rowIndex - 1 === this.rows.length - 1) {
                                                movedRow = this.rows[rowIndex - 1];
                                                if (movedRow.configuration.actionButtons && movedRow.configuration.actionButtons.moveUp && movedRow.configuration.actionButtons.moveDown) {
                                                    movedRow.configuration.actionButtons.moveUp.attributes.class = 'btn-sm';
                                                    movedRow.configuration.actionButtons.moveDown.attributes.class = 'btn-sm d-none';
                                                }
                                            }
                                        }
                                        this.$emit('valueUpdate', { data: this.rows.map(x => x.data), tableRowId: row.id });
                                    }
                                }
                            },
                            external: this.options.actions !== null && this.options.actions.length > 0 ? this.options.actions.includes(TableActionType.deleteExternal) : false,
                            attributes: {
                                class: 'btn-sm'
                            },
                            backgroundColor: ButtonColor.danger,
                            icon: CommonIcons.delete
                        } : undefined,
                        moveUp: this.options.actions !== null && this.options.actions.length > 0 && this.options.actions.includes(TableActionType.move) ? {
                            onClick: (rowId) => {
                                var fromRowIndex = this.rows.findIndex((x) => {
                                    return x.id === rowId;
                                });
                                if (fromRowIndex > 0) {
                                    var fromRow = this.rows[fromRowIndex];
                                    var toRowIndex = fromRowIndex - 1;
                                    this.rows.splice(fromRowIndex, 1);
                                    this.rows.splice(toRowIndex, 0, fromRow);
                                    if (fromRow.configuration.actionButtons && fromRow.configuration.actionButtons.moveUp && fromRow.configuration.actionButtons.moveDown) {
                                        if (fromRowIndex === 0) {
                                            fromRow.configuration.actionButtons.moveUp.attributes.class = 'btn-sm d-none';
                                        }
                                        else {
                                            fromRow.configuration.actionButtons.moveUp.attributes.class = 'btn-sm';
                                        }
                                        if (fromRowIndex === this.rows.length - 1) {
                                            fromRow.configuration.actionButtons.moveDown.attributes.class = 'btn-sm d-none';
                                        }
                                        else {
                                            fromRow.configuration.actionButtons.moveDown.attributes.class = 'btn-sm';
                                        }
                                    }
                                    var toRow = this.rows[toRowIndex];
                                    if (toRow.configuration.actionButtons && toRow.configuration.actionButtons.moveUp && toRow.configuration.actionButtons.moveDown) {
                                        if (toRowIndex === 0) {
                                            toRow.configuration.actionButtons.moveUp.attributes.class = 'btn-sm d-none';
                                        }
                                        else {
                                            toRow.configuration.actionButtons.moveUp.attributes.class = 'btn-sm';
                                        }
                                        if (toRowIndex === this.rows.length - 1) {
                                            toRow.configuration.actionButtons.moveDown.attributes.class = 'btn-sm d-none';
                                        }
                                        else {
                                            toRow.configuration.actionButtons.moveDown.attributes.class = 'btn-sm';
                                        }
                                    }
                                    this.$emit('valueUpdate', { data: this.rows.map(x => x.data), tableRowId: rowId });
                                }
                            },
                            attributes: {
                                class: 'btn-sm' + (index === 0 ? ' d-none' : '')
                            },
                            backgroundColor: ButtonColor.primary,
                            icon: CommonIcons.up
                        } : undefined,
                        moveDown: this.options.actions !== null && this.options.actions.length > 0 && this.options.actions.includes(TableActionType.move) ? {
                            onClick: (rowId) => {
                                var fromRowIndex = this.rows.findIndex((x) => {
                                    return x.id === rowId;
                                });
                                if (fromRowIndex < (this.rows.length - 1)) {
                                    var row = this.rows[fromRowIndex];
                                    var toRowIndex = fromRowIndex + 1;
                                    this.rows.splice(fromRowIndex, 1);
                                    this.rows.splice(toRowIndex, 0, row);
                                    if (row.configuration.actionButtons && row.configuration.actionButtons.moveUp && row.configuration.actionButtons.moveDown) {
                                        if (fromRowIndex === 0) {
                                            row.configuration.actionButtons.moveUp.attributes.class = 'btn-sm d-none';
                                        }
                                        else {
                                            row.configuration.actionButtons.moveUp.attributes.class = 'btn-sm';
                                        }
                                        if (fromRowIndex === this.rows.length - 1) {
                                            row.configuration.actionButtons.moveDown.attributes.class = 'btn-sm d-none';
                                        }
                                        else {
                                            row.configuration.actionButtons.moveDown.attributes.class = 'btn-sm';
                                        }
                                    }
                                    var toRow = this.rows[toRowIndex];
                                    if (toRow.configuration.actionButtons && toRow.configuration.actionButtons.moveUp && toRow.configuration.actionButtons.moveDown) {
                                        if (toRowIndex === 0) {
                                            toRow.configuration.actionButtons.moveUp.attributes.class = 'btn-sm d-none';
                                        }
                                        else {
                                            toRow.configuration.actionButtons.moveUp.attributes.class = 'btn-sm';
                                        }
                                        if (toRowIndex === this.rows.length - 1) {
                                            toRow.configuration.actionButtons.moveDown.attributes.class = 'btn-sm d-none';
                                        }
                                        else {
                                            toRow.configuration.actionButtons.moveDown.attributes.class = 'btn-sm';
                                        }
                                    }
                                    this.$emit('valueUpdate', { data: this.rows.map(x => x.data), tableRowId: rowId });
                                }
                            },
                            attributes: {
                                class: 'btn-sm' + (index === this.initialData.length - 1 ? ' d-none' : '')
                            },
                            backgroundColor: ButtonColor.primary,
                            icon: CommonIcons.down
                        } : undefined
                    } : undefined,
                    customActionButtons: this.options.customActionButtons && this.options.customActionButtons !== null && this.options.customActionButtons.length > 0 ? this.options.customActionButtons.map((a) => ({
                        onClick: (rowId) => {
                            var row = this.rows.find((x) => {
                                return x.id === rowId;
                            });
                            if (row) {
                                this.$emit('customEvent', { event: a.event, value: row.data });
                            }
                        },
                        backgroundColor: a.buttonColor,
                        icon: a.icon,
                        text: a.text,
                        eventName: a.event,
                        attributes: {
                            class: 'btn-sm'
                        }
                    })) : undefined,
                    cardRow: {
                        spaceRows: this.options.mobileOptions !== null ? this.options.mobileOptions.spaceRecords : false,
                        headerTitle: this.options.mobileOptions !== null && this.options.mobileOptions.titleFormat !== null ? this.$emptyValueUtilities.emptyStringToNull(this.options.mobileOptions.titleFormat.replace(/{(\w+)}/g, function (_, k) {
                            return x[k];
                        })) : null,
                        collapsable: this.options.mobileOptions !== null ? this.options.mobileOptions.collapsable : false,
                        headerBackgroundColor: this.options.mobileOptions !== null ? this.options.mobileOptions.style.headerBackgroundColor : null,
                        backgroundColor: this.options.mobileOptions !== null ? this.options.mobileOptions.style.backgroundColor : null,
                        border: this.options.mobileOptions !== null ? this.options.mobileOptions.style.borderStyle : null
                    }
                }
            };
        },
        createBlankRecord() {
            var newRecord = {};
            this.columns.forEach((e) => {
                if (e.key !== null) {
                    switch (e.type) {
                        case TableColumnType.input:
                            var config = e.config;
                            switch (config.inputType) {
                                case InputColumnType.text:
                                    newRecord[e.key] = '';
                                    break;
                                case InputColumnType.dropdown:
                                    var changeConfig = config;
                                    newRecord[e.key] = changeConfig.input.options[0].value;
                                    break;
                            }
                            break;
                        case TableColumnType.text:
                            newRecord[e.key] = '';
                            break;
                    }
                }
            });
            var newRow = this.createTableRow(newRecord, this.rows.length);
            this.rows.push(newRow);
            return newRow.id;
        },
        valueUpdate(rowId) {
            this.$emit('valueUpdate', { data: this.rows.map(x => x.data), tableRowId: rowId });
        },
        cardHeaderTitle(row) {
            return this.options.mobileOptions !== null && this.options.mobileOptions.titleFormat !== null ? this.$emptyValueUtilities.emptyStringToNull(this.options.mobileOptions.titleFormat.replace(/{(\w+)}/g, function (_, k) {
                return row[k];
            })) : null;
        },
        clearNulls(val) {
            if (val !== null) {
                var filtered = val.filter((x) => {
                    return x !== null;
                });
                return filtered.length > 0 ? filtered : null;
            }
            return val;
        },
        cellAlignment(column) {
            var alignmentClasses = [];
            alignmentClasses.push(column.alignment.cellTextAlignment);
            alignmentClasses.push(column.alignment.cellVerticalAlignment);
            return this.clearNulls(alignmentClasses);
        },
        formatCellText(val, format) {
            if ((typeof val !== 'string' && typeof val !== 'number' && typeof val !== 'boolean' && val instanceof Date) || val === '' || val === null) {
                return val;
            }
            return format === TextFormat.date ? this.$stringUtilities.formattedDate(val) : format === TextFormat.currency ? this.$stringUtilities.formattedCurrency(val) : format === TextFormat.yesNo ? (val === 'true' || val ? 'Yes' : 'No') : val;
        },
        tableHeaderStyle() {
            return this.$emptyValueUtilities.emptyStringToNull(this.options.style.headerBackgroundColor);
        },
        tableStyle() {
            var styleClasses = [];
            styleClasses.push(this.options.style.backgroundColor);
            styleClasses.push(this.options.style.striped ? 'table-striped' : null);
            styleClasses.push(this.options.style.hover ? 'table-hover' : null);
            styleClasses.push(this.options.style.bordered ? 'table-bordered' : null);
            styleClasses.push(this.options.style.borderless ? 'table-borderless' : null);
            styleClasses.push(this.options.style.outerBordered ||
                (this.options.style.borderStyle !== null &&
                    (this.options.style.borderStyle.color !== null ||
                        this.options.style.borderStyle.width !== null)) ? 'border' : null);
            if (this.options.style.borderStyle !== null) {
                styleClasses.push(this.options.style.borderStyle.color);
                styleClasses.push(this.options.style.borderStyle.width);
            }
            styleClasses.push(this.options.small ? 'table-sm' : null);
            return this.clearNulls(styleClasses);
        }
    },
    beforeMount() {
        // Create Table
        this.tableId = uuid.v4();
        this.table.title = this.options.title ?? this.table.title;
        this.table.isForm = this.columns.findIndex((x) => {
            return x.type === TableColumnType.input;
        }) > -1 || (this.options.actions !== null && this.options.actions.length > 0 && !this.options.actions.includes(TableActionType.deleteExternal) && !this.options.actions.includes(TableActionType.editExternal));
        this.table.squashMobile = this.options.squashMobile ?? this.table.squashMobile;
        this.table.configuration.table.attributes.class = this.tableStyle();
        if (this.options.responsive) {
            this.table.configuration.tableWrapper.attributes.class = 'table-responsive';
        }
        this.table.configuration.tableHeader.attributes.class = this.tableHeaderStyle();
        if (this.options.pagination && this.options.paginationOptions !== null) {
            this.table.configuration.pagination = {
                colspan: (this.options.actions !== null && this.options.actions.length > 0) || (this.options.customActionButtons && this.options.customActionButtons !== null && this.options.customActionButtons.length > 0) ? this.columns.length + 1 : this.columns.length,
                page: this.options.pagination && this.options.paginationOptions.localPagination ? 1 : this.options.pagination && this.options.paginationOptions.page !== null ? this.options.paginationOptions.page : 1,
                pageSize: this.options.pagination && this.options.paginationOptions.localPagination ? 10 : this.options.pagination && this.options.paginationOptions.pageSize !== null ? this.options.paginationOptions.pageSize : 10,
                total: this.options.pagination && this.options.paginationOptions.localPagination ? this.initialData.length : this.options.pagination && this.options.paginationOptions.total !== null ? this.options.paginationOptions.total : this.initialData.length,
                updatePagination: (page, pageSize) => {
                    this.table.configuration.pagination.page = page;
                    this.table.configuration.pagination.pageSize = pageSize;
                    this.$emit('updatePagination', { page: page, pageSize: pageSize });
                }
            };
        }
        // Create Columns and Cells
        if (this.options.actions !== null && this.options.actions.length > 0) {
            this.internalColumns.set('addButton', this.createTableColumnAdd());
        }
        this.columns.forEach((c, index) => {
            const id = uuid.v4();
            this.internalColumns.set(id, this.createTableColumn(c));
            const currentIndex = index + (this.options.actions !== null && this.options.actions.length > 0 ? 1 : 0);
            this.cells.push(this.createTableCell(id, c, currentIndex));
        });
        // Create Rows
        this.initialData.forEach((x, index) => {
            this.rows.push(this.createTableRow(x, index));
        });
        this.completed = true;
    },
    mounted() {
        this.$emit('tableMounted');
    },
    updated() {
        this.$emit('tableUpdated');
    }
});
