import { defineComponent } from 'vue';
import Notifications from '@/hooks/Notifications';
import BSToast from '@/components/shared/bootstrap/Toast.vue';
export default defineComponent({
    inject: ['Notifications'],
    components: {
        BSToast
    },
    computed: {
        notifications() {
            return Notifications.state.notifications;
        }
    },
    methods: {
        destroyToast(index) {
            Notifications.state.notifications.splice(index, 1);
        }
    }
});
