import { defineComponent } from 'vue';
import { OrganizationType } from '@/services/models/shared/OrganizationType';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppTable from '@/components/shared/AppTable.vue';
import AppSelect from '@/components/shared/AppSelect.vue';
import AppMultipleCheckbox from '@/components/shared/AppMultipleCheckbox.vue';
export default defineComponent({
    inject: ['userCanEdit', 'userCanRead', 'userCanWrite', 'documentState', 'documentInformationNotEditable', 'userIsVendor', 'userIsAdministrator'],
    emits: ['informationFinishedLoading'],
    components: {
        AppCollapsingCardWithButtons,
        AppTable,
        AppSelect,
        AppInput,
        AppMultipleCheckbox
    },
    props: {
        documentId: {
            required: true,
            type: String
        },
        initialDocumentData: {
            required: true,
            type: Object
        },
        organizationType: {
            required: true,
            type: Number
        },
        documentStatusId: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            documentData: {},
            yesNoOptions: [{ value: true, item: 'Yes' }, { value: false, item: 'No' }],
            resetMobilityAid: false
        };
    },
    computed: {
        isTWC() {
            if (this.organizationType & OrganizationType.twc) {
                return true;
            }
            return false;
        }
    },
    methods: {
        updateMobilityAid(e) {
            this.documentData.customer.mobilityAid = e;
            if (this.documentData.customer.hasMobilityDevice && this.documentData.customer.mobilityAid.length === 0) {
                this.documentData.customer.mobilityAid.push({
                    type: 'Manual Wheelchair',
                    make: null,
                    model: null,
                    weight: null,
                    seatMeasurement: null,
                    sittingHeight: null,
                    chairDepth: null,
                    chairWidth: null,
                    eyeHeight: null,
                    floorToSeatHeight: null,
                    specialFeatures: null
                });
                this.resetMobilityAid = !this.resetMobilityAid;
            }
        },
        toggleMobilityAidList(e) {
            if (e) {
                if (this.documentData.customer.mobilityAid === null || this.documentData.customer.mobilityAid.length < 1) {
                    this.documentData.customer.mobilityAid.push({
                        type: 'Manual Wheelchair',
                        make: null,
                        model: null,
                        weight: null,
                        seatMeasurement: null,
                        sittingHeight: null,
                        chairDepth: null,
                        chairWidth: null,
                        eyeHeight: null,
                        floorToSeatHeight: null,
                        specialFeatures: null
                    });
                }
            }
            else {
                this.documentData.customer.mobilityAid = [];
            }
        },
        toggleVinNumberField(e) {
            if (!e) {
                this.documentData.vehicle.vinNumber = null;
            }
        },
        resetFieldsVehicleIsGettingSpecialConversion() {
            if (!this.documentData.vehicleIsGettingSpecialConversion) {
                this.documentData.vehicleModificationType = [];
            }
        }
    },
    created() {
        this.documentData = this.initialDocumentData;
    },
    mounted() {
        this.$emit('informationFinishedLoading');
    }
});
