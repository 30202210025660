import FormService from '@/services/FormService';
import { defineComponent } from 'vue';
import AppCarouselForm from '@/components/shared/AppCarouselForm.vue';
import AppInput from '@/components/shared/AppInput.vue';
import OrganizationsService from '@/services/OrganizationsService';
import { OrganizationsFilter } from '@/services/models/Organization';
import { UserAccountsFilter } from '@/services/models/UserAccount';
import { OrganizationType } from '@/services/models/shared/OrganizationType';
import UsersService from '@/services/UsersService';
import AppSelect from '@/components/shared/AppSelect.vue';
import { UserAccountStatus } from '@/services/models/shared/UserAccountStatus';
import BSAlert from '@/components/shared/bootstrap/Alert.vue';
export default defineComponent({
    emits: ['onCancel'],
    components: {
        AppCarouselForm,
        AppInput,
        AppSelect,
        BSAlert
    },
    props: {
        submitterName: {
            required: true,
            type: String
        },
        submitterOffice: {
            required: true,
            type: String
        },
        submitterPhoneNumber: {
            required: true,
            type: String
        },
        submitterFaxNumber: {
            required: true,
            type: String
        },
        submitterEmailAddress: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            organizationOptions: [],
            vendorAccountOptions: null,
            selectedOrganization: null,
            selectedVendorUserAccountId: null,
            repairReviewRequest: {
                formVersion: null,
                formVersionDate: null,
                caseId: null,
                sa: null,
                customer: {
                    firstName: null,
                    lastName: null,
                    weight: null,
                    driverOrPassenger: null,
                    hasMobilityDevice: false,
                    mobilityAid: [],
                    canAmbulate: null,
                    ambulatoryDistance: null,
                    manualDexterityType: null
                },
                representativeCounselor: {
                    name: null,
                    office: null,
                    phoneNumber: null,
                    faxNumber: null,
                    emailAddress: null
                },
                ats: {
                    name: null,
                    office: null,
                    phoneNumber: null,
                    faxNumber: null,
                    emailAddress: null
                },
                vendor: {
                    name: null,
                    addressLineOne: null,
                    addressLineTwo: null,
                    city: null,
                    state: null,
                    zipCode: null,
                    phoneNumber: null,
                    faxNumber: null,
                    contactName: null,
                    contactEmailAddress: null
                },
                vehicle: {
                    type: null,
                    year: null,
                    make: null,
                    model: null,
                    mileage: null,
                    vinNumber: null
                },
                vehicleIsGettingSpecialConversion: null,
                vehicleModificationType: [],
                attachments: {
                    ttiReviewSA: null,
                    vendorEstimate: null
                },
                proposedVehicleGawr: null,
                concernsOfCombinedWeightExceedingGawr: null
            },
            submissionSuccessful: null,
            returnedMessage: null,
            yesNoOptions: [{ value: true, item: 'Yes' }, { value: false, item: 'No' }]
        };
    },
    computed: {
        formSections() {
            return ([{ name: 'contactInformationSlide', title: 'Contact Information' },
                { name: 'customerInformationSlide', title: 'Customer Information' },
                (this.$store.getters.hasDelegateUser && this.$store.getters.delegateUser.isTWC) || this.$store.getters.user.isTWC ? { name: 'atsInformationSlide', title: 'ATS Information' } : null,
                { name: 'representativeInformationSlide', title: (this.$store.getters.hasDelegateUser && this.$store.getters.delegateUser.isTWC) || this.$store.getters.user.isTWC ? 'TWC Counselor Information' : 'ILC Representative' }]).filter((s) => { return s !== null; });
        }
    },
    methods: {
        onSubmit() {
            var formData = new FormData(document.forms[0]);
            FormService.submitRepairReviewRequestForm(formData)
                .then(() => {
                this.submissionSuccessful = true;
            })
                .catch(error => {
                this.returnedMessage = error.response.data;
                this.submissionSuccessful = false;
            });
        },
        loadVendorOrganizations() {
            const filter = new OrganizationsFilter(true);
            filter.onlyHasUsers = true;
            filter.organizationType = OrganizationType.vendorActive;
            OrganizationsService.getOrganizations(filter.options())
                .then(response => {
                if (!response.data) {
                    this.organizationOptions = [];
                    return;
                }
                this.organizationOptions = response.data.map((e) => ({ item: e.name, value: e.id }));
            });
        },
        loadVendors() {
            this.selectedVendorUserAccountId = null;
            const filter = new UserAccountsFilter(true);
            filter.organizationId = this.selectedOrganization;
            filter.userAccountStatus = UserAccountStatus.activeAuthorized;
            UsersService.getUserAccounts(filter.options())
                .then(response => {
                if (!response.data) {
                    this.vendorAccountOptions = [];
                    return;
                }
                this.vendorAccountOptions = response.data.map((e) => ({ item: e.fullName, value: e.id }));
            });
        }
    },
    created() {
        this.loadVendorOrganizations();
    },
    updated() {
        if ((this.$store.getters.hasDelegateUser && this.$store.getters.delegateUser.isTWC) || this.$store.getters.user.isTWC) {
            this.repairReviewRequest.ats.name = this.submitterName;
            this.repairReviewRequest.ats.office = this.submitterOffice;
            this.repairReviewRequest.ats.phoneNumber = this.submitterPhoneNumber;
            this.repairReviewRequest.ats.faxNumber = this.submitterFaxNumber;
            this.repairReviewRequest.ats.emailAddress = this.submitterEmailAddress;
        }
        else {
            this.repairReviewRequest.representativeCounselor.name = this.submitterName;
            this.repairReviewRequest.representativeCounselor.office = this.submitterOffice;
            this.repairReviewRequest.representativeCounselor.phoneNumber = this.submitterPhoneNumber;
            this.repairReviewRequest.representativeCounselor.faxNumber = this.submitterFaxNumber;
            this.repairReviewRequest.representativeCounselor.emailAddress = this.submitterEmailAddress;
        }
    }
});
