import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        isNavItem: {
            required: true,
            type: Boolean
        }
    },
    computed: {
        hasRoles() {
            return this.$store.getters.userIsAuthorized && this.$store.getters.user.roles.length > 1;
        },
        currentRole() {
            return this.$store.getters.currentRoleId;
        },
        userRoles() {
            return this.$store.getters.user?.roles;
        },
        currentRoleName() {
            return this.$store.getters.user?.roles.find((x) => {
                return x.id === this.currentRole;
            })?.name ?? '';
        }
    },
    methods: {
        onClick(id) {
            if (this.currentRole !== id) {
                this.$store.dispatch('changeRole', id);
            }
        }
    }
});
