import { defineComponent, ref } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import { Popover } from 'bootstrap';
import { OrganizationType } from '@/services/models/shared/OrganizationType';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
import BSAlert from '@/components/shared/bootstrap/Alert.vue';
import AppTable from '@/components/shared/AppTable.vue';
import AppRadioButtons from '@/components/shared/AppRadioButtons.vue';
import AppSelect from '@/components/shared/AppSelect.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppTextArea from '@/components/shared/AppTextArea.vue';
import { CaseItemsFilter } from '@/services/models/CaseItem';
import { AcceptedItemsFilter } from '@/services/models/AcceptedItem';
import CasesItemsService from '@/services/CasesItemsService';
import DocumentService from '@/services/DocumentService';
import AcceptedItemsService from '@/services/AcceptedItemsService';
import ManufacturersService from '@/services/ManufacturersService';
import Notifications from '@/hooks/Notifications';
import { CaseItemPartyApproval } from '@/services/models/shared/CaseItemPartyApproval';
import { DocumentStatus } from '@/services/models/shared/DocumentStatus';
import { DocumentType } from '@/services/models/shared/DocumentType';
import { DocumentState } from '@/services/models/shared/DocumentState';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import { CaseItemHistoryAction } from '@/services/models/shared/CaseItemHistoryAction';
export default defineComponent({
    emits: ['reloadCustomerAttachments', 'generateAndSave3408Form', 'allCaseItemsAccepted'],
    inject: ['userCanEdit', 'userCanRead', 'userCanWrite', 'userIsEmployee', 'userIsAdministrator', 'userIsCustomer', 'documentState', 'documentInformationNotEditable'],
    components: {
        AppTable,
        BSButton,
        BSModal,
        AppRadioButtons,
        AppSelect,
        AppInput,
        AppTextArea,
        BSAlert,
        AppCollapsingCardWithButtons
    },
    setup() {
        const approvedTable = ref();
        const unacceptedTable = ref();
        const unapprovedTable = ref();
        const allTable = ref();
        const rejectedByCustomerTable = ref();
        const rejectedByProductAdminTable = ref();
        const rejectedByVendorTable = ref();
        const productAdminUnapprovedTable = ref();
        const productAdminApprovedTable = ref();
        const editCaseItemModal = ref();
        const addCaseItemNoteModal = ref();
        const confirmRemoveCaseItemModal = ref();
        return { approvedTable, unacceptedTable, unapprovedTable, productAdminUnapprovedTable, productAdminApprovedTable, editCaseItemModal, addCaseItemNoteModal, confirmRemoveCaseItemModal, allTable, rejectedByCustomerTable, rejectedByProductAdminTable, rejectedByVendorTable };
    },
    props: {
        documentId: {
            required: true,
            type: String
        },
        documentStatusId: {
            required: true,
            type: String
        },
        documentTypeId: {
            required: true,
            type: String
        },
        formVersion: {
            required: true,
            type: String
        },
        caseId: {
            required: true,
            type: String
        },
        requestDataCompleted: {
            required: true,
            type: Boolean
        },
        organizationType: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            submitButton: {
                text: 'Save',
                show: true
            },
            cancelButton: {
                text: 'Cancel',
                show: true
            },
            yesButton: {
                text: 'Yes',
                show: true
            },
            noButton: {
                text: 'No',
                show: true
            },
            filter: new CaseItemsFilter(true),
            searchByManufacturer: true,
            keywordSearch: null,
            caseItems: [],
            addedCaseItem: null,
            acceptedItemManufacturerIdSelected: 'all',
            acceptedItemSelectedId: null,
            acceptedItemManufacturerSelection: [],
            acceptedItemsFiltered: [],
            acceptedItemOriginalCostNull: false,
            updatedCaseItem: null,
            deletedCaseItem: null,
            originalCost: null,
            addModalKey: false,
            activeTab: 'caseItemsTab',
            submitDisabled: false,
            addedCaseItemNote: null,
            currentNoteParty: this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId) ? 1 : this.$stringUtilities.compareIds(DocumentStatus.Pending, this.documentStatusId) ? 3 : this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId) ? 2 : 0
        };
    },
    computed: {
        isTWC() {
            if (this.organizationType & OrganizationType.twc) {
                return true;
            }
            return false;
        },
        acceptedItemFilteredSelection() {
            return this.acceptedItemsFiltered.map((x) => ({
                item: (x.itemNumber !== null && x.itemNumber !== '' ? x.itemNumber + ', ' : '') + x.itemDescription,
                value: x.id
            }));
        },
        numberItemsNeedApprovalByCurrent() {
            if (this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)) {
                return this.caseItems.filter((c) => {
                    return c.itemPartyApproval === CaseItemPartyApproval.none || !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.vendor);
                }).length;
            }
            else if (this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)) {
                return this.caseItems.filter((c) => {
                    return c.itemPartyApproval === CaseItemPartyApproval.none || !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdmin);
                }).length;
            }
            else if (this.$stringUtilities.compareIds(DocumentStatus.Pending, this.documentStatusId)) {
                return this.caseItems.filter((c) => {
                    return c.itemPartyApproval === CaseItemPartyApproval.none || !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.customer);
                }).length;
            }
            return 0;
        },
        numberItemsNeedReviewByNext() {
            if (this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)) {
                return this.caseItems.filter((c) => {
                    return c.itemPartyApproval !== CaseItemPartyApproval.none && !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdmin);
                }).length;
            }
            else if (this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)) {
                return this.caseItems.filter((c) => {
                    return c.itemPartyApproval !== CaseItemPartyApproval.none && !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.vendor);
                }).length;
            }
            return 0;
        },
        rejectedByCustomerCaseItems() {
            if (this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)) {
                if (!this.$stringUtilities.compareIds(DocumentType.repair, this.documentTypeId)) {
                    return this.caseItems.filter((c) => {
                        return this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdminVendor) &&
                            !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.customer) &&
                            c.history.length > 0 &&
                            (c.history[0].action === CaseItemHistoryAction.rejectedAutomatically || c.history[0].action === CaseItemHistoryAction.rejectedManually);
                    }).map((c) => {
                        return {
                            id: c.id,
                            caseId: c.caseId,
                            copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                            itemManufacturer: c.itemManufacturer,
                            itemNumber: c.itemNumber,
                            itemDescription: c.itemDescription,
                            serialNumbers: c.serialNumbers,
                            quantity: c.quantity,
                            cost: c.cost,
                            itemPartyApproval: c.itemPartyApproval,
                            history: c.history,
                            notes: c.notes
                        };
                    });
                }
            }
            return [];
        },
        approvedCaseItems() {
            if (this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)) {
                if (!this.$stringUtilities.compareIds(DocumentType.repair, this.documentTypeId)) {
                    return this.caseItems.filter((c) => {
                        return this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdminVendor) &&
                            this.rejectedByCustomerCaseItems.findIndex((d) => {
                                return d.id === c.id;
                            }) < 0;
                    }).map((c) => {
                        return {
                            id: c.id,
                            caseId: c.caseId,
                            copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                            itemManufacturer: c.itemManufacturer,
                            itemNumber: c.itemNumber,
                            itemDescription: c.itemDescription,
                            serialNumbers: c.serialNumbers,
                            quantity: c.quantity,
                            cost: c.cost,
                            itemPartyApproval: c.itemPartyApproval,
                            history: c.history,
                            notes: c.notes
                        };
                    });
                }
            }
            else if (this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)) {
                return this.caseItems.filter((c) => {
                    return this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdminVendor);
                }).map((c) => {
                    return {
                        id: c.id,
                        caseId: c.caseId,
                        copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                        itemManufacturer: c.itemManufacturer,
                        itemNumber: c.itemNumber,
                        itemDescription: c.itemDescription,
                        serialNumbers: c.serialNumbers,
                        quantity: c.quantity,
                        cost: c.cost,
                        itemPartyApproval: c.itemPartyApproval,
                        history: c.history,
                        notes: c.notes
                    };
                });
            }
            else if (this.$stringUtilities.compareIds(DocumentStatus.Pending, this.documentStatusId)) {
                return this.caseItems.filter((c) => {
                    return this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdminVendorCustomer);
                }).map((c) => {
                    return {
                        id: c.id,
                        caseId: c.caseId,
                        copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                        itemManufacturer: c.itemManufacturer,
                        itemNumber: c.itemNumber,
                        itemDescription: c.itemDescription,
                        serialNumbers: c.serialNumbers,
                        quantity: c.quantity,
                        cost: c.cost,
                        itemPartyApproval: c.itemPartyApproval,
                        history: c.history,
                        notes: c.notes
                    };
                });
            }
            return [];
        },
        rejectedByProductAdminCaseItems() {
            if (this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)) {
                if (!this.$stringUtilities.compareIds(DocumentType.repair, this.documentTypeId)) {
                    return this.caseItems.filter((c) => {
                        return this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.vendor) &&
                            !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdmin) &&
                            c.history.length > 0 &&
                            (c.history[0].action === CaseItemHistoryAction.rejectedAutomatically || c.history[0].action === CaseItemHistoryAction.rejectedManually) &&
                            c.itemPartyApproval !== CaseItemPartyApproval.none;
                    }).map((c) => {
                        return {
                            id: c.id,
                            caseId: c.caseId,
                            copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                            itemManufacturer: c.itemManufacturer,
                            itemNumber: c.itemNumber,
                            itemDescription: c.itemDescription,
                            serialNumbers: c.serialNumbers,
                            quantity: c.quantity,
                            cost: c.cost,
                            itemPartyApproval: c.itemPartyApproval,
                            history: c.history,
                            notes: c.notes
                        };
                    });
                }
            }
            return [];
        },
        unapprovedCaseItems() {
            if (this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)) {
                if (!this.$stringUtilities.compareIds(DocumentType.repair, this.documentTypeId)) {
                    return this.caseItems.filter((c) => {
                        return ((this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdmin) || this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.customer)) &&
                            !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.vendor)) ||
                            c.itemPartyApproval === CaseItemPartyApproval.none;
                    }).map((c) => {
                        return {
                            id: c.id,
                            caseId: c.caseId,
                            copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                            itemManufacturer: c.itemManufacturer,
                            itemNumber: c.itemNumber,
                            itemDescription: c.itemDescription,
                            serialNumbers: c.serialNumbers,
                            quantity: c.quantity,
                            cost: c.cost,
                            itemPartyApproval: c.itemPartyApproval,
                            history: c.history,
                            notes: c.notes
                        };
                    });
                }
            }
            else if (this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)) {
                return this.caseItems.filter((c) => {
                    return (!this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdmin) &&
                        this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.vendor)) ||
                        c.itemPartyApproval === CaseItemPartyApproval.none;
                }).map((c) => {
                    return {
                        id: c.id,
                        caseId: c.caseId,
                        copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                        itemManufacturer: c.itemManufacturer,
                        itemNumber: c.itemNumber,
                        itemDescription: c.itemDescription,
                        serialNumbers: c.serialNumbers,
                        quantity: c.quantity,
                        cost: c.cost,
                        itemPartyApproval: c.itemPartyApproval,
                        history: c.history,
                        notes: c.notes
                    };
                });
            }
            else if (this.$stringUtilities.compareIds(DocumentStatus.Pending, this.documentStatusId)) {
                return this.caseItems.filter((c) => {
                    return !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.customer);
                }).map((c) => {
                    return {
                        id: c.id,
                        caseId: c.caseId,
                        copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                        itemManufacturer: c.itemManufacturer,
                        itemNumber: c.itemNumber,
                        itemDescription: c.itemDescription,
                        serialNumbers: c.serialNumbers,
                        quantity: c.quantity,
                        cost: c.cost,
                        itemPartyApproval: c.itemPartyApproval,
                        history: c.history,
                        notes: c.notes
                    };
                });
            }
            return [];
        },
        rejectedByVendorCaseItems() {
            if (this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)) {
                return this.caseItems.filter((c) => {
                    return this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdmin) &&
                        !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.vendor) &&
                        c.history.length > 0 &&
                        (c.history[0].action === CaseItemHistoryAction.rejectedAutomatically || c.history[0].action === CaseItemHistoryAction.rejectedManually);
                }).map((c) => {
                    return {
                        id: c.id,
                        caseId: c.caseId,
                        copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                        itemManufacturer: c.itemManufacturer,
                        itemNumber: c.itemNumber,
                        itemDescription: c.itemDescription,
                        serialNumbers: c.serialNumbers,
                        quantity: c.quantity,
                        cost: c.cost,
                        itemPartyApproval: c.itemPartyApproval,
                        history: c.history,
                        notes: c.notes
                    };
                });
            }
            return [];
        },
        unacceptedCaseItems() {
            if (this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)) {
                if (!this.$stringUtilities.compareIds(DocumentType.repair, this.documentTypeId)) {
                    return this.caseItems.filter((c) => {
                        return !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdmin) &&
                            this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.vendor) &&
                            this.rejectedByProductAdminCaseItems.findIndex((d) => {
                                return d.id === c.id;
                            }) < 0;
                    }).map((c) => {
                        return {
                            id: c.id,
                            caseId: c.caseId,
                            copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                            itemManufacturer: c.itemManufacturer,
                            itemNumber: c.itemNumber,
                            itemDescription: c.itemDescription,
                            serialNumbers: c.serialNumbers,
                            quantity: c.quantity,
                            cost: c.cost,
                            itemPartyApproval: c.itemPartyApproval,
                            history: c.history,
                            notes: c.notes
                        };
                    });
                }
            }
            else if (this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)) {
                return this.caseItems.filter((c) => {
                    return !this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.vendor) &&
                        this.$bitwiseEnumUtilities.hasFlag(c.itemPartyApproval, CaseItemPartyApproval.productsAdmin) &&
                        this.rejectedByVendorCaseItems.findIndex((d) => {
                            return d.id === c.id;
                        }) < 0;
                }).map((c) => {
                    return {
                        id: c.id,
                        caseId: c.caseId,
                        copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                        itemManufacturer: c.itemManufacturer,
                        itemNumber: c.itemNumber,
                        itemDescription: c.itemDescription,
                        serialNumbers: c.serialNumbers,
                        quantity: c.quantity,
                        cost: c.cost,
                        itemPartyApproval: c.itemPartyApproval,
                        history: c.history,
                        notes: c.notes
                    };
                });
            }
            return [];
        }
    },
    methods: {
        loadCaseItems(reload) {
            this.caseItems = [];
            CasesItemsService.getCaseItemListForDocumentId(this.documentId, this.filter.options())
                .then(response => {
                this.caseItems = response.data.map((c) => {
                    return {
                        id: c.id,
                        caseId: c.caseId,
                        copiedFromAcceptedItem: c.copiedFromAcceptedItem,
                        itemManufacturer: c.itemManufacturer,
                        itemNumber: c.itemNumber,
                        itemDescription: c.itemDescription,
                        serialNumbers: c.serialNumbers,
                        quantity: c.quantity,
                        cost: c.cost,
                        itemPartyApproval: c.itemPartyApproval,
                        history: c.history,
                        notes: c.notes
                    };
                });
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            })
                .finally(() => {
                if (reload) {
                    if (((this.numberItemsNeedApprovalByCurrent === 0 && this.numberItemsNeedReviewByNext === 0) || (this.numberItemsNeedApprovalByCurrent === 1 && this.numberItemsNeedReviewByNext === 0)) && this.$stringUtilities.compareIds(DocumentStatus.Pending, this.documentStatusId)) {
                        this.$router.go(0);
                    }
                }
            });
        },
        loadAcceptedItems(itemManufacturerId, isKeywordSearch) {
            if (!itemManufacturerId) {
                this.acceptedItemsFiltered = [];
            }
            this.acceptedItemOriginalCostNull = false;
            const acceptedItemsFilter = new AcceptedItemsFilter(true);
            if (isKeywordSearch) {
                acceptedItemsFilter.keyword = this.keywordSearch;
                this.acceptedItemSelectedId = null;
                if (this.addedCaseItem !== null) {
                    this.addedCaseItem.itemManufacturer = null;
                    this.addedCaseItem.itemNumber = null;
                    this.addedCaseItem.itemDescription = '';
                    this.addedCaseItem.quantity = 1;
                    this.addedCaseItem.serialNumbers = null;
                    this.addedCaseItem.cost = 0.00;
                }
                if (this.keywordSearch === null || this.keywordSearch.trim() === '') {
                    this.acceptedItemsFiltered = [];
                    return;
                }
            }
            else {
                acceptedItemsFilter.itemManufacturerId = itemManufacturerId;
            }
            AcceptedItemsService.getAcceptedItemList(acceptedItemsFilter.options())
                .then(response => {
                if (response.data.length === 0) {
                    this.acceptedItemsFiltered = [];
                    return;
                }
                this.acceptedItemsFiltered = response.data;
                this.acceptedItemsFiltered.sort((a, b) => {
                    const itemA = a.itemNumber !== null ? a.itemNumber : '';
                    const itemB = b.itemNumber !== null ? b.itemNumber : '';
                    if (itemA < itemB) {
                        return -1;
                    }
                    if (itemA > itemB) {
                        return 1;
                    }
                    return 0;
                });
            })
                .catch(() => {
                this.acceptedItemsFiltered = [];
            });
        },
        loadAddModal() {
            this.addedCaseItem = {
                copiedFromAcceptedItem: true,
                copiedAcceptedItemId: null,
                itemManufacturer: null,
                itemNumber: null,
                itemDescription: '',
                quantity: 1,
                serialNumbers: null,
                cost: 0.00,
                itemPartyAdding: this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)
                    ? CaseItemPartyApproval.vendor
                    : (this.userIsEmployee && this.userCanEdit) || this.userIsAdministrator
                        ? CaseItemPartyApproval.tti
                        : CaseItemPartyApproval.none,
                addNote: null
            };
            if (this.addedCaseItem.copiedFromAcceptedItem && (this.searchByManufacturer || (this.userIsEmployee && this.userCanEdit))) {
                this.loadAcceptedItemManufacturers();
            }
        },
        loadAcceptedItemManufacturers() {
            ManufacturersService.getItemManufacturerList({
                page: 1,
                pageSize: 10,
                returnAll: true,
                name: null,
                onlyAssignedToAcceptedItems: true,
                onlyViewableOnAcceptedProductsReport: false
            })
                .then(response => {
                if (response.data) {
                    if (response.data.length === 0) {
                        this.acceptedItemManufacturerSelection = [];
                    }
                    else {
                        this.acceptedItemManufacturerSelection = response.data.map((e) => ({ item: e.name, value: e.id }));
                    }
                    this.acceptedItemManufacturerSelection.unshift({ item: '(None)', value: null });
                    this.setAcceptedItemManufacturerFromSelected('');
                }
            })
                .catch(() => {
                this.acceptedItemManufacturerSelection = [];
            });
        },
        editCaseItem(data) {
            this.updatedCaseItem = {
                id: data.id,
                documentId: this.documentId,
                copiedFromAcceptedItem: data.copiedFromAcceptedItem,
                itemManufacturer: data.itemManufacturer,
                itemNumber: data.itemNumber,
                itemDescription: data.itemDescription,
                quantity: data.quantity,
                serialNumbers: data.serialNumbers,
                cost: data.cost,
                itemPartyEditing: this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)
                    ? CaseItemPartyApproval.vendor
                    : this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)
                        ? CaseItemPartyApproval.productsAdmin
                        : (this.userIsEmployee && this.userCanEdit) || this.userIsAdministrator
                            ? CaseItemPartyApproval.tti
                            : CaseItemPartyApproval.none,
                changeNote: null
            };
            if (data.history !== null && data.history.length > 0) {
                this.originalCost = data.history[data.history.length - 1].cost;
            }
            if (this.editCaseItemModal) {
                this.editCaseItemModal.show();
            }
        },
        confirmRemoveCaseItem(data) {
            this.deletedCaseItem = data;
            if (this.confirmRemoveCaseItemModal) {
                this.confirmRemoveCaseItemModal.show();
            }
        },
        loadAddCaseItemNoteModal(id) {
            this.addedCaseItemNote = {
                caseItemId: id,
                note: null
            };
            if (this.addCaseItemNoteModal) {
                this.addCaseItemNoteModal.show();
            }
        },
        setCopiedFromAcceptedItem(copiedFromAcceptedItem) {
            this.keywordSearch = null;
            this.acceptedItemManufacturerIdSelected = 'all';
            this.acceptedItemSelectedId = null;
            this.acceptedItemManufacturerSelection = [];
            this.acceptedItemsFiltered = [];
            this.acceptedItemOriginalCostNull = false;
            if (this.addedCaseItem !== null) {
                this.addedCaseItem.copiedAcceptedItemId = null;
                this.addedCaseItem.itemManufacturer = null;
                this.addedCaseItem.itemNumber = null;
                this.addedCaseItem.itemDescription = '';
                this.addedCaseItem.quantity = 1;
                this.addedCaseItem.serialNumbers = null;
                this.addedCaseItem.cost = 0.00;
                this.addedCaseItem.itemPartyAdding = this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)
                    ? CaseItemPartyApproval.vendor
                    : (this.userIsEmployee && this.userCanEdit) || this.userIsAdministrator
                        ? CaseItemPartyApproval.tti
                        : CaseItemPartyApproval.none;
            }
            if (this.addedCaseItem !== null) {
                this.addedCaseItem.copiedFromAcceptedItem = copiedFromAcceptedItem;
                if (copiedFromAcceptedItem && this.searchByManufacturer) {
                    this.loadAcceptedItemManufacturers();
                }
            }
        },
        setAcceptedItemManufacturerFromSelected(id) {
            this.acceptedItemSelectedId = null;
            const acceptedItemManufacturerSelected = this.acceptedItemManufacturerSelection.find((x) => { return x.value === id; });
            if (this.addedCaseItem !== null) {
                this.addedCaseItem.itemManufacturer = (acceptedItemManufacturerSelected?.item ?? null) === '(None)' ? null : acceptedItemManufacturerSelected?.item ?? null;
                this.addedCaseItem.itemNumber = null;
                this.addedCaseItem.itemDescription = '';
                this.addedCaseItem.quantity = 1;
                this.addedCaseItem.serialNumbers = null;
                this.addedCaseItem.cost = 0.00;
            }
            this.loadAcceptedItems(id, false);
        },
        setAcceptedItemFromSelected(isKeywordSearch) {
            const selectedAcceptedItem = this.acceptedItemsFiltered.find((x) => { return x.id === this.acceptedItemSelectedId; });
            if (this.addedCaseItem !== null) {
                if (isKeywordSearch) {
                    this.addedCaseItem.itemManufacturer = selectedAcceptedItem?.itemManufacturer ?? '';
                }
                this.addedCaseItem.copiedAcceptedItemId = this.acceptedItemSelectedId;
                this.addedCaseItem.itemDescription = selectedAcceptedItem?.itemDescription ?? '';
                this.addedCaseItem.itemNumber = selectedAcceptedItem?.itemNumber ?? null;
                this.addedCaseItem.cost = selectedAcceptedItem?.acceptedPrice ?? null;
                this.acceptedItemOriginalCostNull = (selectedAcceptedItem?.acceptedPrice ?? null) === null;
            }
        },
        updatePagination(page, pageSize) {
            this.filter.update({ page: page, pageSize: pageSize });
            this.loadCaseItems(false);
        },
        clearAcceptedItemsList() {
            this.acceptedItemsFiltered = [];
            this.acceptedItemSelectedId = null;
            this.acceptedItemOriginalCostNull = false;
        },
        clearAddCaseItemModal() {
            this.searchByManufacturer = true;
            this.keywordSearch = null;
            this.acceptedItemManufacturerIdSelected = 'all';
            this.acceptedItemSelectedId = null;
            this.acceptedItemManufacturerSelection = [];
            this.acceptedItemsFiltered = [];
            this.addedCaseItem = null;
            this.acceptedItemOriginalCostNull = false;
        },
        expandDetails(id) {
            var row = document.getElementById('details-' + id);
            var rowMobile = document.getElementById('details-mobile-' + id);
            if (row) {
                if (row.classList.contains('d-none')) {
                    row.classList.remove('d-none');
                }
                else {
                    row.classList.add('d-none');
                }
            }
            if (rowMobile) {
                if (rowMobile.classList.contains('d-none')) {
                    rowMobile.classList.remove('d-none');
                }
                else {
                    rowMobile.classList.add('d-none');
                }
            }
        },
        updateCaseItem() {
            var form = document.forms.namedItem('updateCaseItemForm');
            if (form !== null && this.updatedCaseItem !== null) {
                this.$loadingBackdrop.data.isVisible = true;
                var formData = new FormData(form);
                CasesItemsService.updateCaseItem(this.updatedCaseItem.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.updatePagination(1, 10);
                    if (this.formVersion !== '1.0' && ((this.userIsEmployee && this.userCanEdit) || this.userIsAdministrator)) {
                        this.$emit('generateAndSave3408Form');
                    }
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        addCaseItemNote() {
            var form = document.forms.namedItem('addCaseItemNoteForm');
            if (form !== null && this.addedCaseItemNote !== null) {
                this.$loadingBackdrop.data.isVisible = true;
                var formData = new FormData(form);
                CasesItemsService.addCaseItemNote(this.addedCaseItemNote.caseItemId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.updatePagination(1, 10);
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        addCaseItem() {
            var form = document.forms.namedItem('addCaseItemForm');
            if (form !== null) {
                this.$loadingBackdrop.data.isVisible = true;
                var formData = new FormData(form);
                CasesItemsService.addCaseItem(this.caseId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.updatePagination(1, 10);
                    if (this.formVersion !== '1.0' && ((this.userIsEmployee && this.userCanEdit) || this.userIsAdministrator)) {
                        this.$emit('generateAndSave3408Form');
                    }
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        removeCaseItem() {
            var formData = new FormData();
            formData.append('documentId', this.documentId);
            formData.append('itemPartyDeleted', this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)
                ? CaseItemPartyApproval.vendor.toString()
                : (this.userIsEmployee && this.userCanEdit) || this.userIsAdministrator
                    ? CaseItemPartyApproval.tti.toString()
                    : CaseItemPartyApproval.none.toString());
            formData.append('submittedByUserId', this.$store.getters.user.id);
            if (this.deletedCaseItem !== null) {
                this.$loadingBackdrop.data.isVisible = true;
                CasesItemsService.removeCaseItem(this.deletedCaseItem?.id, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.updatePagination(1, 10);
                    if (this.formVersion !== '1.0' && ((this.userIsEmployee && this.userCanEdit) || this.userIsAdministrator)) {
                        this.$emit('generateAndSave3408Form');
                    }
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.deletedCaseItem = null;
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        highlightRows() {
            if (this.unapprovedTable) {
                var table = document.getElementById(this.unapprovedTable.$data.tableId);
                if (table) {
                    var rows = table.querySelectorAll('tbody tr[id*="row-"]');
                    if (rows) {
                        var index = 0;
                        for (const row of rows) {
                            this.highlightRow(index, row.id, this.unapprovedCaseItems);
                            if (row.id) {
                                index++;
                            }
                        }
                    }
                }
            }
            if (this.approvedTable) {
                table = document.getElementById(this.approvedTable.$data.tableId);
                if (table) {
                    rows = table.querySelectorAll('tbody tr[id*="row-"]');
                    if (rows) {
                        index = 0;
                        for (const row of rows) {
                            this.highlightRow(index, row.id, this.approvedCaseItems);
                            if (row.id) {
                                index++;
                            }
                        }
                    }
                }
            }
            if (this.unacceptedTable) {
                table = document.getElementById(this.unacceptedTable.$data.tableId);
                if (table) {
                    rows = table.querySelectorAll('tbody tr[id*="row-"]');
                    if (rows) {
                        index = 0;
                        for (const row of rows) {
                            this.highlightRow(index, row.id, this.unacceptedCaseItems);
                            if (row.id) {
                                index++;
                            }
                        }
                    }
                }
            }
            if (this.rejectedByCustomerTable) {
                table = document.getElementById(this.rejectedByCustomerTable.$data.tableId);
                if (table) {
                    rows = table.querySelectorAll('tbody tr[id*="row-"]');
                    if (rows) {
                        index = 0;
                        for (const row of rows) {
                            this.highlightRow(index, row.id, this.rejectedByCustomerCaseItems);
                            if (row.id) {
                                index++;
                            }
                        }
                    }
                }
            }
            if (this.rejectedByProductAdminTable) {
                table = document.getElementById(this.rejectedByProductAdminTable.$data.tableId);
                if (table) {
                    rows = table.querySelectorAll('tbody tr[id*="row-"]');
                    if (rows) {
                        index = 0;
                        for (const row of rows) {
                            this.highlightRow(index, row.id, this.rejectedByProductAdminCaseItems);
                            if (row.id) {
                                index++;
                            }
                        }
                    }
                }
            }
            if (this.rejectedByVendorTable) {
                table = document.getElementById(this.rejectedByVendorTable.$data.tableId);
                if (table) {
                    rows = table.querySelectorAll('tbody tr[id*="row-"]');
                    if (rows) {
                        index = 0;
                        for (const row of rows) {
                            this.highlightRow(index, row.id, this.rejectedByVendorCaseItems);
                            if (row.id) {
                                index++;
                            }
                        }
                    }
                }
            }
            if (this.$stringUtilities.compareIds(DocumentType.repair, this.documentTypeId)) {
                if (this.allTable) {
                    table = document.getElementById(this.allTable.$data.tableId);
                    if (table) {
                        rows = table.querySelectorAll('tbody tr[id*="row-"]');
                        if (rows) {
                            index = 0;
                            for (const row of rows) {
                                this.highlightRow(index, row.id, this.caseItems);
                                if (row.id) {
                                    index++;
                                }
                            }
                        }
                    }
                }
            }
        },
        highlightRow(rowIndex, tableRowId, data) {
            if (!tableRowId) {
                return;
            }
            var row = document.querySelectorAll('[id="' + tableRowId + '"]');
            var editButtons = document.querySelectorAll('button[id*="edit-' + tableRowId.replace('row-', '') + '"]');
            var approveButtons = document.querySelectorAll('button[id*="approveCaseItem-' + tableRowId.replace('row-', '') + '"]');
            var dataRow = data[rowIndex];
            if (row && dataRow && dataRow.history && dataRow.history.length > 0) {
                var initialCost = dataRow.history[dataRow.history.length - 1].cost ?? null;
                row.forEach((r) => {
                    if (r.tagName === 'TR') {
                        var sibling = r.nextElementSibling;
                        if (dataRow.copiedFromAcceptedItem && initialCost === null) {
                            if (!r.classList.contains('table-primary')) {
                                r.classList.add('table-primary');
                                if (sibling && sibling != null) {
                                    sibling.classList.add('table-primary');
                                }
                            }
                        }
                        else if (dataRow.copiedFromAcceptedItem && initialCost !== dataRow.cost) {
                            if (!r.classList.contains('table-warning')) {
                                r.classList.add('table-warning');
                                if (sibling && sibling != null) {
                                    sibling.classList.add('table-warning');
                                }
                            }
                        }
                        else if (!dataRow.copiedFromAcceptedItem) {
                            if (!r.classList.contains('table-danger')) {
                                r.classList.add('table-danger');
                                if (sibling && sibling != null) {
                                    sibling.classList.add('table-danger');
                                }
                            }
                        }
                    }
                    else {
                        if (dataRow.copiedFromAcceptedItem && initialCost === null) {
                            if (!r.classList.contains('bg-light-blue')) {
                                r.classList.add('bg-light-blue');
                            }
                        }
                        else if (dataRow.copiedFromAcceptedItem && initialCost !== dataRow.cost) {
                            if (!r.classList.contains('bg-light-yellow')) {
                                r.classList.add('bg-light-yellow');
                            }
                        }
                        else if (!dataRow.copiedFromAcceptedItem) {
                            if (!r.classList.contains('bg-light-red')) {
                                r.classList.add('bg-light-red');
                            }
                        }
                    }
                    if (this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)) {
                        if (dataRow.copiedFromAcceptedItem && initialCost !== null && initialCost === dataRow.cost) {
                            editButtons.forEach((b) => {
                                b.setAttribute('disabled', '');
                            });
                        }
                        if (dataRow.cost === null) {
                            approveButtons.forEach((b) => {
                                b.setAttribute('disabled', '');
                            });
                        }
                    }
                });
            }
        },
        approveCaseItem(id) {
            this.$loadingBackdrop.data.isVisible = true;
            var formData = new FormData();
            formData.append('itemPartyApproval', this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)
                ? CaseItemPartyApproval.vendor.toString()
                : this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)
                    ? CaseItemPartyApproval.productsAdmin.toString()
                    : this.$stringUtilities.compareIds(DocumentStatus.Pending, this.documentStatusId)
                        ? CaseItemPartyApproval.customer.toString()
                        : (this.userIsEmployee && this.userCanEdit) || this.userIsAdministrator
                            ? CaseItemPartyApproval.tti.toString()
                            : CaseItemPartyApproval.none.toString());
            formData.append('documentId', this.documentId.toString());
            formData.append('markingAsApproved', 'true');
            formData.append('submittedByUserId', this.$store.getters.user.id.toString());
            CasesItemsService.updateCaseItemApproval(id, formData)
                .then(response => {
                Notifications.methods.addToast(ToastType.success, response);
                this.loadCaseItems(true);
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            })
                .finally(() => {
                this.$loadingBackdrop.data.isVisible = false;
            });
        },
        removeCaseItemApproval(id) {
            this.$loadingBackdrop.data.isVisible = true;
            var formData = new FormData();
            formData.append('itemPartyApproval', this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId)
                ? CaseItemPartyApproval.vendor.toString()
                : this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)
                    ? CaseItemPartyApproval.productsAdmin.toString()
                    : this.$stringUtilities.compareIds(DocumentStatus.Pending, this.documentStatusId)
                        ? CaseItemPartyApproval.customer.toString()
                        : (this.userIsEmployee && this.userCanEdit) || this.userIsAdministrator
                            ? CaseItemPartyApproval.tti.toString()
                            : CaseItemPartyApproval.none.toString());
            formData.append('documentId', this.documentId.toString());
            formData.append('markingAsApproved', 'false');
            formData.append('submittedByUserId', this.$store.getters.user.id.toString());
            CasesItemsService.updateCaseItemApproval(id, formData)
                .then(response => {
                Notifications.methods.addToast(ToastType.success, response);
                this.loadCaseItems(true);
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            })
                .finally(() => {
                this.$loadingBackdrop.data.isVisible = false;
            });
        },
        submit3408() {
            this.$loadingBackdrop.data.isVisible = true;
            var form = document.forms.namedItem('3408CaseItemsForm');
            if (form !== null) {
                var formData = new FormData(form);
                DocumentService.submit3408(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                    this.$loadingBackdrop.data.isVisible = false;
                    this.$router.replace({ name: 'OutboxDocumentDetails', params: { humanReadableId: this.$route.params.humanReadableId.toString() } });
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        tabChange(tabId) {
            this.activeTab = tabId;
        },
        generateAndDownload3408Form() {
            this.$loadingBackdrop.data.isVisible = true;
            DocumentService.generate3408FormPdf(this.documentId)
                .then(response => {
                const data = window.URL.createObjectURL(response.blob);
                var anchor = document.createElement('a');
                anchor.download = response.name;
                anchor.href = data;
                anchor.click();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            })
                .finally(() => {
                this.$loadingBackdrop.data.isVisible = false;
            });
        },
        sign3408Form() {
            this.$loadingBackdrop.data.isVisible = true;
            var form = document.forms.namedItem('signed3408Form');
            if (form !== null) {
                var formData = new FormData(form);
                DocumentService.submit3408(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.data);
                    this.$router.push({ name: 'OutboxDocumentDetails', params: { humanReadableId: this.$route.params.humanReadableId } });
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                })
                    .finally(() => {
                    this.submitDisabled = false;
                    this.$loadingBackdrop.data.isVisible = false;
                });
            }
        },
        currentSignedFile() {
            var currentValue = document.getElementById('signedFile').value;
            if (currentValue !== null && currentValue !== '') {
                this.submitDisabled = false;
            }
            else {
                this.submitDisabled = true;
            }
        }
    },
    created() {
        this.loadCaseItems(false);
    },
    mounted() {
        if ((((this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId) ||
            this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)) && !this.userIsAdministrator) ||
            (this.documentState === DocumentState.Open &&
                this.$stringUtilities.compareIds(DocumentType.repair, this.documentTypeId))) &&
            this.userCanEdit && !this.documentInformationNotEditable && this.formVersion !== '1.0') {
            var popover = new Popover('#key-popover', {
                html: true,
                title: 'Key',
                content: '<ul class="no-bullets">' +
                    '<li><ul class="key-row"><li class="bg-light-yellow">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li><li>Cost Changed</li></ul></li>' +
                    '<li><ul class="key-row"><li class="bg-light-red">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li><li>Manually Input Item</li></ul></li>' +
                    '<li><ul class="key-row"><li class="bg-light-blue">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li><li>Contact PSART</li></ul></li>' +
                    '</ul>',
                placement: 'top',
                trigger: 'hover',
                offset: [60, 10]
            });
        }
    },
    unmounted() {
        if ((((this.$stringUtilities.compareIds(DocumentStatus.Draft, this.documentStatusId) ||
            this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId)) && !this.userIsAdministrator) ||
            (this.documentState === DocumentState.Open &&
                this.$stringUtilities.compareIds(DocumentType.repair, this.documentTypeId))) &&
            this.userCanEdit && !this.documentInformationNotEditable && this.formVersion !== '1.0') {
            const popoverButton = document.getElementById('key-popover');
            if (popoverButton) {
                var popover = Popover.getInstance(popoverButton);
                if (popover) {
                    popover.dispose();
                }
            }
        }
    },
    beforeUpdate() {
        if (this.numberItemsNeedApprovalByCurrent === 0 && this.numberItemsNeedReviewByNext === 0 && this.documentStatusId === DocumentStatus.Pending && this.userCanEdit && this.userIsCustomer) {
            this.$emit('allCaseItemsAccepted', true);
        }
        else {
            this.$emit('allCaseItemsAccepted', false);
        }
    }
});
