import AveHttpService from '@/services/AveHttpService';
class CasesService {
    /**
     *  @description Retrieves all documents related to a case id
     */
    getCaseDocuments(caseId, filter) {
        return AveHttpService.get(`api/v1/cases/${caseId}`, { params: filter }).then((response) => {
            return response.data;
        });
    }
}
export default new CasesService();
