export var ButtonColor;
(function (ButtonColor) {
    ButtonColor["primary"] = "btn-primary";
    ButtonColor["secondary"] = "btn-secondary";
    ButtonColor["success"] = "btn-success";
    ButtonColor["danger"] = "btn-danger";
    ButtonColor["warning"] = "btn-warning";
    ButtonColor["info"] = "btn-info";
    ButtonColor["light"] = "btn-light";
    ButtonColor["dark"] = "btn-dark";
    ButtonColor["link"] = "btn-link";
    ButtonColor["maroon"] = "btn-maroon";
    ButtonColor["darkslategray"] = "btn-darkslategray";
})(ButtonColor || (ButtonColor = {}));
