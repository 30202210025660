import { defineComponent, computed, ref } from 'vue';
import RequestInformationForm from '@/components/document/information/RequestInformationForm.vue';
import CaseDocuments from '@/components/document/information/CaseDocuments.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
import CaseItems from '@/components/document/information/CaseItems.vue';
import AppUploadDocumentAttachmentsWithNotes from '@/components/shared/AppUploadDocumentAttachmentsWithNotes.vue';
import { DocumentStatus } from '@/services/models/shared/DocumentStatus';
import DocumentService from '@/services/DocumentService';
import Notifications from '@/hooks/Notifications';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
var Tabs;
(function (Tabs) {
    Tabs[Tabs["requestInformation"] = 0] = "requestInformation";
    Tabs[Tabs["caseItems"] = 1] = "caseItems";
    Tabs[Tabs["changeRequestUpload"] = 2] = "changeRequestUpload";
    Tabs[Tabs["caseDocuments"] = 3] = "caseDocuments";
})(Tabs || (Tabs = {}));
export default defineComponent({
    emits: ['reloadCustomerAttachments'],
    inject: ['userIsEmployee', 'userIsAdministrator', 'userIsCustomer', 'userCanRead', 'userCanEdit', 'userCanWrite', 'formVersion', 'documentState'],
    components: {
        BSButton,
        AppUploadDocumentAttachmentsWithNotes,
        RequestInformationForm,
        CaseItems,
        CaseDocuments,
        BSModal
    },
    setup() {
        const send3408ChangeNotificationModal = ref();
        return { send3408ChangeNotificationModal };
    },
    provide() {
        return {
            documentInformationNotEditable: computed(() => this.documentInformationNotEditable)
        };
    },
    props: {
        documentId: {
            required: true,
            type: String
        },
        caseId: {
            required: true,
            type: String
        },
        documentTypeId: {
            required: true,
            type: String
        },
        documentStatusId: {
            required: true,
            type: String
        },
        organizationType: {
            required: true,
            type: Number
        }
    },
    computed: {
        documentInformationNotEditable() {
            return this.$stringUtilities.compareIds(DocumentStatus.Assigning, this.documentStatusId) ||
                this.$stringUtilities.compareIds(DocumentStatus.Review, this.documentStatusId) ||
                this.$stringUtilities.compareIds(DocumentStatus.Approved, this.documentStatusId) ||
                this.$stringUtilities.compareIds(DocumentStatus.Billing, this.documentStatusId) ||
                this.$stringUtilities.compareIds(DocumentStatus.Bill, this.documentStatusId) ||
                this.$stringUtilities.compareIds(DocumentStatus.Billed, this.documentStatusId) ||
                this.$stringUtilities.compareIds(DocumentStatus.Escalated, this.documentStatusId);
        }
    },
    data() {
        return {
            activeTab: this.userIsCustomer && this.documentStatusId === DocumentStatus.Pending && this.userCanEdit ? Tabs.caseItems : Tabs.requestInformation,
            requestDataCompleted: false,
            allCaseItemsAccepted: false,
            yesButton: {
                text: 'Yes',
                show: true
            },
            noButton: {
                text: 'No',
                show: true
            }
        };
    },
    methods: {
        send3408ChangeNotification() {
            var form = document.forms.namedItem('send3408ChangeNotificationForm');
            if (form !== null) {
                var formData = new FormData(form);
                DocumentService.sendNotification(this.documentId, formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response);
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response);
                });
            }
        },
        generateAndSave3408Form() {
            this.$loadingBackdrop.data.isVisible = true;
            var formData = new FormData();
            formData.append('submittedByUserId', this.$store.getters.user.id);
            DocumentService.generateAndSave3408FormPdf(this.documentId, formData)
                .then(response => {
                Notifications.methods.addToast(ToastType.success, response.data);
                this.$emit('reloadCustomerAttachments');
                if (this.send3408ChangeNotificationModal) {
                    this.send3408ChangeNotificationModal.show();
                }
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            })
                .finally(() => {
                this.$loadingBackdrop.data.isVisible = false;
            });
        }
    }
});
