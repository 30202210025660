import { defineComponent } from 'vue';
import { BackgroundColor } from '@/services/models/shared/bootstrap/BackgroundColor';
import { CommonIcons } from '@/services/models/shared/CommonIcons';
import { uuid } from 'vue-uuid';
export default defineComponent({
    props: {
        sectionId: {
            required: false,
            type: String
        },
        headerTitle: {
            required: false,
            type: String,
            default: null
        },
        collapsable: {
            required: true,
            type: Boolean
        },
        collapseDisabled: {
            required: false,
            type: Boolean,
            default: false
        },
        backgroundColor: {
            required: false,
            type: String,
            default: null
        },
        headerBackgroundColor: {
            required: false,
            type: String,
            default: null
        },
        borderStyle: {
            required: false,
            type: Object,
            default: null
        },
        keepHeaderIfEmpty: {
            required: false,
            type: Boolean,
            default: false
        },
        removeBodyPadding: {
            required: false,
            type: Boolean,
            default: false
        },
        expanded: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            card: {},
            uniqueId: ''
        };
    },
    methods: {
        updateCard() {
            var keepCollapseButton = {
                collapsed: !this.expanded,
                attributes: {},
                icon: null
            };
            if (this.card.configuration.cardHeader && this.card.configuration.cardHeader.headerText && this.card.configuration.cardHeader.headerText.collapseButton) {
                keepCollapseButton.collapsed = this.card.configuration.cardHeader.headerText.collapseButton.collapsed;
                keepCollapseButton.attributes = this.card.configuration.cardHeader.headerText.collapseButton.attributes;
                keepCollapseButton.icon = this.card.configuration.cardHeader.headerText.collapseButton.icon;
            }
            this.setCard();
            if (this.card.configuration.cardHeader && this.card.configuration.cardHeader.headerText && this.card.configuration.cardHeader.headerText.collapseButton) {
                this.card.configuration.cardHeader.headerText.collapseButton.collapsed = keepCollapseButton.collapsed;
                this.card.configuration.cardHeader.headerText.collapseButton.attributes = keepCollapseButton.attributes;
                this.card.configuration.cardHeader.headerText.collapseButton.icon = keepCollapseButton.icon;
            }
        },
        setCard() {
            this.card = {
                id: this.uniqueId,
                headerTitle: this.headerTitle,
                configuration: {
                    attributes: {
                        class: this.borderStyle !== null
                            ? this.$emptyValueUtilities.clearNullsFromArray([
                                this.backgroundColor,
                                this.borderStyle !== null && (this.borderStyle.color !== null || this.borderStyle.width !== null)
                                    ? 'border'
                                    : null,
                                this.borderStyle !== null
                                    ? this.borderStyle.color
                                    : null,
                                this.borderStyle !== null
                                    ? this.borderStyle.width
                                    : null,
                                this.$emptyValueUtilities.emptyStringToNull(this.backgroundColor) !== null
                                    ? (this.backgroundColor === BackgroundColor.light ||
                                        this.backgroundColor === BackgroundColor.info ||
                                        this.backgroundColor === BackgroundColor.warning
                                        ? 'text-white'
                                        : 'text-dark')
                                    : null
                            ])
                            : null
                    },
                    cardHeader: this.keepHeaderIfEmpty || (!this.keepHeaderIfEmpty && (this.collapsable || this.$emptyValueUtilities.emptyStringToNull(this.headerTitle) !== null || !!this.$slots['header-buttons'])) ? {
                        attributes: {
                            class: this.headerBackgroundColor !== null
                                ? [this.headerBackgroundColor,
                                    (this.headerBackgroundColor === BackgroundColor.light ||
                                        this.headerBackgroundColor === BackgroundColor.info ||
                                        this.headerBackgroundColor === BackgroundColor.warning) ? 'text-dark' : 'text-white']
                                : null
                        },
                        headerText: this.collapsable || this.$emptyValueUtilities.emptyStringToNull(this.headerTitle) !== null ? {
                            collapseButton: this.collapsable ? {
                                onClick: () => {
                                    var card = this.card;
                                    if (card.configuration.cardHeader && card.configuration.cardHeader.headerText && card.configuration.cardHeader.headerText.collapseButton) {
                                        card.configuration.cardHeader.headerText.collapseButton.collapsed = !card.configuration.cardHeader.headerText.collapseButton.collapsed;
                                        card.configuration.cardHeader.headerText.collapseButton.disabled = this.collapseDisabled;
                                        card.configuration.cardHeader.headerText.collapseButton.icon = card.configuration.cardHeader.headerText.collapseButton.collapsed ? CommonIcons.expand : CommonIcons.collapse;
                                    }
                                },
                                collapsed: !this.expanded,
                                disabled: this.collapseDisabled,
                                attributes: {
                                    class: !!this.$slots['header-buttons'] ? 'col-9' : 'col-12' + (this.collapseDisabled ? ' button-disabled' : ''),
                                    type: 'button',
                                    'data-bs-target': '#' + this.uniqueId,
                                    'data-bs-toggle': 'collapse',
                                    'aria-controls': this.uniqueId
                                },
                                icon: this.expanded ? CommonIcons.collapse : CommonIcons.expand
                            } : undefined,
                            text: !this.collapsable && this.$emptyValueUtilities.emptyStringToNull(this.headerTitle) !== null ? {
                                attributes: {
                                    class: !!this.$slots['header-buttons'] ? 'col-8' : 'col-12'
                                }
                            } : undefined
                        } : undefined,
                        headerButtons: !!this.$slots['header-buttons'] ? {
                            attributes: {
                                class: this.collapsable ? 'col-3' : this.$emptyValueUtilities.emptyStringToNull(this.headerTitle) !== null ? 'col-4' : 'col-12'
                            }
                        } : undefined
                    } : undefined,
                    cardBody: {
                        attributes: this.collapsable ? {
                            class: this.expanded ? ['collapse', 'show'] : ['collapse'],
                            id: this.uniqueId
                        } : undefined
                    },
                    cardFooter: !!this.$slots['footer-content'] ? {
                        attributes: this.collapsable ? {
                            class: this.expanded ? ['collapse', 'show'] : ['collapse'],
                            id: this.uniqueId
                        } : undefined
                    } : undefined
                }
            };
        }
    },
    created() {
        this.uniqueId = this.sectionId ?? 'section-' + uuid.v4();
    },
    beforeMount() {
        this.setCard();
    },
    beforeUpdate() {
        this.updateCard();
    }
});
