import { defineComponent, ref } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
import AppTable from '@/components/shared/AppTable.vue';
import { OrganizationsFilter } from '@/services/models/Organization';
import OrganizationsService from '@/services/OrganizationsService';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
import AppSelect from '@/components/shared/AppSelect.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppCheckbox from '@/components/shared/AppCheckbox.vue';
import { OrganizationType, ExternalOrganizationTypeOption } from '@/services/models/shared/OrganizationType';
import BSAlert from '@/components/shared/bootstrap/Alert.vue';
export default defineComponent({
    name: 'Organizations',
    components: {
        AppTable,
        BSButton,
        AppSelect,
        AppInput,
        BSModal,
        AppCheckbox,
        BSAlert
    },
    setup() {
        const editOrganizationModal = ref();
        return { editOrganizationModal };
    },
    data() {
        return {
            organizations: [],
            filter: new OrganizationsFilter(false),
            isLoading: true,
            addModalKey: false,
            submitButton: {
                text: 'Save',
                show: true
            },
            cancelButton: {
                text: 'Cancel',
                show: true
            },
            updatedOrganization: null
        };
    },
    computed: {
        organizationTypeOptions() {
            return this.$enumUtilities.enumToSelectOptions(ExternalOrganizationTypeOption);
        }
    },
    methods: {
        loadOrganizations() {
            this.isLoading = true;
            OrganizationsService.getOrganizations(this.filter.options())
                .then(response => {
                if (!this.filter.update({ totalItems: response.totalCount })) {
                    this.loadOrganizations();
                    return;
                }
                this.organizations = response.data.map((o) => ({
                    id: o.id,
                    name: o.name,
                    abbreviation: o.abbreviation,
                    organizationType: this.$bitwiseEnumUtilities.getKeyFromValueHasFlag(ExternalOrganizationTypeOption, o.organizationType),
                    primaryContactFullName: o.primaryContactFullName,
                    primaryContactEmailAddress: o.primaryContactEmailAddress,
                    primaryContactDepartment: o.primaryContactDepartment,
                    primaryContactTitle: o.primaryContactTitle,
                    address: (o.addressLineOne !== null ? o.addressLineOne + '\n' : '') + (o.addressLineTwo !== null ? o.addressLineTwo + '\n' : '') + (o.addressLineThree !== null ? o.addressLineThree + '\n' : '') + (o.city !== null ? o.city + ', ' : '') + (o.state !== null ? o.state + '  ' : '') + (o.zipCode !== null ? o.zipCode : ''),
                    phoneNumber: o.phoneNumber,
                    faxNumber: o.faxNumber,
                    isActive: this.$bitwiseEnumUtilities.hasFlag(o.organizationType, OrganizationType.active) ? 'Yes' : 'No'
                }));
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        editOrganization(id) {
            OrganizationsService.getOrganization(id)
                .then(response => {
                this.updatedOrganization = {
                    id: id,
                    name: response.name,
                    abbreviation: response.abbreviation,
                    organizationType: this.$bitwiseEnumUtilities.getValueFromValueHasFlag(ExternalOrganizationTypeOption, response.organizationType),
                    primaryContactFullName: response.primaryContactFullName,
                    primaryContactEmailAddress: response.primaryContactEmailAddress,
                    primaryContactDepartment: response.primaryContactDepartment,
                    primaryContactTitle: response.primaryContactTitle,
                    addressLineOne: response.addressLineOne,
                    addressLineTwo: response.addressLineTwo,
                    addressLineThree: response.addressLineThree,
                    city: response.city,
                    state: response.state,
                    zipCode: response.zipCode,
                    phoneNumber: response.phoneNumber,
                    faxNumber: response.faxNumber,
                    isActive: this.$bitwiseEnumUtilities.hasFlag(response.organizationType, OrganizationType.active)
                };
                if (this.editOrganizationModal) {
                    this.editOrganizationModal.show();
                }
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            });
        },
        updatePagination(page, pageSize) {
            this.filter.update({ page: page, pageSize: pageSize });
            this.loadOrganizations();
        },
        addOrganization() {
            var form = document.forms.namedItem('addOrganizationForm');
            if (form !== null) {
                var formData = new FormData(form);
                OrganizationsService.addOrganization(formData)
                    .then((response) => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.filter = new OrganizationsFilter(false);
                    this.loadOrganizations();
                })
                    .catch((error) => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        updateOrganization() {
            var form = document.forms.namedItem('updateOrganizationForm');
            if (form !== null && this.updatedOrganization !== null) {
                var formData = new FormData(form);
                OrganizationsService.updateOrganization(this.updatedOrganization.id, formData)
                    .then((response) => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.filter = new OrganizationsFilter(false);
                    this.loadOrganizations();
                })
                    .catch((error) => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        }
    },
    created() {
        this.loadOrganizations();
    }
});
