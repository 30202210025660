import { defineComponent } from 'vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
export default defineComponent({
    name: 'Accounts',
    components: {
        BSButton
    },
    computed: {
        childRoutes() {
            const accountRoute = this.$router.getRoutes().find((r) => {
                return r.name === 'Accounts';
            });
            if (accountRoute) {
                return accountRoute.children;
            }
            return [];
        }
    }
});
