import { defineComponent } from 'vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import AppInput from '@/components/shared/AppInput.vue';
export default defineComponent({
    inject: ['userCanEdit', 'documentInformationNotEditable'],
    components: {
        AppCollapsingCardWithButtons,
        AppInput
    },
    props: {
        initialDocumentData: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            documentData: {},
            isLoading: true
        };
    },
    mounted() {
        this.documentData = this.initialDocumentData;
        this.isLoading = false;
    }
});
