import { defineComponent, ref } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
import AppTable from '@/components/shared/AppTable.vue';
import { GroupsFilter } from '@/services/models/Group';
import { DocumentTypesFilter } from '@/services/models/DocumentTypeStatus';
import { DocumentTypeStatusDraftOpenPermissionOptions, DocumentTypeStatusClosedIgnoredPermissionOptions } from '@/services/models/shared/DocumentTypeStatusPermission';
import GroupsService from '@/services/GroupsService';
import DocumentTypeStatusesService from '@/services/DocumentTypeStatusesService';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
import AppCheckbox from '@/components/shared/AppCheckbox.vue';
import AppMultipleCheckbox from '@/components/shared/AppMultipleCheckbox.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import BSAlert from '@/components/shared/bootstrap/Alert.vue';
export default defineComponent({
    name: 'Groups',
    components: {
        AppTable,
        BSButton,
        AppInput,
        BSModal,
        AppCheckbox,
        AppMultipleCheckbox,
        BSAlert,
        AppCollapsingCardWithButtons
    },
    setup() {
        const editGroupModal = ref();
        return { editGroupModal };
    },
    data() {
        return {
            groups: [],
            documentTypeStatusOptions: [],
            filter: new GroupsFilter(false),
            isLoading: true,
            addModalKey: false,
            submitButton: {
                text: 'Save',
                show: true
            },
            cancelButton: {
                text: 'Cancel',
                show: true
            },
            selectedDocumentTypeStatusPermissions: [],
            updatedGroup: null
        };
    },
    computed: {
        documentTypeStatusDraftOpenPermissionOptions() {
            return this.$enumUtilities.enumToSelectOptions(DocumentTypeStatusDraftOpenPermissionOptions);
        },
        documentTypeStatusClosedIgnoredPermissionOptions() {
            return this.$enumUtilities.enumToSelectOptions(DocumentTypeStatusClosedIgnoredPermissionOptions);
        }
    },
    methods: {
        loadData() {
            // Get list of groups
            this.isLoading = true;
            GroupsService.getGroups(this.filter.options())
                .then(response => {
                if (!this.filter.update({ totalItems: response.totalCount })) {
                    this.loadData();
                    return;
                }
                this.groups = response.data.map((x) => {
                    return {
                        id: x.id,
                        name: x.name,
                        isExternalUserGroup: x.isExternalUserGroup ? 'Yes' : 'No',
                        documentTypeStatusPermissions: x.documentTypeStatusPermissions
                    };
                });
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        loadDocumentTypeStatuses() {
            // Get list of existing document type statuses
            DocumentTypeStatusesService.getDocumentTypes((new DocumentTypesFilter(true)).options())
                .then(response => {
                this.documentTypeStatusOptions = response.data.map((t) => {
                    return {
                        id: t.id,
                        name: t.name,
                        documentStatuses: t.documentStatuses.map((s) => {
                            if (this.updatedGroup &&
                                this.updatedGroup.documentTypeStatusPermissions &&
                                this.updatedGroup.documentTypeStatusPermissions !== null &&
                                this.updatedGroup.documentTypeStatusPermissions.findIndex((x) => { return x.documentTypeId === t.id; }) > -1) {
                                const documentTypeStatusPermissionsIndex = this.updatedGroup.documentTypeStatusPermissions.findIndex((x) => { return x.documentTypeId === t.id; });
                                const documentStatusPermissions = this.updatedGroup.documentTypeStatusPermissions[documentTypeStatusPermissionsIndex].documentStatusPermissions;
                                if (documentStatusPermissions && documentStatusPermissions !== null) {
                                    const documentStatusPermission = documentStatusPermissions.find((y) => { return y.documentTypeStatusId === s.id; });
                                    if (documentStatusPermission && documentStatusPermission !== null) {
                                        return {
                                            name: s.documentStatusName,
                                            id: s.id,
                                            documentState: s.documentState,
                                            isSelected: true,
                                            permissions: documentStatusPermission.permissions
                                        };
                                    }
                                }
                            }
                            return {
                                name: s.documentStatusName,
                                id: s.id,
                                documentState: s.documentState,
                                isSelected: false,
                                permissions: []
                            };
                        })
                    };
                });
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            });
        },
        editGroup(id) {
            GroupsService.getGroup(id)
                .then(response => {
                this.updatedGroup = response;
                this.selectedDocumentTypeStatusPermissions = [];
                if (response.documentTypeStatusPermissions && response.documentTypeStatusPermissions !== null) {
                    response.documentTypeStatusPermissions.forEach((x) => {
                        const allPermissions = x.documentStatusPermissions.map((y) => {
                            let totalPermissions = 0;
                            y.permissions.forEach((p) => {
                                totalPermissions = totalPermissions + p;
                            });
                            return {
                                documentTypeStatusId: y.documentTypeStatusId,
                                permissions: totalPermissions
                            };
                        });
                        this.selectedDocumentTypeStatusPermissions = this.selectedDocumentTypeStatusPermissions.concat(allPermissions);
                    });
                }
                if (this.editGroupModal) {
                    this.editGroupModal.show();
                }
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            })
                .finally(() => {
                this.loadDocumentTypeStatuses();
            });
        },
        updatePagination(page, pageSize) {
            this.filter.update({ page: page, pageSize: pageSize });
            this.loadData();
        },
        expandDetails(id) {
            var row = document.getElementById('details-' + id);
            var rowMobile = document.getElementById('details-mobile-' + id);
            if (row) {
                if (row.classList.contains('d-none')) {
                    row.classList.remove('d-none');
                }
                else {
                    row.classList.add('d-none');
                }
            }
            if (rowMobile) {
                if (rowMobile.classList.contains('d-none')) {
                    rowMobile.classList.remove('d-none');
                }
                else {
                    rowMobile.classList.add('d-none');
                }
            }
        },
        selectDocumentTypeStatus(documentTypeStatusId, isSelected) {
            var indexOfValue = this.selectedDocumentTypeStatusPermissions?.findIndex((x) => { return x.documentTypeStatusId === documentTypeStatusId; });
            if (indexOfValue > -1 && !isSelected) {
                this.selectedDocumentTypeStatusPermissions.splice(indexOfValue, 1);
            }
            else if (indexOfValue === -1 && isSelected) {
                this.selectedDocumentTypeStatusPermissions.push({
                    documentTypeStatusId: documentTypeStatusId,
                    permissions: 0
                });
            }
        },
        permissionsChanged(documentTypeStatusId, permissions) {
            var indexOfValue = this.selectedDocumentTypeStatusPermissions?.findIndex((x) => { return x.documentTypeStatusId === documentTypeStatusId; });
            if (indexOfValue > -1) {
                this.selectedDocumentTypeStatusPermissions[indexOfValue].permissions = permissions.reduce((partialSum, a) => partialSum + a, 0);
            }
        },
        addGroup() {
            var form = document.forms.namedItem('addGroupForm');
            if (form !== null) {
                var formData = new FormData(form);
                GroupsService.addGroup(formData)
                    .then((response) => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadData();
                })
                    .catch((error) => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        updateGroup() {
            var form = document.forms.namedItem('updateGroupForm');
            if (form !== null && this.updatedGroup !== null) {
                var formData = new FormData(form);
                GroupsService.updateGroup(this.updatedGroup.id, formData)
                    .then((response) => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadData();
                })
                    .catch((error) => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        }
    },
    created() {
        this.loadData();
    }
});
