export var ApplicationRole;
(function (ApplicationRole) {
    ApplicationRole["Administrator"] = "A4066968-6F39-4EC5-BFE0-F2DEEA202103";
    ApplicationRole["AVE Administrator"] = "ABBB1067-1958-4A8C-AA18-79431737B516";
    ApplicationRole["Employee"] = "BCB2A6CB-5105-4BC3-B6C0-F529A31D9E5D";
    ApplicationRole["Customer"] = "A21048DD-10C9-4F69-B417-9FFC8212C6FA";
    ApplicationRole["Vendor"] = "59B009AC-4D5A-4ABB-80AE-09B192EF9776";
    ApplicationRole["PSART"] = "D2724791-7368-411C-9179-DDB4CEBCBC01";
})(ApplicationRole || (ApplicationRole = {}));
export var ExternalApplicationRole;
(function (ExternalApplicationRole) {
    ExternalApplicationRole["Customer"] = "A21048DD-10C9-4F69-B417-9FFC8212C6FA";
    ExternalApplicationRole["Vendor"] = "59B009AC-4D5A-4ABB-80AE-09B192EF9776";
    ExternalApplicationRole["PSART"] = "D2724791-7368-411C-9179-DDB4CEBCBC01";
})(ExternalApplicationRole || (ExternalApplicationRole = {}));
export var InternalApplicationRole;
(function (InternalApplicationRole) {
    InternalApplicationRole["AVE Administrator"] = "ABBB1067-1958-4A8C-AA18-79431737B516";
    InternalApplicationRole["Employee"] = "BCB2A6CB-5105-4BC3-B6C0-F529A31D9E5D";
})(InternalApplicationRole || (InternalApplicationRole = {}));
