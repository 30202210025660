import { PagingFilter } from '@/services/models/shared/PagingFilter';
export class AcceptedItemDetailsFilter extends PagingFilter {
    constructor(returnAll = false) {
        super(returnAll);
    }
}
export class AcceptedItemVehiclesFilter extends PagingFilter {
    constructor(returnAll = false) {
        super(returnAll);
    }
}
export class AcceptedItemDocumentsFilter extends PagingFilter {
    status;
    constructor(returnAll = false) {
        super(returnAll);
        this.status = null;
    }
    options() {
        return Object.assign({
            status: this.status
        }, super.options());
    }
}
export class AcceptedItemsFilter extends PagingFilter {
    itemDescription;
    itemNumber;
    itemManufacturerId;
    conversionType;
    sortForVendors;
    keyword;
    constructor(returnAll = false) {
        super(returnAll);
        this.itemDescription = null;
        this.itemNumber = null;
        this.itemManufacturerId = 'all';
        this.keyword = null;
    }
    options() {
        return Object.assign({
            itemDescription: this.itemDescription,
            itemNumber: this.itemNumber,
            itemManufacturerId: this.itemManufacturerId,
            conversionType: this.conversionType,
            sortForVendors: this.sortForVendors,
            keyword: this.keyword
        }, super.options());
    }
}
