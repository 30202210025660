import AveHttpService from '@/services/AveHttpService';
class VehiclesService {
    /**
     *  @description Retrieves vehicle with specified id
     */
    getVehicle(vehicleId) {
        return AveHttpService.get(`api/v1/vehicles/${vehicleId}`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all vehicles based on a filter
     */
    getVehicleList(filter) {
        return AveHttpService.get('api/v1/vehicles', { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Adds a vehicle
     */
    addVehicle(data) {
        return AveHttpService.post('api/v1/vehicles/add', data).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Updates vehicle data
     */
    updateVehicle(vehicleId, data) {
        return AveHttpService.patch(`api/v1/vehicles/${vehicleId}/update`, data).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Removes a vehicle
     */
    removeVehicle(vehicleId) {
        return AveHttpService.delete(`api/v1/vehicles/${vehicleId}/remove`).then((response) => {
            return response.data;
        });
    }
}
export default new VehiclesService();
