import { defineComponent } from 'vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppRichTextEditor from '@/components/shared/AppRichTextEditor.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppImageSelector from '@/components/shared/AppImageSelector.vue';
import DocumentService from '@/services/DocumentService';
import Notifications from '@/hooks/Notifications';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
export default defineComponent({
    name: 'VehicleInspectionReport',
    emits: ['disableSubmit', 'enableSubmit'],
    components: {
        BSButton,
        AppRichTextEditor,
        AppInput,
        AppImageSelector
    },
    props: {
        documentId: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            inspectionReport: null,
            inspectionReportExists: false,
            isUploadingSignedLetter: false,
            isLoading: true,
            inspectionSheetsExist: false
        };
    },
    methods: {
        loadData() {
            this.$emit('disableSubmit');
            this.isLoading = true;
            DocumentService.getInspectionReportData(this.documentId)
                .then(response => {
                if (response !== null) {
                    this.inspectionSheetsExist = true;
                    this.inspectionReport = {
                        letter: response.letter,
                        clientVehicleSummary: response.clientVehicleSummary,
                        summary: response.summary,
                        photos: response.photos,
                        issues: response.issues
                    };
                    this.inspectionReportExists = response.exists;
                    this.$emit('enableSubmit');
                }
            })
                .catch(() => {
                this.inspectionSheetsExist = false;
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        signInspectionReport() {
            this.isUploadingSignedLetter = !this.isUploadingSignedLetter;
            if (this.isUploadingSignedLetter) {
                this.$emit('disableSubmit');
            }
            else {
                var file = document.getElementById('signedFile');
                file.value = '';
                this.$emit('enableSubmit');
            }
        },
        currentSignedFile() {
            var currentValue = document.getElementById('signedFile').value;
            if (currentValue !== null && currentValue !== '') {
                this.$emit('enableSubmit');
            }
            else {
                this.$emit('disableSubmit');
            }
        },
        generateAndDownloadInspectionReport() {
            this.$loadingBackdrop.data.isVisible = true;
            DocumentService.generateInspectionReportPdf(this.documentId)
                .then(response => {
                const data = window.URL.createObjectURL(response.blob);
                var anchor = document.createElement('a');
                anchor.download = response.name;
                anchor.href = data;
                anchor.click();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            })
                .finally(() => {
                this.$loadingBackdrop.data.isVisible = false;
            });
        },
        addIssue() {
            if (this.inspectionReport !== null) {
                this.inspectionReport.issues.push({
                    description: '',
                    details: [{ detail: '', status: '' }]
                });
            }
        },
        addIssueDetail(issueIndex) {
            if (this.inspectionReport !== null) {
                this.inspectionReport.issues[issueIndex].details.push({
                    detail: '',
                    status: ''
                });
            }
        },
        removeIssue(issueIndex) {
            if (this.inspectionReport !== null) {
                this.inspectionReport.issues.splice(issueIndex, 1);
            }
        },
        removeIssueDetail(issueIndex, detailIndex) {
            if (this.inspectionReport !== null) {
                this.inspectionReport.issues[issueIndex].details.splice(detailIndex, 1);
            }
        }
    },
    mounted() {
        this.loadData();
    }
});
