import { defineComponent } from 'vue';
import AppCardRouterButton from '@/components/shared/AppCardRouterButton.vue';
import { AdministratorRoutes, EmployeeRoutes } from '@/router/index';
import { ApplicationRole } from '@/services/models/Role';
export default defineComponent({
    name: 'TaskOrderManagementSystemHome',
    components: {
        AppCardRouterButton
    },
    computed: {
        routes() {
            if (this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole.Administrator ||
                this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole['AVE Administrator']) {
                return AdministratorRoutes.filter((route) => {
                    return route.name !== 'Home';
                });
            }
            return EmployeeRoutes.filter((route) => {
                return route.name !== 'Home';
            });
        }
    }
});
