import { defineComponent } from 'vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import { ApplicationRole } from '@/services/models/Role';
export default defineComponent({
    components: {
        BSButton
    },
    computed: {
        viewIsReadonly() {
            console.log(this.$stringUtilities.compareIds(this.$store.getters.currentRoleId, ApplicationRole.Customer));
            return this.$stringUtilities.compareIds(this.$store.getters.currentRoleId, ApplicationRole.Vendor) || this.$stringUtilities.compareIds(this.$store.getters.currentRoleId, ApplicationRole.Customer);
        }
    }
});
