export var BorderColor;
(function (BorderColor) {
    BorderColor["primary"] = "border-primary";
    BorderColor["secondary"] = "border-secondary";
    BorderColor["success"] = "border-success";
    BorderColor["danger"] = "border-danger";
    BorderColor["warning"] = "border-warning";
    BorderColor["info"] = "border-info";
    BorderColor["light"] = "border-light";
    BorderColor["dark"] = "border-dark";
    BorderColor["white"] = "border-white";
})(BorderColor || (BorderColor = {}));
export var BorderWidth;
(function (BorderWidth) {
    BorderWidth["one"] = "border-1";
    BorderWidth["two"] = "border-2";
    BorderWidth["three"] = "border-3";
    BorderWidth["four"] = "border-4";
    BorderWidth["five"] = "border-5";
})(BorderWidth || (BorderWidth = {}));
