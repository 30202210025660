import { defineComponent } from 'vue';
import { InputType } from '@/services/models/shared/bootstrap/InputType';
import { uuid } from 'vue-uuid';
export default defineComponent({
    el: '.form-control',
    emits: ['update:modelValue'],
    props: {
        type: {
            required: false,
            type: String,
            default: InputType.text
        },
        modelValue: {
            required: false,
            default: null
        },
        label: {
            required: false,
            type: String,
            default: null
        },
        id: {
            required: false,
            type: String,
            default: null
        },
        name: {
            required: false,
            type: String,
            default: null
        },
        placeholder: {
            required: false,
            type: String,
            default: null
        },
        required: {
            required: false,
            type: Boolean,
            default: false
        },
        accept: {
            required: false,
            type: String,
            default: null
        },
        maxLength: {
            required: false,
            type: Number,
            default: null
        },
        minLength: {
            required: false,
            type: Number,
            default: null
        },
        min: {
            required: false,
            type: Number,
            default: null
        },
        max: {
            required: false,
            type: Number,
            default: null
        },
        readOnly: {
            required: false,
            type: Boolean,
            default: false
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        multiple: {
            required: false,
            type: Boolean,
            default: false
        },
        mask: {
            required: false,
            type: Number,
            default: null
        },
        validate: {
            required: false,
            type: Boolean,
            default: false
        },
        showInvalidMessage: {
            required: false,
            type: Boolean,
            default: false
        },
        indicateIfValid: {
            required: false,
            type: Boolean,
            default: false
        },
        alignmentReadOnly: {
            required: false,
            type: String,
            default: 'text-center'
        },
        returnBaseSixtyFourValue: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uniqueId: ''
        };
    },
    computed: {
        hasLabel() {
            return this.label !== null && this.label !== '';
        }
    },
    methods: {
        validation(e) {
            e.classList.remove('is-invalid');
            e.classList.remove('is-valid');
            if (this.validate) {
                this.$inputValidationUtilities.validateInput(e, this.indicateIfValid, this.showInvalidMessage, this.showInvalidMessage, true);
            }
        },
        convertFileToBase64(e) {
            if (!e.target.files || !e.target.files[0]) {
                return;
            }
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                this.$emit('update:modelValue', fileReader.result);
            };
            fileReader.readAsDataURL(e.target.files[0]);
        }
    },
    created() {
        this.uniqueId = this.id ?? uuid.v4();
    }
});
