import { defineComponent } from 'vue';
import AppInput from '@/components/shared/AppInput.vue';
import { uuid } from 'vue-uuid';
export default defineComponent({
    el: '.form-select',
    emits: ['update:modelValue'],
    components: {
        AppInput
    },
    props: {
        modelValue: {
            required: false,
            type: [String, Number, Boolean],
            default: null
        },
        label: {
            required: false,
            type: String,
            default: null
        },
        id: {
            required: false,
            type: String,
            default: null
        },
        name: {
            required: false,
            type: String,
            default: null
        },
        required: {
            required: false,
            type: Boolean,
            default: false
        },
        readOnly: {
            required: false,
            type: Boolean,
            default: false
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        options: {
            required: true,
            type: Object
        },
        placeholder: {
            required: false,
            type: Object,
            default: null
        },
        validate: {
            required: false,
            type: Boolean,
            default: false
        },
        showInvalidMessage: {
            required: false,
            type: Boolean,
            default: false
        },
        indicateIfValid: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: true,
            selectedIndex: 0,
            combinedOptions: [],
            uniqueId: '',
            showDisplay: ''
        };
    },
    computed: {
        hasLabel() {
            return this.label !== null && this.label !== '';
        },
        displayValue() {
            if (this.isLoading) {
                return '';
            }
            else {
                if (this.placeholder !== null) {
                    return (this.options.find((x) => {
                        return x.value === this.modelValue;
                    })?.item ?? this.placeholder.value === this.modelValue) ? this.placeholder.item : '';
                }
                else {
                    return this.options.find((x) => {
                        return x.value === this.modelValue;
                    })?.item ?? '';
                }
            }
        },
        value: {
            get() {
                return this.modelValue === null ? '' : this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value === 'true' ? true : value === 'false' ? false : value === 'null' ? null : value === '' ? null : value);
            }
        }
    },
    methods: {
        validation(e) {
            e.classList.remove('is-invalid');
            e.classList.remove('is-valid');
            if (this.validate) {
                this.$inputValidationUtilities.validateInput(e, this.indicateIfValid, this.showInvalidMessage, this.showInvalidMessage, true);
            }
        }
    },
    created() {
        this.uniqueId = this.id ?? uuid.v4();
        this.combinedOptions = this.options.slice();
        if (this.placeholder !== null) {
            this.combinedOptions.unshift(this.placeholder);
        }
    },
    mounted() {
        this.isLoading = false;
    }
});
