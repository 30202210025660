import { defineComponent } from 'vue';
export default defineComponent({
    name: 'AppLoadingBackdrop',
    data() {
        return {
            isVisible: false,
            message: ''
        };
    },
    computed: {
        classes() {
            return this.isVisible ? 'visible' : 'invisible';
        }
    }
});
