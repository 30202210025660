import { PagingFilter } from '@/services/models/shared/PagingFilter';
export class DocumentTypesFilter extends PagingFilter {
    documentStatusId;
    constructor(returnAll = false) {
        super(returnAll);
    }
    options() {
        return Object.assign({
            documentStatusId: this.documentStatusId
        }, super.options());
    }
}
