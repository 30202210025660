export var TableColor;
(function (TableColor) {
    TableColor["primary"] = "table-primary";
    TableColor["secondary"] = "table-secondary";
    TableColor["success"] = "table-success";
    TableColor["danger"] = "table-danger";
    TableColor["warning"] = "table-warning";
    TableColor["info"] = "table-info";
    TableColor["light"] = "table-light";
    TableColor["dark"] = "table-dark";
})(TableColor || (TableColor = {}));
