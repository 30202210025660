import moment from 'moment';
import store from '@/store';
import Notifications from '@/hooks/Notifications';
import LoadingContainer from '@/components/shared/LoadingContainer.vue';
export default {
    install: (app) => {
        const emptyValueUtilities = {
            emptyStringToNull: (val) => {
                if (val) {
                    return val.trim() === '' ? null : val;
                }
                return null;
            },
            clearNullsFromArray: (val) => {
                if (val !== null) {
                    const filtered = val.filter((x) => {
                        return x !== null && x !== '';
                    });
                    return filtered.length > 0 ? filtered : null;
                }
                return val;
            }
        };
        const stringUtilities = {
            compareIds: (src, target) => {
                if (target && src) {
                    return src.toLowerCase() === target.toLowerCase();
                }
                return false;
            },
            formattedDate: (val) => {
                if (typeof val === 'string') {
                    return moment(val).format('MM/DD/YYYY hh:mm a');
                }
                return moment(String(val)).format('MM/DD/YYYY hh:mm a');
            },
            formattedCurrency: (val) => {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                });
                if (val === null) {
                    return '$ -';
                }
                if (typeof val !== 'number') {
                    const valNumber = parseInt(val, 10);
                    return formatter.format(valNumber);
                }
                return formatter.format(val);
            }
        };
        const enumUtilities = {
            enumToSelectOptions: (enumType) => {
                return (Object.keys(enumType).filter(x => isNaN(parseInt(x)))).map((key) => {
                    const enumVal = enumType[key];
                    if (typeof enumVal === 'string') {
                        return {
                            value: enumVal,
                            item: key
                        };
                    }
                    else if (typeof enumVal === 'number') {
                        return {
                            value: enumVal,
                            item: key
                        };
                    }
                });
            },
            enumValueToKeyString: (enumType, value) => {
                if (typeof value === 'string' || value === null) {
                    return null;
                }
                return enumType[value];
            }
        };
        const generalUtilities = {
            getLetterIncrement: (index) => {
                const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
                return alphabet[index];
            }
        };
        app.mixin({
            computed: {
                isMobileWidth() {
                    return store.state.window.width <= 549;
                }
            }
        });
        app.provide('Notifications', Notifications);
        app.component('LoadingContainer', LoadingContainer);
        app.config.globalProperties.$emptyValueUtilities = emptyValueUtilities;
        app.config.globalProperties.$stringUtilities = stringUtilities;
        app.config.globalProperties.$enumUtilities = enumUtilities;
        app.config.globalProperties.$generalUtilities = generalUtilities;
    }
};
