export class PagingFilter {
    _returnAll;
    _page;
    _pageSize;
    _totalItems;
    constructor(returnAll = false) {
        if (returnAll) {
            this._returnAll = true;
            this._page = 0;
            this._pageSize = 0;
        }
        else {
            this._returnAll = false;
            this._page = 1;
            this._pageSize = 10;
        }
        this._totalItems = 0;
    }
    get returnAll() {
        return this._returnAll;
    }
    get page() {
        return this._page;
    }
    get pageSize() {
        return this._pageSize;
    }
    get totalItems() {
        return this._totalItems;
    }
    options() {
        return {
            page: this._page,
            pageSize: this._pageSize,
            returnAll: this._returnAll
        };
    }
    maxNumberOfPages() {
        if (!this._returnAll) {
            if (this._totalItems !== 0) {
                return Math.ceil(this._totalItems / this._pageSize);
            }
        }
        return 1;
    }
    update(changes) {
        this._returnAll = changes.returnAll ?? this._returnAll;
        this._totalItems = changes.totalItems ?? this._totalItems;
        if (changes.returnAll) {
            this._page = 0;
            this._pageSize = 0;
            return true;
        }
        if (this.validPage(this._totalItems, changes.page ?? this._page, changes.pageSize ?? this._pageSize)) {
            this._page = changes.page ?? this._page;
            this._pageSize = changes.pageSize ?? this._pageSize;
            return true;
        }
        this._page = 1;
        this._pageSize = changes.pageSize ?? (this._pageSize !== 0 ? this._pageSize : 10);
        return this._totalItems === 0;
    }
    validPage(totalItems, page, pageSize) {
        if (pageSize === 0) {
            return false;
        }
        if (totalItems !== 0 && page > 0) {
            const maxNumberOfPages = Math.ceil(totalItems / pageSize);
            return page <= maxNumberOfPages;
        }
        return page === 1;
    }
}
