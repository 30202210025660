export var CommonIcons;
(function (CommonIcons) {
    CommonIcons["add"] = "fas fa-plus";
    CommonIcons["delete"] = "fas fa-trash-alt";
    CommonIcons["edit"] = "fas fa-pencil-alt";
    CommonIcons["download"] = "fas fa-file-download";
    CommonIcons["done"] = "fas fa-check";
    CommonIcons["x"] = "fas fa-times";
    CommonIcons["up"] = "fas fa-arrow-up";
    CommonIcons["down"] = "fas fa-arrow-down";
    CommonIcons["expand"] = "fas fa-chevron-down";
    CommonIcons["collapse"] = "fas fa-chevron-up";
})(CommonIcons || (CommonIcons = {}));
