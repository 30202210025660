import { defineComponent } from 'vue';
import AppCardRouterButton from '@/components/shared/AppCardRouterButton.vue';
import { VendorRoutes } from '@/router/index';
export default defineComponent({
    name: 'VendorHome',
    components: {
        AppCardRouterButton
    },
    computed: {
        routes() {
            return VendorRoutes.filter((route) => {
                return route.name !== 'Home';
            });
        }
    }
});
