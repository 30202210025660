
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: 'Error.'
    },
    subTitle: {
      type: String,
      required: false,
      default: 'An error occurred while processing your request.'
    },
    errorMessage: {
      type: String,
      required: false,
      default: 'Please contact the administrator of the site if the issue persists.'
    }
  }
}
