import { defineComponent } from 'vue';
export default defineComponent({
    el: '.form-check',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            required: false,
            type: [Boolean, String],
            default: false
        },
        options: {
            required: false,
            type: Object,
            default: []
        },
        label: {
            required: false,
            type: String,
            default: null
        },
        id: {
            required: false,
            type: String,
            default: null
        },
        name: {
            required: false,
            type: String,
            default: null
        },
        required: {
            required: false,
            type: Boolean,
            default: false
        },
        readOnly: {
            required: false,
            type: Boolean,
            default: false
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            value: ''
        };
    },
    computed: {
        hasLabel() {
            return this.label !== null && this.label !== '';
        },
        computedId() {
            return this.id !== null && this.id !== '' ? '#' + this.id : null;
        }
    },
    mounted() {
        this.value = this.modelValue;
    }
});
