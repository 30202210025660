import AveHttpService from '@/services/AveHttpService';
class CasesItemsService {
    /**
     *  @description Retrieves all case items based on a filter
     */
    getCaseItemList(filter) {
        return AveHttpService.get('api/v1/cases/items', { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all case items for a document's case id
     */
    getCaseItemListForDocumentId(documentId, filter) {
        return AveHttpService.get(`api/v1/cases/${documentId}/items`, { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Adds an item to a case
     */
    addCaseItem(caseId, data) {
        return AveHttpService.post(`api/v1/cases/${caseId}/items/add`, data).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Updates case item information
     */
    updateCaseItem(caseItemId, data) {
        return AveHttpService.patch(`api/v1/cases/items/${caseItemId}/update`, data).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Updates case item approval information
     */
    updateCaseItemApproval(caseItemId, data) {
        return AveHttpService.patch(`api/v1/cases/items/${caseItemId}/updateApproval`, data).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Adds note for a case item
     */
    addCaseItemNote(caseItemId, data) {
        return AveHttpService.post(`api/v1/cases/items/${caseItemId}/notes/add`, data).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Remove item from a case
     */
    removeCaseItem(caseItemId, data) {
        return AveHttpService.patch(`api/v1/cases/items/${caseItemId}/remove`, data).then((response) => {
            return response.data;
        });
    }
}
export default new CasesItemsService();
