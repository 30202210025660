export var TableColumnType;
(function (TableColumnType) {
    TableColumnType[TableColumnType["input"] = 0] = "input";
    TableColumnType[TableColumnType["text"] = 1] = "text";
    TableColumnType[TableColumnType["route"] = 2] = "route";
    TableColumnType[TableColumnType["list"] = 3] = "list";
})(TableColumnType || (TableColumnType = {}));
export var ListColumnFormat;
(function (ListColumnFormat) {
    ListColumnFormat[ListColumnFormat["none"] = 0] = "none";
    ListColumnFormat[ListColumnFormat["number"] = 1] = "number";
    ListColumnFormat[ListColumnFormat["bullet"] = 2] = "bullet";
})(ListColumnFormat || (ListColumnFormat = {}));
export var TextColumnType;
(function (TextColumnType) {
    TextColumnType[TextColumnType["date"] = 0] = "date";
    TextColumnType[TextColumnType["string"] = 1] = "string";
    TextColumnType[TextColumnType["number"] = 2] = "number";
    TextColumnType[TextColumnType["currency"] = 3] = "currency";
    TextColumnType[TextColumnType["yesNo"] = 4] = "yesNo";
})(TextColumnType || (TextColumnType = {}));
export var InputColumnType;
(function (InputColumnType) {
    InputColumnType[InputColumnType["text"] = 0] = "text";
    InputColumnType[InputColumnType["dropdown"] = 1] = "dropdown";
})(InputColumnType || (InputColumnType = {}));
