import { defineComponent } from 'vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
export default defineComponent({
    emits: ['onCancel', 'onSubmit', 'onForward', 'onBackward'],
    components: {
        BSButton,
        BSModal
    },
    props: {
        id: {
            required: true,
            type: String
        },
        formName: {
            required: true,
            type: String
        },
        slides: {
            required: true,
            type: Object
        },
        validateSlides: {
            required: false,
            type: Boolean,
            default: false
        },
        showIndicators: {
            required: false,
            type: Boolean,
            default: true
        },
        freezeForm: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            submitButton: {
                text: 'Yes',
                show: true
            },
            cancelButton: {
                text: 'No',
                show: true
            },
            currentSlideIndex: 0,
            maxSlideVisited: 0
        };
    },
    methods: {
        getSlide(slideIndex) {
            const slideName = this.slides[slideIndex].name;
            const slide = document.getElementById(slideName);
            return slide ?? null;
        },
        onSlide(e) {
            let isValid = true;
            if (this.validateSlides && e.direction === 'left') {
                const slide = this.getSlide(e.from);
                var inputElements = slide?.querySelectorAll('input[required], textarea[required], select[required]') ?? undefined;
                if (inputElements) {
                    inputElements.forEach(input => {
                        if (!this.$inputValidationUtilities.validateInput(input, true, true, true, true)) {
                            isValid = false;
                        }
                    });
                }
            }
            if (isValid) {
                if (e.direction === 'left') {
                    this.onForward(e);
                }
                else if (e.direction === 'right') {
                    this.onBackward(e);
                }
            }
            else {
                e.preventDefault();
            }
        },
        onForward(e) {
            if (e.to > this.maxSlideVisited) {
                if (e.to !== this.maxSlideVisited + 1) {
                    e.preventDefault();
                    return;
                }
                this.maxSlideVisited = e.to;
            }
            this.currentSlideIndex = e.to;
            if (e.to === this.slides.length) {
                this.$emit('onSubmit', e.to);
            }
            else {
                this.$emit('onForward', e.to);
            }
        },
        onBackward(e) {
            this.currentSlideIndex = e.to;
            this.$emit('onBackward', e.to);
        }
    },
    mounted() {
        const carouselForm = document.getElementById(this.id);
        if (carouselForm) {
            carouselForm.addEventListener('slide.bs.carousel', (e) => {
                this.onSlide(e);
            });
        }
    },
    unmounted() {
        const carouselForm = document.getElementById(this.id);
        if (carouselForm) {
            carouselForm.removeEventListener('slide.bs.carousel', (e) => {
                this.onSlide(e);
            });
        }
        this.$store.dispatch('emptyDelegateUser');
    }
});
