import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        tags: {
            type: Array,
            required: true
        }
    },
    emits: ['tagRemoved'],
    methods: {
        removeTag(val) {
            this.$emit('tagRemoved', val);
        }
    }
});
