export var CaseItemHistoryAction;
(function (CaseItemHistoryAction) {
    CaseItemHistoryAction[CaseItemHistoryAction["added"] = 0] = "added";
    CaseItemHistoryAction[CaseItemHistoryAction["edited"] = 1] = "edited";
    CaseItemHistoryAction[CaseItemHistoryAction["approved"] = 2] = "approved";
    CaseItemHistoryAction[CaseItemHistoryAction["rejectedManually"] = 3] = "rejectedManually";
    CaseItemHistoryAction[CaseItemHistoryAction["deleted"] = 4] = "deleted";
    CaseItemHistoryAction[CaseItemHistoryAction["rejectedAutomatically"] = 5] = "rejectedAutomatically";
    CaseItemHistoryAction[CaseItemHistoryAction["approvalRemoved"] = 6] = "approvalRemoved";
    CaseItemHistoryAction[CaseItemHistoryAction["approvedAutomatically"] = 7] = "approvedAutomatically";
})(CaseItemHistoryAction || (CaseItemHistoryAction = {}));
