import AveHttpService from '@/services/AveHttpService';
class AcceptedItemsService {
    // #region Accepted Items
    /**
     *  @description Retrieves a specific accepted item's data
     */
    getAcceptedItemByHumanReadableId(humanReadableId) {
        return AveHttpService.get(`api/v1/items/accepted/${humanReadableId}`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all accepted item based on filter requirements
     */
    getAcceptedItemList(filter) {
        return AveHttpService.get('api/v1/items/accepted', { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Adds a new accepted item record
     */
    addAcceptedItem(data) {
        return (AveHttpService.post('api/v1/items/accepted/add', data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Updates an accepted item record
     */
    updateAcceptedItem(acceptedItemId, data) {
        return (AveHttpService.patch(`api/v1/items/accepted/${acceptedItemId}/update`, data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Removes an accepted item record
     */
    removeAcceptedItem(acceptedItemId) {
        return (AveHttpService.delete(`api/v1/items/accepted/${acceptedItemId}/remove`)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves the generated accepted products report in pdf form
     */
    generateAcceptedProductsReportPdf(showPrices) {
        return AveHttpService.get('api/v1/items/accepted/report/generate', { responseType: 'arraybuffer', params: { showPrices: showPrices } }).then((response) => {
            return {
                blob: new Blob([response.data], { type: 'application/pdf' }),
                name: (response.headers['content-disposition'].split('filename=')[1].split(';')[0]).replace(/['"]+/g, '')
            };
        })
            .catch(error => {
            throw new TextDecoder('utf-8').decode(new Uint8Array(error.response.data)).replace(/['"]+/g, '');
        });
    }
    // #endregion
    // #region Details
    /**
     *  @description Retrieves a specific accepted item detail's data
     */
    getAcceptedItemDetail(acceptedItemDetailId) {
        return AveHttpService.get(`api/v1/items/accepted/details/${acceptedItemDetailId}`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all accepted item details for a specific accepted item based on filter requirements
     */
    getAcceptedItemDetails(acceptedItemId, filter) {
        return AveHttpService.get(`api/v1/items/accepted/${acceptedItemId}/details`, { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Gets list of all detail types
     */
    getAcceptedItemDetailTypes() {
        return AveHttpService.get('api/v1/items/accepted/details/types').then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Adds a new accepted item detail record
     */
    addAcceptedItemDetail(acceptedItemId, data) {
        return (AveHttpService.post(`api/v1/items/accepted/${acceptedItemId}/details/add`, data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Updates an accepted item detail record
     */
    updateAcceptedItemDetail(acceptedItemDetailId, data) {
        return (AveHttpService.patch(`api/v1/items/accepted/details/${acceptedItemDetailId}/update`, data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Removes an accepted item detail record
     */
    removeAcceptedItemDetail(acceptedItemDetailId) {
        return (AveHttpService.delete(`api/v1/items/accepted/details/${acceptedItemDetailId}/remove`)).then((response) => {
            return response.data;
        });
    }
    // #endregion
    // #region Vehicles
    /**
     *  @description Retrieves a specific accepted item vehicle's data
     */
    getAcceptedItemVehicle(acceptedItemVehicleId) {
        return AveHttpService.get(`api/v1/items/accepted/vehicles/${acceptedItemVehicleId}`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all accepted item vehicles for a specific accepted item based on filter requirements
     */
    getAcceptedItemVehicles(acceptedItemId, filter) {
        return AveHttpService.get(`api/v1/items/accepted/${acceptedItemId}/vehicles`, { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Adds a new accepted item vehicle record
     */
    addAcceptedItemVehicle(acceptedItemId, data) {
        return (AveHttpService.post(`api/v1/items/accepted/${acceptedItemId}/vehicles/add`, data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Updates an accepted item vehicle record
     */
    updateAcceptedItemVehicle(acceptedItemVehicleId, data) {
        return (AveHttpService.patch(`api/v1/items/accepted/vehicles/${acceptedItemVehicleId}/update`, data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Removes an accepted item vehicle record
     */
    removeAcceptedItemVehicle(acceptedItemVehicleId) {
        return (AveHttpService.delete(`api/v1/items/accepted/vehicles/${acceptedItemVehicleId}/remove`)).then((response) => {
            return response.data;
        });
    }
    // #endregion
    // #region Documents
    /**
     *  @description Retrieves a specific accepted item document for downlaod
     */
    getAcceptedItemDocumentFile(acceptedItemDocumentId) {
        return AveHttpService.get(`api/v1/items/accepted/documents/${acceptedItemDocumentId}/download`, { responseType: 'arraybuffer' }).then((response) => {
            return {
                blob: new Blob([new Uint8Array(response.data)], { type: 'application/pdf' }),
                name: (response.headers['content-disposition'].split('filename=')[1].split(';')[0]).replace(/['"]+/g, '')
            };
        })
            .catch(error => {
            throw new TextDecoder('utf-8').decode(new Uint8Array(error.response.data)).replace(/['"]+/g, '');
        });
    }
    /**
     *  @description Retrieves a specific accepted item document data
     */
    getAcceptedItemDocument(acceptedItemDocumentId) {
        return AveHttpService.get(`api/v1/items/accepted/documents/${acceptedItemDocumentId}`).then((response) => {
            return response.data;
        });
    }
    getAcceptedItemDocuments(acceptedItemId, filter) {
        return AveHttpService.get(`api/v1/items/accepted/${acceptedItemId}/documents`, { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Submits a document for an accepted item
     */
    submitAcceptedItemDocument(acceptedItemId, data) {
        return AveHttpService.put(`api/v1/items/accepted/${acceptedItemId}/documents/submit`, data);
    }
    /**
     *  @description Updates an accepted item document record
     */
    updateAcceptedItemDocument(acceptedItemDocumentId, data) {
        return (AveHttpService.patch(`api/v1/items/accepted/documents/${acceptedItemDocumentId}/update`, data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Removes an accepted item document record
     */
    removeAcceptedItemDocument(acceptedItemDocumentId) {
        return (AveHttpService.delete(`api/v1/items/accepted/documents/${acceptedItemDocumentId}/remove`)).then((response) => {
            return response.data;
        });
    }
}
export default new AcceptedItemsService();
