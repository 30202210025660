import { defineComponent, ref } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
import AppTable from '@/components/shared/AppTable.vue';
import { UserAccountsFilter } from '@/services/models/UserAccount';
import { GroupsFilter } from '@/services/models/Group';
import { DocumentTypesFilter } from '@/services/models/DocumentTypeStatus';
import { DocumentTypeStatusDraftOpenPermissionOptions, DocumentTypeStatusClosedIgnoredPermissionOptions } from '@/services/models/shared/DocumentTypeStatusPermission';
import UsersService from '@/services/UsersService';
import GroupService from '@/services/GroupsService';
import DocumentTypeStatusesService from '@/services/DocumentTypeStatusesService';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
import AppMultipleCheckbox from '@/components/shared/AppMultipleCheckbox.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppCheckbox from '@/components/shared/AppCheckbox.vue';
import { ApplicationRole, InternalApplicationRole } from '@/services/models/Role';
import { NotArchivedDocumentStatus } from '@/services/models/shared/DocumentStatus';
import { OrganizationType } from '@/services/models/shared/OrganizationType';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import BSAlert from '@/components/shared/bootstrap/Alert.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
export default defineComponent({
    name: 'Employees',
    components: {
        AppTable,
        AppInput,
        BSModal,
        AppMultipleCheckbox,
        BSAlert,
        BSButton,
        AppCheckbox,
        AppCollapsingCardWithButtons
    },
    setup() {
        const editUserModal = ref();
        return { editUserModal };
    },
    data() {
        return {
            accounts: [],
            documentTypeStatusOptions: [],
            groupOptions: [],
            filter: new UserAccountsFilter(false),
            isLoading: true,
            addModalKey: false,
            submitButton: {
                text: 'Save',
                show: true
            },
            cancelButton: {
                text: 'Cancel',
                show: true
            },
            updatedUser: null,
            selectedDocumentTypeStatusPermissions: [],
            employeeSelectedRoles: []
        };
    },
    computed: {
        documentStatusPermissions() {
            return this.$enumUtilities.enumToSelectOptions(NotArchivedDocumentStatus);
        },
        internalRoles() {
            return this.$enumUtilities.enumToSelectOptions(InternalApplicationRole);
        },
        documentTypeStatusDraftOpenPermissionOptions() {
            return this.$enumUtilities.enumToSelectOptions(DocumentTypeStatusDraftOpenPermissionOptions);
        },
        documentTypeStatusClosedIgnoredPermissionOptions() {
            return this.$enumUtilities.enumToSelectOptions(DocumentTypeStatusClosedIgnoredPermissionOptions);
        }
    },
    methods: {
        loadAccounts() {
            this.isLoading = true;
            UsersService.getUserAccounts(this.filter.options())
                .then(response => {
                if (!this.filter.update({ totalItems: response.totalCount })) {
                    this.loadAccounts();
                    return;
                }
                this.accounts = response.data.map((e) => ({
                    id: e.id,
                    emailAddress: e.emailAddress,
                    organizationName: e.organizationName,
                    department: e.department,
                    title: e.title,
                    phoneNumber: e.phoneNumber,
                    fullName: e.fullName,
                    isAuthorized: e.isAuthorized ? 'Yes' : 'No',
                    documentTypeStatusPermissions: e.documentTypeStatusPermissions,
                    groupDocumentTypeStatusPermissions: e.groups.map((g) => { return g.documentTypeStatusPermissions; }).flat(),
                    groups: e.groups,
                    roles: e.roles.length === 0 ? '' : e.roles.map((role) => { return this.$stringEnumUtilities.getKeyFromValue(ApplicationRole, role.toString()); }).join(', ')
                }));
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        loadDocumentTypeStatuses() {
            // Get list of existing document type statuses
            DocumentTypeStatusesService.getDocumentTypes((new DocumentTypesFilter(true)).options())
                .then(response => {
                this.documentTypeStatusOptions = response.data.map((t) => {
                    return {
                        id: t.id,
                        name: t.name,
                        documentStatuses: t.documentStatuses.map((s) => {
                            if (this.updatedUser &&
                                this.updatedUser.documentTypeStatusPermissions &&
                                this.updatedUser.documentTypeStatusPermissions !== null &&
                                this.updatedUser.documentTypeStatusPermissions.findIndex((x) => { return x.documentTypeId === t.id; }) > -1) {
                                const documentTypeStatusPermissionsIndex = this.updatedUser.documentTypeStatusPermissions.findIndex((x) => { return x.documentTypeId === t.id; });
                                const documentStatusPermissions = this.updatedUser.documentTypeStatusPermissions[documentTypeStatusPermissionsIndex].documentStatusPermissions;
                                if (documentStatusPermissions && documentStatusPermissions !== null) {
                                    const documentStatusPermission = documentStatusPermissions.find((y) => { return y.documentTypeStatusId === s.id; });
                                    if (documentStatusPermission && documentStatusPermission !== null) {
                                        return {
                                            name: s.documentStatusName,
                                            id: s.id,
                                            documentState: s.documentState,
                                            isSelected: true,
                                            permissions: documentStatusPermission.permissions
                                        };
                                    }
                                }
                            }
                            return {
                                name: s.documentStatusName,
                                id: s.id,
                                documentState: s.documentState,
                                isSelected: false,
                                permissions: []
                            };
                        })
                    };
                });
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            });
        },
        loadGroups() {
            var groupFilter = new GroupsFilter(true);
            groupFilter.externalUserGroups = false;
            GroupService.getGroups(groupFilter.options())
                .then(response => {
                this.groupOptions = response.data.map((g) => {
                    return {
                        value: g.id,
                        item: g.name
                    };
                });
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            });
        },
        editUser(id) {
            UsersService.getUserAccount(id)
                .then(response => {
                this.updatedUser = {
                    id: response.id,
                    emailAddress: response.emailAddress,
                    organizationId: response.organizationId,
                    department: response.department,
                    title: response.title,
                    fullName: response.fullName,
                    phoneNumber: response.phoneNumber,
                    isAuthorized: response.isAuthorized,
                    groups: response.groups.map((group) => { return group.id; }),
                    roles: response.roles.map((role) => { return role.toString().toUpperCase(); }),
                    documentTypeStatusPermissions: response.documentTypeStatusPermissions.filter((p) => { return p.entityId === response.id; })
                };
                this.selectedDocumentTypeStatusPermissions = [];
                if (response.documentTypeStatusPermissions && response.documentTypeStatusPermissions !== null) {
                    response.documentTypeStatusPermissions.filter((p) => { return p.entityId === response.id; }).forEach((x) => {
                        const allPermissions = x.documentStatusPermissions.map((y) => {
                            let totalPermissions = 0;
                            y.permissions.forEach((p) => {
                                totalPermissions = totalPermissions + p;
                            });
                            return {
                                documentTypeStatusId: y.documentTypeStatusId,
                                permissions: totalPermissions
                            };
                        });
                        this.selectedDocumentTypeStatusPermissions = this.selectedDocumentTypeStatusPermissions.concat(allPermissions);
                    });
                }
                if (this.editUserModal) {
                    this.editUserModal.show();
                }
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            })
                .finally(() => {
                this.loadDocumentTypeStatuses();
                this.loadGroups();
            });
        },
        updatePagination(page, pageSize) {
            this.filter.update({ page: page, pageSize: pageSize });
            this.loadAccounts();
        },
        addUser() {
            var form = document.forms.namedItem('addUserForm');
            if (form !== null) {
                var formData = new FormData(form);
                UsersService.addUserAccount(formData)
                    .then((response) => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadAccounts();
                })
                    .catch((error) => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        updateUser() {
            var form = document.forms.namedItem('updateUserForm');
            if (form !== null && this.updatedUser !== null) {
                var formData = new FormData(form);
                UsersService.updateUserAccount(this.updatedUser.id, formData)
                    .then((response) => {
                    Notifications.methods.addToast(ToastType.success, response);
                    this.loadAccounts();
                })
                    .catch((error) => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        generateAndDownloadEmployeeUserAccountsReport() {
            this.$loadingBackdrop.data.isVisible = true;
            UsersService.generateUserAccountsReportPdf(ApplicationRole.Employee)
                .then(response => {
                const data = window.URL.createObjectURL(response.blob);
                var anchor = document.createElement('a');
                anchor.download = response.name;
                anchor.href = data;
                anchor.click();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            })
                .finally(() => {
                this.$loadingBackdrop.data.isVisible = false;
            });
        },
        selectDocumentTypeStatus(documentTypeStatusId, isSelected) {
            var indexOfValue = this.selectedDocumentTypeStatusPermissions?.findIndex((x) => { return x.documentTypeStatusId === documentTypeStatusId; });
            if (indexOfValue > -1 && !isSelected) {
                this.selectedDocumentTypeStatusPermissions.splice(indexOfValue, 1);
            }
            else if (indexOfValue === -1 && isSelected) {
                this.selectedDocumentTypeStatusPermissions.push({
                    documentTypeStatusId: documentTypeStatusId,
                    permissions: 0
                });
            }
        },
        permissionsChanged(documentTypeStatusId, permissions) {
            var indexOfValue = this.selectedDocumentTypeStatusPermissions?.findIndex((x) => { return x.documentTypeStatusId === documentTypeStatusId; });
            if (indexOfValue > -1) {
                this.selectedDocumentTypeStatusPermissions[indexOfValue].permissions = permissions.reduce((partialSum, a) => partialSum + a, 0);
            }
        },
        expandDetails(id) {
            var row = document.getElementById('details-' + id);
            var rowMobile = document.getElementById('details-mobile-' + id);
            if (row) {
                if (row.classList.contains('d-none')) {
                    row.classList.remove('d-none');
                }
                else {
                    row.classList.add('d-none');
                }
            }
            if (rowMobile) {
                if (rowMobile.classList.contains('d-none')) {
                    rowMobile.classList.remove('d-none');
                }
                else {
                    rowMobile.classList.add('d-none');
                }
            }
        }
    },
    created() {
        this.filter.organizationType = OrganizationType.tti;
        this.loadAccounts();
    }
});
