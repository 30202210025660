import { defineComponent } from 'vue';
import InspectionFormsService from '@/services/InspectionFormsService';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
export default defineComponent({
    name: 'InspectionForms',
    data() {
        return {
            forms: [
                { fileName: 'Access-Battery-System', title: 'Access Battery System' },
                { fileName: 'Brakes-Left-Foot-Accelerator-Pedal-Extensions', title: 'Brakes, Left Foot Accelerator, Pedal Extensions' },
                { fileName: 'Cartop-Carrier', title: 'Cartop Carrier' },
                { fileName: 'Floors-Roofs-Doors', title: 'Floors, Roofs, Doors' },
                { fileName: 'General-Design', title: 'General Design' },
                { fileName: 'Mechanical-Hand-Controls', title: 'Mechanical Hand Controls' },
                { fileName: 'Mobility-Aid-Hoist', title: 'Mobility Aid Hoist' },
                { fileName: 'Mobility-Aid-Tiedown', title: 'Mobility Aid Tiedown' },
                { fileName: 'Minivan-Conversion', title: 'Minivan Conversion' },
                { fileName: 'Passenger-Restraints', title: 'Passenger Restraints' },
                { fileName: 'Personal-Lifts-Steps-Handles', title: 'Personal Lifts, Steps, Handles' },
                { fileName: 'Racks', title: 'Racks' },
                { fileName: 'Ramps', title: 'Ramps' },
                { fileName: 'Seats', title: 'Seats' },
                { fileName: 'Secondary-Controls', title: 'Secondary Controls' },
                { fileName: 'Unoccupied-Tiedowns', title: 'Unoccupied Tiedowns' },
                { fileName: 'Wheelchair-Lifts', title: 'Wheelchair Lifts' }
            ]
        };
    },
    methods: {
        downloadInspectionFormFile(fileName) {
            InspectionFormsService.getInspectionFormFile(fileName)
                .then(response => {
                const data = window.URL.createObjectURL(response.blob);
                var anchor = document.createElement('a');
                anchor.download = response.name;
                anchor.href = data;
                anchor.click();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            });
        }
    }
});
