import { defineComponent } from 'vue';
import { ButtonColor } from '@/services/models/shared/bootstrap/ButtonColor';
export default defineComponent({
    name: 'BSButton',
    props: {
        buttonColor: {
            required: false,
            default: ButtonColor.primary
        },
        text: {
            required: false,
            default: ''
        },
        showTextInMobile: {
            required: false,
            default: false
        }
    },
    el: '.btn',
    data() {
        return {
            hasSlot: false,
            isLoading: true
        };
    },
    mounted() {
        this.hasSlot = !!this.$slots.default;
        this.isLoading = false;
    }
});
