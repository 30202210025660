import AveHttpService from '@/services/AveHttpService';
class InspectionFormsService {
    /**
     *  @description Retrieves a specified inspection form file for downlaod
     */
    getInspectionFormFile(fileName) {
        return AveHttpService.get(`api/v1/InspectionForms/${fileName}/download`, { responseType: 'arraybuffer' }).then((response) => {
            return {
                blob: new Blob([new Uint8Array(response.data)], { type: 'application/pdf' }),
                name: (response.headers['content-disposition'].split('filename=')[1].split(';')[0]).replace(/['"]+/g, '')
            };
        })
            .catch(error => {
            throw new TextDecoder('utf-8').decode(new Uint8Array(error.response.data)).replace(/['"]+/g, '');
        });
    }
}
export default new InspectionFormsService();
