import moment from 'moment';
export default {
    install: (app) => {
        const inputValidationUtilities = {
            validateInput: (e, indicateIfValid, showInvalidMessage, highlightInvalid, includeRequiredValidation) => {
                const value = e.value;
                let invalidMessageElement = e.parentNode;
                let isInvalid = false;
                const errorMessage = [];
                if (e.hasAttribute('required') && includeRequiredValidation) {
                    if (inputValidationUtilities.validateRequired(value) === false) {
                        isInvalid = true;
                        errorMessage.push('Value is required.');
                    }
                    else {
                        if (e.hasAttribute('type')) {
                            if (e.getAttribute('type') === 'date') {
                                if (inputValidationUtilities.validateDate(value) === false) {
                                    isInvalid = true;
                                    errorMessage.push('Not a proper date.');
                                }
                            }
                            if (e.getAttribute('type') === 'email') {
                                if (inputValidationUtilities.validateEmail(value) === false) {
                                    isInvalid = true;
                                    errorMessage.push('Not a proper email.');
                                }
                            }
                            if (e.getAttribute('type') === 'checkbox') {
                                const siblings = document.getElementsByName(e.getAttribute('name'));
                                if (siblings.length > 0) {
                                    let hasValue = false;
                                    let alreadyInvalid = false;
                                    siblings.forEach((x) => {
                                        if (x.checked) {
                                            hasValue = true;
                                        }
                                        if (x.classList.contains('is-invalid')) {
                                            alreadyInvalid = true;
                                        }
                                    });
                                    if (!hasValue) {
                                        isInvalid = true;
                                        if (!alreadyInvalid) {
                                            invalidMessageElement = siblings[siblings.length - 1].parentNode;
                                            errorMessage.push('At least one value must be selected.');
                                        }
                                    }
                                }
                            }
                        }
                        if (e.hasAttribute('maxlength') || e.hasAttribute('minlength')) {
                            let minLength = 0;
                            let maxLength = 0;
                            if (e.hasAttribute('maxlength')) {
                                maxLength = parseInt(e.getAttribute('maxlength'), 10);
                            }
                            if (e.hasAttribute('minlength')) {
                                minLength = parseInt(e.getAttribute('minlength'), 10);
                            }
                            if (e.hasAttribute('minlength') && e.hasAttribute('maxlength') && minLength === maxLength) {
                                if (inputValidationUtilities.validateLength(value, minLength) === false) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be ' + minLength.toString() + ' characters in length.');
                                }
                            }
                            else {
                                if (e.hasAttribute('minlength') && inputValidationUtilities.validateMinLength(value, minLength) === false) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be a minimum of ' + minLength.toString() + ' characters in length.');
                                }
                                if (e.hasAttribute('maxlength') && inputValidationUtilities.validateMaxLength(value, maxLength) === false) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be a maximum of ' + maxLength.toString() + ' characters in length.');
                                }
                            }
                        }
                        if (e.hasAttribute('min') || e.hasAttribute('max')) {
                            let min = 0;
                            let max = 0;
                            if (e.hasAttribute('min')) {
                                min = parseInt(e.getAttribute('min'), 10);
                            }
                            if (e.hasAttribute('max')) {
                                max = parseInt(e.getAttribute('max'), 10);
                            }
                            if (e.hasAttribute('min') && e.hasAttribute('max')) {
                                if (!(value >= min && value <= max)) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be between ' + min.toString() + ' and ' + max.toString() + '.');
                                }
                            }
                            else if (e.hasAttribute('min')) {
                                if (!(value >= min)) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be greater than or equal to ' + min.toString() + '.');
                                }
                            }
                            else if (e.hasAttribute('max')) {
                                if (!(value <= max)) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be less than or equal to ' + max.toString() + '.');
                                }
                            }
                        }
                    }
                }
                else {
                    // Check if it has a value using the same method
                    if (inputValidationUtilities.validateRequired(value) === true) {
                        if (e.hasAttribute('type')) {
                            if (e.getAttribute('type') === 'date') {
                                if (inputValidationUtilities.validateDate(value) === false) {
                                    isInvalid = true;
                                    errorMessage.push('Not a proper date.');
                                }
                            }
                            if (e.getAttribute('type') === 'email') {
                                if (inputValidationUtilities.validateEmail(value) === false) {
                                    isInvalid = true;
                                    errorMessage.push('Not a proper email.');
                                }
                            }
                            if (e.getAttribute('type') === 'checkbox') {
                                const siblings = document.getElementsByName(e.getAttribute('name'));
                                if (siblings.length > 0) {
                                    let hasValue = false;
                                    let alreadyInvalid = false;
                                    siblings.forEach((x) => {
                                        if (x.checked) {
                                            hasValue = true;
                                        }
                                        if (x.classList.contains('is-invalid')) {
                                            alreadyInvalid = true;
                                        }
                                    });
                                    if (!hasValue) {
                                        isInvalid = true;
                                        if (!alreadyInvalid) {
                                            invalidMessageElement = siblings[siblings.length - 1].parentNode;
                                            errorMessage.push('At least one value must be selected.');
                                        }
                                    }
                                }
                            }
                        }
                        if (e.hasAttribute('maxlength') || e.hasAttribute('minlength')) {
                            let minLength = 0;
                            let maxLength = 0;
                            if (e.hasAttribute('maxlength')) {
                                maxLength = parseInt(e.getAttribute('maxlength'), 10);
                            }
                            if (e.hasAttribute('minlength')) {
                                minLength = parseInt(e.getAttribute('minlength'), 10);
                            }
                            if (e.hasAttribute('minlength') && e.hasAttribute('maxlength') && minLength === maxLength) {
                                if (inputValidationUtilities.validateLength(value, minLength) === false) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be ' + minLength.toString() + ' characters in length.');
                                }
                            }
                            else {
                                if (e.hasAttribute('minlength') && inputValidationUtilities.validateMinLength(value, minLength) === false) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be a minimum of ' + minLength.toString() + ' characters in length.');
                                }
                                if (e.hasAttribute('maxlength') && inputValidationUtilities.validateMaxLength(value, maxLength) === false) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be a maximum of ' + maxLength.toString() + ' characters in length.');
                                }
                            }
                        }
                        if (e.hasAttribute('min') || e.hasAttribute('max')) {
                            let min = 0;
                            let max = 0;
                            if (e.hasAttribute('min')) {
                                min = parseInt(e.getAttribute('min'), 10);
                            }
                            if (e.hasAttribute('max')) {
                                max = parseInt(e.getAttribute('max'), 10);
                            }
                            if (e.hasAttribute('min') && e.hasAttribute('max')) {
                                if (!(value >= min && value <= max)) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be between ' + min.toString() + ' and ' + max.toString() + '.');
                                }
                            }
                            else if (e.hasAttribute('min')) {
                                if (!(value >= min)) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be greater than or equal to ' + min.toString() + '.');
                                }
                            }
                            else if (e.hasAttribute('max')) {
                                if (!(value <= max)) {
                                    isInvalid = true;
                                    errorMessage.push('Value should be less than or equal to ' + max.toString() + '.');
                                }
                            }
                        }
                    }
                }
                if (isInvalid) {
                    e.classList.remove('is-valid');
                    if (highlightInvalid) {
                        e.classList.add('is-invalid');
                    }
                    if (showInvalidMessage && errorMessage.length > 0) {
                        inputValidationUtilities.insertInvalidTooltip(invalidMessageElement, errorMessage.join(' '));
                    }
                    return false;
                }
                else {
                    e.classList.remove('is-invalid');
                    if (indicateIfValid) {
                        e.classList.add('is-valid');
                    }
                    if (showInvalidMessage) {
                        const invalidMessageElement = e.querySelector('div.invalid-tooltip, div.invalid-feedback');
                        if (invalidMessageElement) {
                            inputValidationUtilities.insertInvalidTooltip(invalidMessageElement, '');
                        }
                    }
                    return true;
                }
            },
            validateDate: (date) => {
                const formattedDate = moment(date).format('MM/DD/YYYY');
                if (moment(formattedDate, 'MM/DD/YYYY', true).isValid() === false) {
                    return false;
                }
                return true;
            },
            validateRequired: (value) => {
                if (value === null || value === '' || value === 'null') {
                    return false;
                }
                return true;
            },
            validateLength: (value, length) => {
                if (value.length !== length) {
                    return false;
                }
                return true;
            },
            validateMaxLength: (value, length) => {
                if (value.length > length) {
                    return false;
                }
                return true;
            },
            validateMinLength: (value, length) => {
                if (value.length < length) {
                    return false;
                }
                return true;
            },
            validateEmail: (value) => {
                const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                return reg.test(value);
            },
            insertInvalidTooltip: (e, message) => {
                const invalidMessageElement = e.querySelector('div.invalid-tooltip, div.invalid-feedback');
                if (invalidMessageElement) {
                    invalidMessageElement.innerText = message;
                }
                else {
                    const div = document.createElement('div');
                    div.classList.add('invalid-tooltip');
                    div.innerText = message;
                    e.appendChild(div);
                }
            }
        };
        const inputMasks = {
            phoneNumberMask: (inputElement) => {
                const x = inputElement.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                inputElement.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
            }
        };
        app.mixin({
            mounted() {
                document.querySelectorAll('label').forEach(function (label) {
                    const inputId = label.getAttribute('for');
                    let input = null;
                    if (inputId) {
                        input = document.getElementById(inputId);
                    }
                    if (input) {
                        if (input.hasAttribute('required')) {
                            label.classList.add('required');
                        }
                    }
                });
            }
        });
        app.config.globalProperties.$inputValidationUtilities = inputValidationUtilities;
        app.config.globalProperties.$inputMasks = inputMasks;
    }
};
