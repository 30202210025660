import AveHttpService from '@/services/AveHttpService';
class GroupsService {
    /**
     *  @description Retrieves a specific group's data
     */
    getGroup(groupId) {
        return AveHttpService.get(`api/v1/groups/${groupId}`).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves all groups based on filter requirements
     */
    getGroups(filter) {
        return AveHttpService.get('api/v1/groups', { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Adds a new group record
     */
    addGroup(data) {
        return (AveHttpService.post('api/v1/groups/add', data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Updates a group record
     */
    updateGroup(groupId, data) {
        return (AveHttpService.patch(`api/v1/groups/${groupId}/update`, data)).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Removes a group record
     */
    removeGroup(groupId) {
        return (AveHttpService.delete(`api/v1/groups/${groupId}/remove`)).then((response) => {
            return response.data;
        });
    }
}
export default new GroupsService();
