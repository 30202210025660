import { PagingFilter } from '@/services/models/shared/PagingFilter';
export class CaseDocumentsFilter extends PagingFilter {
    excludeDocumentId;
    constructor(returnAll = false) {
        super(returnAll);
        this.excludeDocumentId = null;
    }
    options() {
        return Object.assign({
            excludeDocumentId: this.excludeDocumentId
        }, super.options());
    }
}
export class DocumentsFilter extends PagingFilter {
    caseId;
    humanReadableId;
    documentStatusId;
    documentTypeId;
    employeeAssigneeUserId;
    submitterUserId;
    submitterName;
    organizationType;
    constructor(returnAll = false) {
        super(returnAll);
        this.caseId = null;
        this.humanReadableId = null;
        this.documentStatusId = null;
        this.documentTypeId = null;
        this.employeeAssigneeUserId = null;
        this.submitterUserId = null;
        this.submitterName = null;
        this.organizationType = null;
    }
    options() {
        return Object.assign({
            caseId: this.caseId,
            humanReadableId: this.humanReadableId,
            documentStatusId: this.documentStatusId,
            documentTypeId: this.documentTypeId,
            employeeAssigneeUserId: this.employeeAssigneeUserId,
            submitterUserId: this.submitterUserId,
            submitterName: this.submitterName,
            organizationType: this.organizationType
        }, super.options());
    }
}
