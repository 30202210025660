import { defineComponent } from 'vue';
import { DocumentHistoryFilter } from '@/services/models/DocumentHistory';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
import DocumentService from '@/services/DocumentService';
import AppTable from '@/components/shared/AppTable.vue';
export default defineComponent({
    components: {
        AppTable
    },
    props: {
        documentId: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            filter: new DocumentHistoryFilter(),
            documentHistory: []
        };
    },
    methods: {
        loadData() {
            DocumentService.getDocumentHistory(this.documentId, this.filter.options())
                .then(response => {
                if (!this.filter.update({ totalItems: response.totalCount })) {
                    this.loadData();
                    return;
                }
                this.documentHistory = response.data;
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        updatePagination(page, pageSize) {
            this.filter.update({ page: page, pageSize: pageSize });
            this.loadData();
        }
    },
    created() {
        this.loadData();
    }
});
