import { defineComponent } from 'vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import { Modal } from 'bootstrap';
export default defineComponent({
    name: 'Modal',
    components: {
        BSButton
    },
    emits: ['modalSubmit', 'modalCancel', 'modalClosing', 'modalOpening'],
    props: {
        id: {
            required: true,
            type: String
        },
        modalTitle: {
            required: true,
            type: String
        },
        submitButton: {
            required: true,
            type: Object
        },
        cancelButton: {
            required: true,
            type: Object
        },
        ariaLabeledBy: {
            default: '',
            type: String
        },
        options: {
            default: {
                backdrop: true,
                keyboard: true,
                focus: true
            },
            backdrop: {
                type: [String, Boolean]
            },
            keyboard: {
                type: Boolean
            },
            focus: {
                type: Boolean
            }
        },
        dataLoading: {
            required: false,
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            initialModal: {},
            clearModal: false
        };
    },
    methods: {
        modal() {
            var options = this.options;
            var elem = document.getElementById(this.id);
            if (elem) {
                const modal = Modal.getOrCreateInstance(elem, options);
                return modal;
            }
            return null;
        },
        modalSubmit() {
            this.$emit('modalSubmit');
            const modal = this.modal();
            if (modal !== null) {
                modal.hide();
            }
        },
        modalCancel() {
            this.$emit('modalCancel');
        },
        modalClosing() {
            this.$emit('modalClosing');
        },
        modalOpening() {
            this.$emit('modalOpening');
        },
        show() {
            const modal = this.modal();
            if (modal !== null) {
                modal.show();
            }
        },
        close() {
            const modal = this.modal();
            if (modal !== null) {
                modal.hide();
            }
        }
    },
    mounted() {
        this.modal();
        window.addEventListener('hide.bs.modal', (e) => {
            var elem = document.getElementById(this.id);
            if (elem) {
                if (elem === e.target) {
                    this.modalClosing();
                }
            }
        });
        window.addEventListener('show.bs.modal', (e) => {
            var elem = document.getElementById(this.id);
            if (elem) {
                if (elem === e.target) {
                    this.modalOpening();
                }
            }
        });
    },
    unmounted() {
        window.removeEventListener('hide.bs.modal', (e) => {
            var elem = document.getElementById(this.id);
            if (elem) {
                if (elem === e.target) {
                    this.modalClosing();
                }
            }
        });
        window.removeEventListener('show.bs.modal', (e) => {
            var elem = document.getElementById(this.id);
            if (elem) {
                if (elem === e.target) {
                    this.modalOpening();
                }
            }
        });
    }
});
