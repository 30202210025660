export var DocumentType;
(function (DocumentType) {
    DocumentType["modification"] = "65dbc9ee-f597-400e-9cad-2fa5f33b20e5";
    DocumentType["inspection"] = "0937730b-d23b-4ec9-93a1-12cd5bb7d185";
    DocumentType["repair"] = "7a794610-a18f-4ca8-b45d-90ec948ed2b1";
    DocumentType["priceReview"] = "3f38c9f7-d287-4c33-80f3-8421d88d6272";
    DocumentType["carFax"] = "69ad0a05-7b56-4d4f-bfd1-c440ded5ac68";
})(DocumentType || (DocumentType = {}));
export var DocumentTypeString;
(function (DocumentTypeString) {
    DocumentTypeString["Vehicle Modification"] = "65dbc9ee-f597-400e-9cad-2fa5f33b20e5";
    DocumentTypeString["Vehicle Inspection"] = "0937730b-d23b-4ec9-93a1-12cd5bb7d185";
    DocumentTypeString["Repair Review"] = "7a794610-a18f-4ca8-b45d-90ec948ed2b1";
    DocumentTypeString["Pricing Review"] = "3f38c9f7-d287-4c33-80f3-8421d88d6272";
    DocumentTypeString["CarFax Report"] = "69ad0a05-7b56-4d4f-bfd1-c440ded5ac68";
})(DocumentTypeString || (DocumentTypeString = {}));
