import { defineComponent } from 'vue';
import FormService from '@/services/FormService';
import AppCarouselForm from '@/components/shared/AppCarouselForm.vue';
import AppInput from '@/components/shared/AppInput.vue';
export default defineComponent({
    emits: ['onCancel'],
    components: {
        AppCarouselForm,
        AppInput
    },
    data() {
        return {
            carFaxRequest: {
                formVersion: null,
                formVersionDate: null,
                caseId: null,
                phoneNumber: null,
                customer: {
                    firstName: null,
                    lastName: null
                },
                vehicle: {
                    year: null,
                    make: null,
                    model: null,
                    vinNumber: null
                }
            },
            submissionSuccessful: null,
            returnedMessage: null
        };
    },
    computed: {
        formSections() {
            return [{ name: 'contactInformationSlide', title: 'Contact Information' }, { name: 'customerInformationSlide', title: 'Customer Information' }, { name: 'vehicleInformationSlide', title: 'Vehicle Information' }];
        }
    },
    methods: {
        onSubmit() {
            var formData = new FormData(document.forms[0]);
            FormService.submitCarFaxRequestForm(formData)
                .then(() => {
                this.submissionSuccessful = true;
            })
                .catch(error => {
                this.returnedMessage = error.response.data;
                this.submissionSuccessful = false;
            });
        }
    }
});
