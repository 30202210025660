import { createRouter, createWebHistory } from 'vue-router';
import Items from '../views/items/Items.vue';
import Requests from '../views/requests/Requests.vue';
import InspectionForms from '../views/inspectionForms/InspectionForms.vue';
import DocumentList from '@/components/documents/DocumentList.vue';
import DocumentDetails from '../views/document/DocumentDetails.vue';
import PricingReviewRequest from '../views/forms/PricingReviewRequest.vue';
import CarFaxRequest from '../views/forms/CarFaxRequest.vue';
import RepairReviewRequest from '../views/forms/RepairReviewRequest.vue';
import VehicleInspectionRequest from '../views/forms/VehicleInspectionRequest.vue';
import Error from '../views/Error.vue';
import Maintenance from '../views/Maintenance.vue';
import { ApplicationRole } from '../services/models/Role';
import Accounts from '../views/accounts/Accounts.vue';
import CustomerList from '../views/accounts/customers/CustomerList.vue';
import EmployeeList from '../views/accounts/employees/EmployeeList.vue';
import OrganizationList from '../views/accounts/organizations/OrganizationList.vue';
import VendorList from '../views/accounts/vendors/VendorList.vue';
import GroupList from '../views/accounts/groups/GroupList.vue';
import store from '../store/index';
import TaskOrderManagementSystemHome from '@/views/TaskOrderManagementSystemHome.vue';
import CustomerHome from '@/views/CustomerHome.vue';
import Forms from '@/views/forms/Forms.vue';
import AcceptedItem from '@/views/items/accepted/AcceptedItem.vue';
import AcceptedItemList from '@/views/items/accepted/AcceptedItemList.vue';
import ManufacturerList from '@/views/items/manufacturers/ManufacturerList.vue';
import Vehicles from '../views/vehicles/Vehicles.vue';
import CaseItemList from '@/views/items/cases/CaseItemList.vue';
import VendorHome from '../views/VendorHome.vue';
var RouteIcons;
(function (RouteIcons) {
    RouteIcons["home"] = "";
    RouteIcons["forms"] = "fas fa-copy";
    RouteIcons["pricingReviewRequest"] = "fas fa-file-invoice-dollar";
    RouteIcons["repairReviewRequest"] = "fas fa-tools";
    RouteIcons["vehicleInspectionRequest"] = "fas fa-tasks";
    RouteIcons["carFaxRequest"] = "fa fa-file-alt";
    RouteIcons["inbox"] = "fa fa-inbox";
    RouteIcons["outbox"] = "fa fa-file-alt";
    RouteIcons["archive"] = "fas fa-archive";
    RouteIcons["items"] = "fas fa-box-open";
    RouteIcons["accounts"] = "fas fa-users";
    RouteIcons["vehicles"] = "fas fa-car";
    RouteIcons["inspectionForms"] = "fas fa-file-download";
})(RouteIcons || (RouteIcons = {}));
/**
 * @description Checks to see if the current user has a role in one of the specified params. If it does then the user is
 * navigated to that route, if not the routing is aborted.
 * @return {boolean}
 */
function authorizeRoute(to, from, next, authorizedRoles) {
    // Redirect if unauthorized route
    if (store.getters.userIsEmpty && to.name !== 'Home') {
        next('/');
        return;
    }
    if (store.getters.user !== null) {
        // Check roles
        if (store.getters.currentRoleId.toUpperCase() === ApplicationRole.Administrator ||
            authorizedRoles === null) {
            // Site Administrators can hit any route or anyone can enter route
            next();
        }
        else if (authorizedRoles.length === 0) {
            // Check if admin role active
            if (store.getters.currentRoleId.toUpperCase() === ApplicationRole['AVE Administrator']) {
                next();
            }
            else {
                next(false);
            }
        }
        else if (authorizedRoles.includes(store.getters.currentRoleId.toUpperCase()) ||
            store.getters.currentRoleId.toUpperCase() === ApplicationRole['AVE Administrator']) {
            next();
        }
        else {
            next(false);
        }
        return;
    }
    next(false);
}
export const CustomerRoutes = [
    {
        path: '/',
        name: 'Home',
        component: CustomerHome,
        meta: { routeIcon: RouteIcons.home, showInMenu: true },
        beforeEnter(to, from, next) {
            if (to.fullPath.includes('#state')) {
                next('/');
            }
            else {
                authorizeRoute(to, from, next, [ApplicationRole.Customer]);
            }
        }
    },
    {
        path: '/InspectionForms',
        name: 'Inspection Forms',
        component: InspectionForms,
        meta: { routeIcon: RouteIcons.inspectionForms, showInMenu: true },
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Customer]);
        }
    },
    {
        path: '/Forms',
        name: 'Forms',
        component: Forms,
        meta: { routeIcon: RouteIcons.forms, showInMenu: true },
        children: [
            {
                path: 'PricingReviewRequest',
                name: 'Pricing Review/Vehicle Modification Request',
                component: PricingReviewRequest,
                meta: { routeIcon: RouteIcons.pricingReviewRequest },
                props: { atsName: String, atsOffice: String, atsPhoneNumber: String, atsFaxNumber: String, atsEmailAddress: String },
                beforeEnter(to, from, next) {
                    authorizeRoute(to, from, next, [ApplicationRole.Customer, ApplicationRole.Employee]);
                }
            },
            {
                path: 'RepairReviewRequest',
                name: 'Repair Review Request',
                component: RepairReviewRequest,
                meta: { routeIcon: RouteIcons.repairReviewRequest },
                props: { atsName: String, atsOffice: String, atsPhoneNumber: String, atsFaxNumber: String, atsEmailAddress: String },
                beforeEnter(to, from, next) {
                    authorizeRoute(to, from, next, [ApplicationRole.Customer, ApplicationRole.Employee]);
                }
            },
            {
                path: 'VehicleInspectionRequest',
                name: 'Vehicle Inspection Request',
                component: VehicleInspectionRequest,
                meta: { routeIcon: RouteIcons.vehicleInspectionRequest },
                beforeEnter(to, from, next) {
                    authorizeRoute(to, from, next, [ApplicationRole.Customer, ApplicationRole.Employee]);
                }
            },
            {
                path: 'CarFaxRequest',
                name: 'Car Fax Request',
                component: CarFaxRequest,
                meta: { routeIcon: RouteIcons.carFaxRequest },
                beforeEnter(to, from, next) {
                    authorizeRoute(to, from, next, [ApplicationRole.Customer, ApplicationRole.Employee]);
                }
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Customer, ApplicationRole.Employee]);
        }
    },
    {
        path: '/Inbox',
        name: 'Inbox',
        component: Requests,
        meta: { routeIcon: RouteIcons.inbox, showInMenu: true },
        children: [
            {
                path: '',
                name: 'InboxDocumentList',
                component: DocumentList
            },
            {
                path: ':humanReadableId',
                name: 'InboxDocumentDetails',
                component: DocumentDetails
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Customer, ApplicationRole.Employee]);
        },
        redirect: to => {
            return { name: 'InboxDocumentList' };
        }
    },
    {
        path: '/Outbox',
        name: 'Outbox',
        component: Requests,
        meta: { routeIcon: RouteIcons.outbox, showInMenu: true },
        children: [
            {
                path: '',
                name: 'OutboxDocumentList',
                component: DocumentList
            },
            {
                path: ':humanReadableId',
                name: 'OutboxDocumentDetails',
                component: DocumentDetails
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Customer, ApplicationRole.Employee]);
        },
        redirect: to => {
            return { name: 'OutboxDocumentList' };
        }
    },
    {
        path: '/Archive',
        name: 'Archive',
        component: Requests,
        meta: { routeIcon: RouteIcons.archive, showInMenu: true },
        children: [
            {
                path: '',
                name: 'ArchiveDocumentList',
                component: DocumentList
            },
            {
                path: ':humanReadableId',
                name: 'ArchiveDocumentDetails',
                component: DocumentDetails
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Customer, ApplicationRole.Employee]);
        },
        redirect: to => {
            return { name: 'ArchiveDocumentList' };
        }
    },
    {
        path: '/AcceptedProducts',
        name: 'Accepted Products',
        component: Items,
        meta: { routeIcon: RouteIcons.items, showInMenu: true },
        children: [
            {
                path: '',
                name: 'AcceptedItems',
                component: AcceptedItemList
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Customer]);
        },
        redirect: to => {
            return { name: 'AcceptedItems' };
        }
    }
];
export const EmployeeRoutes = ([
    {
        path: '/',
        name: 'Home',
        component: TaskOrderManagementSystemHome,
        meta: { routeIcon: RouteIcons.home, showInMenu: true },
        beforeEnter(to, from, next) {
            if (to.fullPath.includes('#state')) {
                next('/');
            }
            else {
                authorizeRoute(to, from, next, [ApplicationRole.Employee]);
            }
        }
    },
    {
        path: '/Requests',
        name: 'Requests',
        component: Requests,
        meta: { routeIcon: RouteIcons.inbox, showInMenu: false },
        children: [
            {
                path: '',
                name: 'RequestDocumentList',
                component: DocumentList
            },
            {
                path: ':humanReadableId',
                name: 'RequestsDocumentDetails',
                component: DocumentDetails
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Employee]);
        },
        redirect: to => {
            return { name: 'RequestDocumentList' };
        }
    }
]).concat(CustomerRoutes.filter((route) => {
    return route.name !== 'Home' && route.name !== 'Inspection Forms' && route.name !== 'Accepted Products';
}));
export const VendorRoutes = [
    {
        path: '/',
        name: 'Home',
        component: VendorHome,
        meta: { routeIcon: RouteIcons.home, showInMenu: true },
        beforeEnter(to, from, next) {
            if (to.fullPath.includes('#state')) {
                next('/');
            }
            else {
                authorizeRoute(to, from, next, [ApplicationRole.Vendor]);
            }
        }
    },
    {
        path: '/Inbox',
        name: 'Inbox',
        component: Requests,
        meta: { routeIcon: RouteIcons.inbox, showInMenu: true },
        children: [
            {
                path: '',
                name: 'InboxDocumentList',
                component: DocumentList
            },
            {
                path: ':humanReadableId',
                name: 'InboxDocumentDetails',
                component: DocumentDetails
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Vendor]);
        },
        redirect: to => {
            return { name: 'InboxDocumentList' };
        }
    },
    {
        path: '/Outbox',
        name: 'Outbox',
        component: Requests,
        meta: { routeIcon: RouteIcons.outbox, showInMenu: true },
        children: [
            {
                path: '',
                name: 'OutboxDocumentList',
                component: DocumentList
            },
            {
                path: ':humanReadableId',
                name: 'OutboxDocumentDetails',
                component: DocumentDetails
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Vendor]);
        },
        redirect: to => {
            return { name: 'OutboxDocumentList' };
        }
    },
    {
        path: '/Archive',
        name: 'Archive',
        component: Requests,
        meta: { routeIcon: RouteIcons.archive, showInMenu: true },
        children: [
            {
                path: '',
                name: 'ArchiveDocumentList',
                component: DocumentList
            },
            {
                path: ':humanReadableId',
                name: 'ArchiveDocumentDetails',
                component: DocumentDetails
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Vendor]);
        },
        redirect: to => {
            return { name: 'ArchiveDocumentList' };
        }
    },
    {
        path: '/AcceptedProducts',
        name: 'Accepted Products',
        component: Items,
        meta: { routeIcon: RouteIcons.items, showInMenu: true },
        children: [
            {
                path: '',
                name: 'AcceptedItems',
                component: AcceptedItemList
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Vendor]);
        },
        redirect: to => {
            return { name: 'AcceptedItems' };
        }
    }
];
export const AdministratorRoutes = [
    {
        path: '/',
        name: 'Home',
        component: TaskOrderManagementSystemHome,
        meta: { routeIcon: RouteIcons.home, showInMenu: true },
        beforeEnter(to, from, next) {
            if (to.fullPath.includes('#state')) {
                next('/');
            }
            else {
                authorizeRoute(to, from, next, [ApplicationRole.Employee]);
            }
        }
    },
    {
        path: '/Forms',
        name: 'Forms',
        component: Forms,
        meta: { routeIcon: RouteIcons.forms, showInMenu: true },
        children: [
            {
                path: 'PricingReviewRequest',
                name: 'Pricing Review/Vehicle Modification Request',
                component: PricingReviewRequest,
                meta: { routeIcon: RouteIcons.pricingReviewRequest },
                beforeEnter(to, from, next) {
                    authorizeRoute(to, from, next, [ApplicationRole.Customer]);
                }
            },
            {
                path: 'RepairReviewRequest',
                name: 'Repair Review Request',
                component: RepairReviewRequest,
                meta: { routeIcon: RouteIcons.repairReviewRequest },
                beforeEnter(to, from, next) {
                    authorizeRoute(to, from, next, [ApplicationRole.Customer]);
                }
            },
            {
                path: 'VehicleInspectionRequest',
                name: 'Vehicle Inspection Request',
                component: VehicleInspectionRequest,
                meta: { routeIcon: RouteIcons.vehicleInspectionRequest },
                beforeEnter(to, from, next) {
                    authorizeRoute(to, from, next, [ApplicationRole.Customer]);
                }
            },
            {
                path: 'CarFaxRequest',
                name: 'Car Fax Request',
                component: CarFaxRequest,
                meta: { routeIcon: RouteIcons.carFaxRequest },
                beforeEnter(to, from, next) {
                    authorizeRoute(to, from, next, [ApplicationRole.Customer]);
                }
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Customer]);
        }
    },
    {
        path: '/Requests',
        name: 'Requests',
        component: Requests,
        meta: { routeIcon: RouteIcons.inbox, showInMenu: true },
        children: [
            {
                path: '',
                name: 'RequestDocumentList',
                component: DocumentList
            },
            {
                path: ':humanReadableId',
                name: 'RequestsDocumentDetails',
                component: DocumentDetails
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, []);
        },
        redirect: to => {
            return { name: 'RequestDocumentList' };
        }
    },
    {
        path: '/Archive',
        name: 'Archive',
        component: Requests,
        meta: { routeIcon: RouteIcons.archive, showInMenu: true },
        children: [
            {
                path: '',
                name: 'ArchiveDocumentList',
                component: DocumentList
            },
            {
                path: ':humanReadableId',
                name: 'ArchiveDocumentDetails',
                component: DocumentDetails
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, [ApplicationRole.Employee]);
        },
        redirect: to => {
            return { name: 'ArchiveDocumentList' };
        }
    },
    {
        path: '/Equipment',
        name: 'Equipment',
        component: Items,
        meta: { routeIcon: RouteIcons.items, showInMenu: true },
        children: [
            {
                path: 'Accepted',
                name: 'AcceptedItems',
                component: AcceptedItemList
            },
            {
                path: 'Accepted/:humanReadableId',
                name: 'AcceptedItem',
                component: AcceptedItem
            },
            {
                path: 'Manufacturers',
                name: 'Manufacturers',
                component: ManufacturerList
            },
            {
                path: 'Cases',
                name: 'Cases',
                component: CaseItemList
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, []);
        },
        redirect: to => {
            return { name: 'AcceptedItems' };
        }
    },
    {
        path: '/Vehicles',
        name: 'Vehicles',
        component: Vehicles,
        meta: { routeIcon: RouteIcons.vehicles, showInMenu: true },
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, []);
        }
    },
    {
        path: '/Accounts',
        name: 'Accounts',
        component: Accounts,
        meta: { routeIcon: RouteIcons.accounts, showInMenu: true },
        children: [
            {
                path: 'Customers',
                name: 'Customers',
                component: CustomerList
            },
            {
                path: 'Vendors',
                name: 'Vendors',
                component: VendorList
            },
            {
                path: 'Employees',
                name: 'Employees',
                component: EmployeeList
            },
            {
                path: 'Organizations',
                name: 'Organizations',
                component: OrganizationList
            },
            {
                path: 'Groups',
                name: 'Groups',
                component: GroupList
            }
        ],
        beforeEnter(to, from, next) {
            authorizeRoute(to, from, next, []);
        },
        redirect: to => {
            return { name: 'Customers' };
        }
    }
];
export const AccessDeniedRoute = {
    path: '/',
    name: 'Home',
    component: Error,
    props: { title: 'Access Denied', subTitle: '', errorMessage: 'Please contact the administrator of the site to request access.' },
    meta: { routeIcon: null, showInMenu: false }
};
export const UnauthorizedRoute = {
    path: '/',
    name: 'Home',
    component: Error,
    props: { title: 'Access Denied', subTitle: 'Your account is not authorized to use this site.', errorMessage: 'Please contact the administrator of the site to request access.' },
    meta: { routeIcon: null, showInMenu: false }
};
export const UnauthenticatedRoute = {
    path: '/',
    name: 'Home',
    component: Error,
    props: { title: 'Access Denied', subTitle: 'Your account could not be authenticated.', errorMessage: 'Please contact the administrator of the site if the issue persists.' },
    meta: { routeIcon: null, showInMenu: false }
};
export const MaintenanceRoute = {
    path: '/Maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: { routeIcon: null, showInMenu: false }
};
const routes = [
    UnauthenticatedRoute,
    MaintenanceRoute,
    {
        path: '/Error',
        name: 'Error',
        component: Error,
        meta: { routeIcon: null, showInMenu: false }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Unknown',
        meta: { routeIcon: null, showInMenu: false },
        redirect: to => {
            return { path: '/' };
        }
    }
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // return desired position
        if (savedPosition) {
            return savedPosition;
        }
        else {
            return { left: 0, top: 0 };
        }
    }
});
export default router;
