export var CaseItemPartyApproval;
(function (CaseItemPartyApproval) {
    CaseItemPartyApproval[CaseItemPartyApproval["none"] = 0] = "none";
    CaseItemPartyApproval[CaseItemPartyApproval["productsAdmin"] = 1] = "productsAdmin";
    CaseItemPartyApproval[CaseItemPartyApproval["vendor"] = 2] = "vendor";
    CaseItemPartyApproval[CaseItemPartyApproval["productsAdminVendor"] = 3] = "productsAdminVendor";
    CaseItemPartyApproval[CaseItemPartyApproval["customer"] = 4] = "customer";
    CaseItemPartyApproval[CaseItemPartyApproval["productsAdminVendorCustomer"] = 7] = "productsAdminVendorCustomer";
    CaseItemPartyApproval[CaseItemPartyApproval["tti"] = 8] = "tti";
})(CaseItemPartyApproval || (CaseItemPartyApproval = {}));
export var CaseItemPartyApprovalString;
(function (CaseItemPartyApprovalString) {
    CaseItemPartyApprovalString[CaseItemPartyApprovalString["None"] = 0] = "None";
    CaseItemPartyApprovalString[CaseItemPartyApprovalString["Products Administrators"] = 1] = "Products Administrators";
    CaseItemPartyApprovalString[CaseItemPartyApprovalString["Vendor"] = 2] = "Vendor";
    CaseItemPartyApprovalString[CaseItemPartyApprovalString["Products Administrators and Vendor"] = 3] = "Products Administrators and Vendor";
    CaseItemPartyApprovalString[CaseItemPartyApprovalString["Customer"] = 4] = "Customer";
    CaseItemPartyApprovalString[CaseItemPartyApprovalString["Products Administrator, Vendor, and Customer"] = 7] = "Products Administrator, Vendor, and Customer";
    CaseItemPartyApprovalString[CaseItemPartyApprovalString["TTI"] = 8] = "TTI";
    CaseItemPartyApprovalString[CaseItemPartyApprovalString["Vendor and Customer"] = 6] = "Vendor and Customer";
    CaseItemPartyApprovalString[CaseItemPartyApprovalString["Products Administrators and Customer"] = 5] = "Products Administrators and Customer";
})(CaseItemPartyApprovalString || (CaseItemPartyApprovalString = {}));
