import { defineComponent } from 'vue';
import { helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { Editor, EditorUtils } from '@progress/kendo-vue-editor';
// A function for determining if the html has inner text
const validateRequiredInput = (isRequired) => (value) => {
    if (!isRequired)
        return true;
    const trimmedValue = value.replace(/\s/g, '');
    return !(trimmedValue === '' ||
        trimmedValue === '<p><br></p>');
};
export default defineComponent({
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Editor
    },
    props: {
        addClass: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: false
        },
        modelValue: {
            type: String,
            required: false,
            default: null
        },
        height: {
            type: Number,
            required: false,
            default: 200
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            tools: [
                ['Bold', 'Italic', 'Underline'],
                ['AlignLeft', 'AlignCenter', 'AlignRight'],
                ['OrderedList', 'UnorderedList']
            ]
        };
    },
    methods: {
        onChange(event) {
            this.$emit('update:modelValue', event.html);
        },
        onPasteHtml: function (e) {
            const pasteSettings = {
                convertMsLists: true,
                // stripTags: 'span|font'
                attributes: {
                    class: EditorUtils.sanitizeClassAttr,
                    style: EditorUtils.sanitizeStyleAttr,
                    width: () => {
                        return;
                    },
                    height: () => {
                        return;
                    },
                    src: () => {
                        return;
                    },
                    '*': EditorUtils.removeAttribute
                }
            };
            let html = EditorUtils.pasteCleanup(EditorUtils.sanitize(e.pastedHtml), pasteSettings);
            if (e.event.clipboardData) {
                html = EditorUtils.replaceImageSourcesFromRtf(html, e.event.clipboardData);
            }
            return html;
        }
    },
    validations() {
        const validator = helpers.withMessage('The value is required', validateRequiredInput(this.isRequired));
        return {
            modelValue: { validator }
        };
    }
});
