import { defineComponent, ref } from 'vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppUploadDocumentAttachmentsWithNotes from '@/components/shared/AppUploadDocumentAttachmentsWithNotes.vue';
import VehicleInspectionSheet from '@/components/document/documentManagement/VehicleInspectionSheet.vue';
import VehicleInspectionReport from '@/components/document/documentManagement/VehicleInspectionReport.vue';
export default defineComponent({
    emits: ['disableSubmit', 'enableSubmit'],
    components: {
        BSButton,
        AppUploadDocumentAttachmentsWithNotes,
        VehicleInspectionSheet,
        VehicleInspectionReport
    },
    props: {
        documentId: {
            required: true,
            type: String
        },
        submissionProgress: {
            required: true,
            type: Number
        },
        isSubmitting: {
            required: true,
            type: Boolean
        }
    },
    setup() {
        const vehicleInspectionSheet = ref();
        const vehicleInspectionReport = ref();
        const vehicleInspectionPhotos = ref();
        const vehicleInspectionSupportingDocuments = ref();
        return { vehicleInspectionSheet, vehicleInspectionReport, vehicleInspectionPhotos, vehicleInspectionSupportingDocuments };
    },
    data() {
        return {
            activeTab: 'supportingDocuments'
        };
    },
    methods: {
        tabChange(tabId) {
            this.activeTab = tabId;
        }
    }
});
