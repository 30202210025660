import AveHttpService from '@/services/AveHttpService';
class UsersService {
    /**
     * @description Logs user in
     */
    loginUser() {
        return AveHttpService.get('api/v1/users/login')
            .then(r => r.data);
    }
    /**
     * @description Retrieves the currently logged in user's data
     */
    getCurrentUser() {
        return AveHttpService.get('api/v1/users/current')
            .then(r => r.data);
    }
    /**
     * @description Retrieves the user with the specified id (email address or guid id)
     */
    getUser(id) {
        return AveHttpService.get(`api/v1/users/${id}`)
            .then(r => r.data);
    }
    /**
     * @description Retrieves the user account with the specified email address
     */
    getUserAccount(id) {
        return AveHttpService.get(`api/v1/users/${id}/account`)
            .then(r => r.data);
    }
    /**
     * @description Retrieves the list of user accounts
     */
    getUserAccounts(filter) {
        return AveHttpService.get('api/v1/users', { params: filter }).then((response) => {
            return response.data;
        });
    }
    /**
     * @description Retrieves the list of user's permissions separated by group and user level for specirfic document type and status
     */
    getUserDocumentPermissions(userId, data) {
        return AveHttpService.get(`api/v1/users/${userId}/documentPermissions`, { params: data }).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Adds a user account
     */
    addUserAccount(data) {
        return AveHttpService.post('api/v1/users/add', data).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Updates user's account information
     */
    updateUserAccount(userId, data) {
        return AveHttpService.patch(`api/v1/users/update/${userId}`, data).then((response) => {
            return response.data;
        });
    }
    /**
     *  @description Retrieves the generated user accounts report in pdf form
     */
    generateUserAccountsReportPdf(roleId) {
        const roleRoute = roleId !== null ? `/${roleId}` : '';
        return AveHttpService.get(`api/v1/users${roleRoute}/userAccountsReport/generate`, { responseType: 'arraybuffer' }).then((response) => {
            return {
                blob: new Blob([response.data], { type: 'application/pdf' }),
                name: (response.headers['content-disposition'].split('filename=')[1].split(';')[0]).replace(/['"]+/g, '')
            };
        })
            .catch(error => {
            throw new TextDecoder('utf-8').decode(new Uint8Array(error.response.data)).replace(/['"]+/g, '');
        });
    }
    /**
     * @description Retrieves a list of users that can have the document assigned to them when it is in a specific status
     */
    getStatusUsers(documentTypeId, documentStatusId) {
        return AveHttpService.get(`api/v1/users/documentTypeStatusPermissions/${documentTypeId}/${documentStatusId}`)
            .then(r => r.data);
    }
    /**
     * @description Retrieves a list of contacts for the application
     */
    getContacts(userId) {
        return AveHttpService.get(`api/v1/users/${userId}/contacts`)
            .then(r => r.data);
    }
    /**
     *  @description Updates user's current role
     */
    updateUserRole(roleId) {
        return AveHttpService.patch(`api/v1/users/updaterole/${roleId}`)
            .then(response => {
            return response;
        });
    }
}
export default new UsersService();
