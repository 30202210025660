import { defineComponent } from 'vue';
import SiteHeader from '@/components/layout/SiteHeader.vue';
import SiteFooter from '@/components/layout/SiteFooter.vue';
export default defineComponent({
    inject: ['siteIsInMaintenance', 'maintenanceMessage'],
    name: 'PublicApp',
    components: {
        SiteHeader,
        SiteFooter
    },
    created() {
        document.title = 'Roadside Safety and Physical Security Division';
        if (this.siteIsInMaintenance && this.$route.name !== 'Maintenance') {
            this.$router.replace({ name: 'Maintenance' });
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.$store.state.window.width = window.innerWidth;
            this.$store.state.window.height = window.innerHeight;
        });
    }
});
