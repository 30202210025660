import { defineComponent } from 'vue';
import { uuid } from 'vue-uuid';
export default defineComponent({
    el: '.form-control',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            required: false,
            type: String,
            default: null
        },
        label: {
            required: false,
            type: String,
            default: null
        },
        id: {
            required: false,
            type: String,
            default: null
        },
        name: {
            required: false,
            type: String,
            default: null
        },
        placeholder: {
            required: false,
            type: String,
            default: null
        },
        required: {
            required: false,
            type: Boolean,
            default: false
        },
        maxLength: {
            required: false,
            type: Number,
            default: null
        },
        minLength: {
            required: false,
            type: Number,
            default: null
        },
        readOnly: {
            required: false,
            type: Boolean,
            default: false
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        cols: {
            required: false,
            type: Number,
            default: 50
        },
        rows: {
            required: false,
            type: Number,
            default: 2
        },
        validate: {
            required: false,
            type: Boolean,
            default: false
        },
        showInvalidMessage: {
            required: false,
            type: Boolean,
            default: false
        },
        indicateIfValid: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uniqueId: ''
        };
    },
    computed: {
        hasLabel() {
            return this.label !== null && this.label !== '';
        }
    },
    methods: {
        validation(e) {
            e.classList.remove('is-invalid');
            e.classList.remove('is-valid');
            if (this.validate) {
                this.$inputValidationUtilities.validateInput(e, this.indicateIfValid, this.showInvalidMessage, this.showInvalidMessage, true);
            }
        }
    },
    created() {
        this.uniqueId = this.id ?? uuid.v4();
    }
});
