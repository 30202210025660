import { defineComponent } from 'vue';
import AppCardRouterButton from '@/components/shared/AppCardRouterButton.vue';
import { CustomerRoutes } from '@/router/index';
export default defineComponent({
    name: 'CustomerHome',
    components: {
        AppCardRouterButton
    },
    computed: {
        routes() {
            return CustomerRoutes.filter((route) => {
                return route.name !== 'Home';
            });
        }
    }
});
