import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BSProgress',
    props: {
        progressObjects: {
            required: true,
            type: Object
        },
        striped: {
            required: false,
            type: Boolean,
            default: false
        },
        animated: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    el: '.progress',
    computed: {
        classes() {
            const classList = [];
            if (this.striped) {
                classList.push('progress-bar-striped');
            }
            if (this.animated) {
                classList.push('progress-bar-animated');
            }
            return classList;
        }
    }
});
