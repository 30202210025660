import { createStore } from 'vuex';
import UsersService from '@/services/UsersService';
export default createStore({
    state: {
        user: null,
        delegateUser: null,
        window: {
            width: window.innerWidth,
            height: window.innerHeight
        }
    },
    getters: {
        user: state => {
            return state.user;
        },
        delegateUser: state => {
            return state.delegateUser;
        },
        hasDelegateUser: state => {
            return state.delegateUser !== null;
        },
        userIsEmpty: state => {
            return state.user === null;
        },
        userIsAuthorized: state => {
            if (state.user !== null) {
                return state.user.isAuthorized && state.user.roles.length > 0 && state.user.currentRoleId !== '';
            }
            return false;
        },
        currentRoleId: state => {
            return state.user !== null ? state.user.currentRoleId.toUpperCase() : '';
        }
    },
    mutations: {
        setUser(state, userData) {
            state.user = userData;
        },
        newDelegateUser(state, userData) {
            state.delegateUser = userData;
        },
        setRole(state) {
            const newRoleId = document.cookie.split('; ').find((row) => row.startsWith('user-active-role='))?.split('=')[1];
            if (newRoleId && state.user) {
                state.user.currentRoleId = newRoleId ?? '';
            }
        }
    },
    actions: {
        async login({ commit }) {
            try {
                await UsersService.loginUser()
                    .then(async () => {
                    await UsersService.getCurrentUser()
                        .then(response => {
                        commit('setUser', {
                            id: response.id,
                            organizationName: response.organizationName,
                            emailAddress: response.emailAddress,
                            fullName: response.fullName,
                            currentRoleId: '',
                            roles: response.roles,
                            documentStatusPermissionIds: response.documentStatusPermissionIds,
                            isAuthorized: response.isAuthorized,
                            isTWC: response.isTWC
                        });
                    })
                        .catch(error => {
                        throw error;
                    });
                })
                    .catch(error => {
                    throw error;
                });
            }
            catch {
                await this.dispatch('emptyUser');
            }
            finally {
                commit('setRole');
            }
        },
        async emptyUser({ commit }) {
            commit('setUser', {
                id: '',
                organizationName: '',
                emailAddress: '',
                fullName: '',
                currentRoleId: '',
                roles: [],
                documentStatusPermissionIds: [],
                isAuthorized: false,
                isTWC: false
            });
        },
        changeRole({ commit }, roleId) {
            if (!roleId) {
                window.location.href = window.location.origin;
                return;
            }
            if (this.getters.currentRoleId.toLowerCase() !== roleId.toLowerCase()) {
                UsersService.updateUserRole(roleId)
                    .then(() => {
                    commit('setRole');
                })
                    .finally(() => {
                    window.location.href = window.location.origin;
                });
            }
        },
        async setDelegateUser({ commit }, userId) {
            try {
                const user = await UsersService.getUser(userId);
                commit('newDelegateUser', {
                    id: user.id,
                    organizationName: user.organizationName,
                    emailAddress: user.emailAddress,
                    fullName: user.fullName,
                    currentRoleId: '',
                    roles: user.roles,
                    documentStatusPermissionIds: user.documentStatusPermissionIds,
                    isAuthorized: user.isAuthorized,
                    isTWC: user.isTWC
                });
            }
            catch {
                await this.dispatch('emptyUser');
            }
        },
        async emptyDelegateUser({ commit }) {
            commit('newDelegateUser', null);
        }
    },
    modules: {}
});
