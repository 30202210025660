import { defineComponent } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
import { AcceptedItemsFilter } from '@/services/models/AcceptedItem';
import AppTable from '@/components/shared/AppTable.vue';
import AcceptedItemsService from '@/services/AcceptedItemsService';
import ManufacturersService from '@/services/ManufacturersService';
import BSModal from '@/components/shared/bootstrap/Modal.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppCheckbox from '@/components/shared/AppCheckbox.vue';
import AppSelect from '@/components/shared/AppSelect.vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import { ApplicationRole } from '@/services/models/Role';
export default defineComponent({
    components: {
        AppTable,
        BSModal,
        BSButton,
        AppInput,
        AppSelect,
        AppCheckbox,
        AppCollapsingCardWithButtons
    },
    data() {
        return {
            itemManufacturerFilterSelection: [],
            itemManufacturerSelection: [],
            conversionTypeSelection: [{ item: '(None)', value: 0 }, { item: 'Side Entry', value: 1 }, { item: 'Rear Entry', value: 2 }],
            acceptedItemList: [],
            filterOptions: new AcceptedItemsFilter(),
            submitButton: {
                text: 'Save',
                show: true
            },
            cancelButton: {
                text: 'Cancel',
                show: true
            },
            addModalKey: false,
            conversionTypeInitialValue: 0
        };
    },
    computed: {
        viewIsReadonly() {
            return this.$stringUtilities.compareIds(this.$store.getters.currentRoleId, ApplicationRole.Vendor) || this.$stringUtilities.compareIds(this.$store.getters.currentRoleId, ApplicationRole.Customer);
        }
    },
    methods: {
        /**
        * @description Loads the data from the API bound to the component
        */
        async loadData() {
            if (this.viewIsReadonly) {
                this.filterOptions.sortForVendors = true;
            }
            await AcceptedItemsService.getAcceptedItemList(this.filterOptions.options())
                .then(response => {
                if (!this.filterOptions.update({ totalItems: response.totalCount })) {
                    this.loadData();
                    return;
                }
                var acceptedItemList = [];
                response.data.forEach((x) => {
                    var newRow = {
                        route: {
                            text: x.humanReadableId,
                            to: {
                                name: 'AcceptedItem',
                                params: {
                                    humanReadableId: x.humanReadableId
                                }
                            }
                        },
                        id: x.id,
                        humanReadableId: x.humanReadableId,
                        itemManufacturer: x.itemManufacturer,
                        conversionTypeName: x.conversionTypeName,
                        itemDescription: x.itemDescription,
                        itemNumber: x.itemNumber,
                        acceptedPrice: x.acceptedPrice === null ? 'Contact PSART' : this.$stringUtilities.formattedCurrency(x.acceptedPrice),
                        isIncludedOnReport: x.isIncludedOnReport,
                        vehicles: this.viewIsReadonly ? x.vehicles : [],
                        conversionInformation: x.acceptedItemDetails.length > 0 ? x.acceptedItemDetails[0].detail : null
                    };
                    acceptedItemList.push(newRow);
                });
                if (this.viewIsReadonly) {
                    this.acceptedItemList = acceptedItemList.filter((item) => {
                        return item.isIncludedOnReport;
                    });
                    return;
                }
                this.acceptedItemList = acceptedItemList;
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        loadItemManufacturersFilterSelect() {
            ManufacturersService.getItemManufacturerList(this.viewIsReadonly ? {
                page: 1,
                pageSize: 10,
                returnAll: true,
                name: null,
                onlyAssignedToAcceptedItems: true,
                onlyViewableOnAcceptedProductsReport: true
            }
                : {
                    page: 1,
                    pageSize: 10,
                    returnAll: true,
                    name: null,
                    onlyAssignedToAcceptedItems: true,
                    onlyViewableOnAcceptedProductsReport: false
                })
                .then(response => {
                if (response.data) {
                    if (response.data.length === 0) {
                        this.itemManufacturerFilterSelection = [];
                        return;
                    }
                    this.itemManufacturerFilterSelection = response.data.map((e) => ({ item: e.name, value: e.id }));
                    this.itemManufacturerFilterSelection.unshift({ item: '(None)', value: null });
                    this.itemManufacturerFilterSelection.unshift({ item: 'All', value: 'all' });
                }
            });
        },
        loadItemManufacturersSelection() {
            ManufacturersService.getItemManufacturerList({
                page: 1,
                pageSize: 10,
                returnAll: true,
                name: null,
                onlyAssignedToAcceptedItems: false,
                onlyViewableOnAcceptedProductsReport: false
            })
                .then(response => {
                if (response.data) {
                    if (response.data.length === 0) {
                        this.itemManufacturerSelection = [];
                        return;
                    }
                    this.itemManufacturerSelection = response.data.map((e) => ({ item: e.name, value: e.id }));
                    this.itemManufacturerSelection.unshift({ item: '(None)', value: null });
                }
            });
        },
        updatePagination(page, pageSize) {
            this.filterOptions.update({ page: page, pageSize: pageSize });
            this.loadData();
        },
        generateAndDownloadAcceptedProductsReport(showPrices) {
            this.$loadingBackdrop.data.isVisible = true;
            AcceptedItemsService.generateAcceptedProductsReportPdf(showPrices)
                .then(response => {
                const data = window.URL.createObjectURL(response.blob);
                var anchor = document.createElement('a');
                anchor.download = response.name;
                anchor.href = data;
                anchor.click();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            })
                .finally(() => {
                this.$loadingBackdrop.data.isVisible = false;
            });
        },
        addAcceptedItem() {
            var form = document.forms.namedItem('addAcceptedItemForm');
            if (form !== null) {
                var formData = new FormData(form);
                AcceptedItemsService.addAcceptedItem(formData)
                    .then(response => {
                    Notifications.methods.addToast(ToastType.success, response.message);
                    this.$router.push({ name: 'AcceptedItem', params: { humanReadableId: response.humanReadableId } });
                })
                    .catch(error => {
                    Notifications.methods.addToast(ToastType.danger, error.response.data);
                });
            }
        },
        removeAcceptedItem(id) {
            AcceptedItemsService.removeAcceptedItem(id)
                .then(response => {
                Notifications.methods.addToast(ToastType.success, response);
                this.loadData();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        }
    },
    created() {
        this.loadData()
            .then(() => {
            this.loadItemManufacturersFilterSelect();
        });
    }
});
