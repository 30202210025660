export var OrganizationType;
(function (OrganizationType) {
    OrganizationType[OrganizationType["unknown"] = 0] = "unknown";
    OrganizationType[OrganizationType["active"] = 1] = "active";
    OrganizationType[OrganizationType["twc"] = 2] = "twc";
    OrganizationType[OrganizationType["twcActive"] = 3] = "twcActive";
    OrganizationType[OrganizationType["ilc"] = 4] = "ilc";
    OrganizationType[OrganizationType["ilcActive"] = 5] = "ilcActive";
    OrganizationType[OrganizationType["tti"] = 8] = "tti";
    OrganizationType[OrganizationType["vendor"] = 16] = "vendor";
    OrganizationType[OrganizationType["vendorActive"] = 17] = "vendorActive";
    OrganizationType[OrganizationType["customer"] = 32] = "customer";
    OrganizationType[OrganizationType["activeCustomer"] = 33] = "activeCustomer";
    OrganizationType[OrganizationType["twcCustomer"] = 34] = "twcCustomer";
    OrganizationType[OrganizationType["twcActiveCustomer"] = 35] = "twcActiveCustomer";
    OrganizationType[OrganizationType["ilcCustomer"] = 36] = "ilcCustomer";
    OrganizationType[OrganizationType["ilcActiveCustomer"] = 37] = "ilcActiveCustomer";
})(OrganizationType || (OrganizationType = {}));
export var DocumentFilterOrganizationType;
(function (DocumentFilterOrganizationType) {
    DocumentFilterOrganizationType[DocumentFilterOrganizationType["Texas Workforce Commission"] = 34] = "Texas Workforce Commission";
    DocumentFilterOrganizationType[DocumentFilterOrganizationType["Independent Living Center"] = 36] = "Independent Living Center";
})(DocumentFilterOrganizationType || (DocumentFilterOrganizationType = {}));
export var ExternalOrganizationTypeOption;
(function (ExternalOrganizationTypeOption) {
    ExternalOrganizationTypeOption[ExternalOrganizationTypeOption["Texas Workforce Commission"] = 34] = "Texas Workforce Commission";
    ExternalOrganizationTypeOption[ExternalOrganizationTypeOption["Indpendent Living Center"] = 36] = "Indpendent Living Center";
    ExternalOrganizationTypeOption[ExternalOrganizationTypeOption["Vendor"] = 16] = "Vendor";
})(ExternalOrganizationTypeOption || (ExternalOrganizationTypeOption = {}));
