import { defineComponent } from 'vue';
import UsersService from '@/services/UsersService';
import { DocumentStatus } from '@/services/models/shared/DocumentStatus';
export default defineComponent({
    props: {
        currentStatusId: {
            required: true,
            type: String
        },
        documentTypeId: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            employeeAssigneeUserId: null,
            assignEmployees: []
        };
    },
    methods: {
        loadData() {
            UsersService.getStatusUsers(this.documentTypeId, DocumentStatus.Processing)
                .then(response => {
                this.assignEmployees = response;
            });
        }
    },
    created() {
        this.loadData();
    }
});
