import { defineComponent } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import { CaseDocumentsFilter } from '@/services/models/Document';
import CasesService from '@/services/CasesService';
import Notifications from '@/hooks/Notifications';
import AppTable from '@/components/shared/AppTable.vue';
import { ApplicationRole } from '@/services/models/Role';
export default defineComponent({
    components: {
        AppTable
    },
    props: {
        caseId: {
            required: true,
            type: String
        },
        excludeDocumentId: {
            required: false,
            type: String,
            default: null
        }
    },
    data() {
        return {
            filter: new CaseDocumentsFilter(true),
            caseDocuments: [],
            refreshCaseDocuments: false,
            documentTypeStatusUserPermissions: null
        };
    },
    methods: {
        loadCaseDocuments() {
            CasesService.getCaseDocuments(this.caseId, this.filter.options())
                .then(response => {
                if (this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole['AVE Administrator'] || this.$store.getters.currentRoleId.toUpperCase() === ApplicationRole.Administrator) {
                    this.caseDocuments = response.map((c) => {
                        return {
                            route: {
                                text: c.humanReadableId,
                                to: {
                                    name: 'RequestsDocumentDetails',
                                    params: {
                                        id: c.id,
                                        humanReadableId: c.humanReadableId
                                    }
                                }
                            },
                            humanReadableId: c.humanReadableId,
                            documentType: c.documentType,
                            documentStatus: c.documentStatus,
                            employeeAssigneeUserFullName: c.employeeAssigneeUserFullName,
                            submitterFullName: c.submitterFullName,
                            submittedDate: c.submittedDate
                        };
                    });
                }
                else {
                    this.caseDocuments = response.map((c) => {
                        return {
                            route: {
                                text: c.humanReadableId,
                                to: {
                                    name: 'InboxDocumentDetails',
                                    params: {
                                        id: c.id,
                                        humanReadableId: c.humanReadableId
                                    }
                                }
                            },
                            humanReadableId: c.humanReadableId,
                            documentType: c.documentType,
                            documentStatus: c.documentStatus,
                            employeeAssigneeUserFullName: c.employeeAssigneeUserFullName,
                            submitterFullName: c.submitterFullName,
                            submittedDate: c.submittedDate
                        };
                    });
                }
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            })
                .finally(() => {
                this.refreshCaseDocuments = !this.refreshCaseDocuments;
            });
        }
    },
    created() {
        this.filter.excludeDocumentId = this.excludeDocumentId;
        this.loadCaseDocuments();
    }
});
