import { defineComponent } from 'vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppCheckbox from '@/components/shared/AppCheckbox.vue';
import AppTextArea from '@/components/shared/AppTextArea.vue';
export default defineComponent({
    name: 'WorksheetItem',
    emits: ['update:item'],
    components: {
        AppInput,
        AppCheckbox,
        AppTextArea
    },
    props: {
        itemCount: {
            required: true,
            type: Number
        },
        itemIndex: {
            required: true,
            type: Number
        },
        item: {
            required: true,
            type: Object
        },
        parentId: {
            required: false,
            type: String,
            default: ''
        }
    },
    data() {
        return {
            itemInternal: {}
        };
    },
    created() {
        this.itemInternal = this.item;
    }
});
