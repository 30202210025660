import axios from 'axios';
import store from '@/store/index';
const AveHttpService = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true
});
AveHttpService.defaults.headers.common.Accepts = 'application/json';
AveHttpService.interceptors.response.use(response => response, error => {
    let currentLocation = window.location.href;
    if (window.location.href.lastIndexOf('/') === window.location.href.length - 1) {
        currentLocation = currentLocation.slice(0, window.location.href.length - 1);
    }
    if (currentLocation !== window.location.origin && !currentLocation.toLowerCase().includes('error') && (error.response.status === 403 || error.response.status === 401)) {
        window.location.href = window.location.origin;
    }
    return Promise.reject(error);
});
AveHttpService.interceptors.request.use(config => {
    if (!config.url?.includes('login')) {
        const currentRoleId = document.cookie.split('; ').find((row) => row.startsWith('user-active-role='))?.split('=')[1];
        if (!currentRoleId) {
            store.dispatch('changeRole', currentRoleId);
        }
    }
    return config;
});
export default AveHttpService;
