import { defineComponent } from 'vue';
import Notifications from '@/hooks/Notifications';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import DocumentService from '@/services/DocumentService';
import AppTablePagination from '@/components/shared/AppTablePagination.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import BSAlert from '@/components/shared/bootstrap/Alert.vue';
export default defineComponent({
    components: {
        BSButton,
        BSAlert,
        AppTablePagination
    },
    props: {
        documentId: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            total: 0,
            isLoading: true,
            documentNotifications: [],
            filterOptions: {
                page: 1,
                pageSize: 10
            }
        };
    },
    methods: {
        loadData() {
            DocumentService.getDocumentNotifications(this.documentId, this.filterOptions)
                .then(response => {
                this.total = response.totalCount;
                this.documentNotifications = response.data;
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        downloadAttachment(id) {
            DocumentService.getDocumentAttachmentFile(id)
                .then(response => {
                const data = window.URL.createObjectURL(response.blob);
                var anchor = document.createElement('a');
                anchor.download = response.name;
                anchor.href = data;
                anchor.click();
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error);
            });
        },
        toggleIcon(e) {
            if (e.target.classList.contains('fa-angle-right')) {
                e.target.classList.remove('fa-angle-right');
                e.target.classList.add('fa-angle-down');
            }
            else {
                e.target.classList.remove('fa-angle-down');
                e.target.classList.add('fa-angle-right');
            }
        },
        refreshNotification(id) {
            DocumentService.getDocumentNotification(id)
                .then(response => {
                var notificationIndex = this.documentNotifications.findIndex((x) => {
                    return x.id.toLowerCase() === id.toLowerCase();
                });
                if (notificationIndex > -1) {
                    this.documentNotifications[notificationIndex] = response;
                }
            });
        },
        updatePagination(page, pageSize) {
            this.isLoading = true;
            this.filterOptions.page = page;
            this.filterOptions.pageSize = pageSize;
            this.loadData();
        }
    },
    mounted() {
        this.loadData();
    }
});
