import { defineComponent } from 'vue';
import { ToastType } from '@/services/models/shared/bootstrap/ToastType';
import Notifications from '@/hooks/Notifications';
import { CaseItemsFilter } from '@/services/models/CaseItem';
import AppTable from '@/components/shared/AppTable.vue';
import BSButton from '@/components/shared/bootstrap/Button.vue';
import AppInput from '@/components/shared/AppInput.vue';
import AppCollapsingCardWithButtons from '@/components/shared/AppCollapsingCardWithButtons.vue';
import CasesItemsService from '@/services/CasesItemsService';
export default defineComponent({
    components: {
        AppTable,
        BSButton,
        AppCollapsingCardWithButtons,
        AppInput
    },
    data() {
        return {
            caseItemList: [],
            filterOptions: new CaseItemsFilter(false)
        };
    },
    methods: {
        /**
        * @description Loads the data from the API bound to the component
        */
        loadData() {
            CasesItemsService.getCaseItemList(this.filterOptions.options())
                .then(response => {
                if (!this.filterOptions.update({ totalItems: response.totalCount })) {
                    this.loadData();
                    return;
                }
                this.caseItemList = response.data;
            })
                .catch(error => {
                Notifications.methods.addToast(ToastType.danger, error.response.data);
            });
        },
        updatePagination(page, pageSize) {
            this.filterOptions.update({ page: page, pageSize: pageSize });
            this.loadData();
        }
    },
    created() {
        this.loadData();
    }
});
