import * as Msal from '@azure/msal-browser';
import { Configuration as AVEConfiguration } from '@/Configuration';
class AuthenticationService {
    loginRequest;
    loginRedirectRequest;
    config = {
        auth: {
            clientId: AVEConfiguration.OpenIdConnect.ClientId,
            authority: 'https://' + AVEConfiguration.OpenIdConnect.Hostname + '/' + AVEConfiguration.OpenIdConnect.Tenant + '/' + AVEConfiguration.OpenIdConnect.PolicySignIn,
            redirectUri: AVEConfiguration.OpenIdConnect.RedirectUrl,
            navigateToLoginRequestUrl: true,
            postLogoutRedirectUri: 'https://tti.tamu.edu',
            knownAuthorities: [AVEConfiguration.OpenIdConnect.Hostname]
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
        }
    };
    msalApp = new Msal.PublicClientApplication(this.config);
    constructor() {
        this.loginRequest = {
            scopes: [`https://${AVEConfiguration.OpenIdConnect.Tenant}/${AVEConfiguration.OpenIdConnect.ClientId}/${AVEConfiguration.OpenIdConnect.RequestScope}`]
        };
        this.loginRedirectRequest = {
            scopes: [`https://${AVEConfiguration.OpenIdConnect.Tenant}/${AVEConfiguration.OpenIdConnect.ClientId}/${AVEConfiguration.OpenIdConnect.RequestScope}`]
        };
    }
    async authenticate() {
        const users = this.msalApp.getAllAccounts();
        if (users.length > 0) {
            this.msalApp.setActiveAccount(users[0]);
        }
        const user = this.msalApp.getActiveAccount();
        if (!user) {
            try {
                const authResponse = await this.msalApp.handleRedirectPromise();
                if (authResponse && authResponse.accessToken) {
                    // login the user to AVE
                    if (authResponse.accessToken === null || authResponse.accessToken === '') {
                        return Promise.reject(new Error('Unauthenticated'));
                    }
                    return {
                        accessToken: authResponse.accessToken,
                        username: authResponse.account?.username === undefined ? null : authResponse.account?.username
                    };
                }
                else {
                    await this.msalApp.loginRedirect(this.loginRedirectRequest);
                }
            }
            catch (authErr) {
                return Promise.reject(authErr);
            }
        }
        else {
            try {
                const authResponse = await this.msalApp.acquireTokenSilent(this.loginRequest);
                // login the user to AVE
                if (authResponse.accessToken === null || authResponse.accessToken === '') {
                    return Promise.reject(new Error('Unauthenticated'));
                }
                return {
                    accessToken: authResponse.accessToken,
                    username: authResponse.account?.username === undefined ? null : authResponse.account?.username
                };
            }
            catch {
                await this.msalApp.acquireTokenRedirect(this.loginRedirectRequest);
            }
        }
        return Promise.reject(new Error('Unauthenticated'));
    }
    /**
    * @description Returns the API access token for the given scopes
    */
    async getApiAccessToken() {
        const user = this.msalApp.getActiveAccount();
        if (!user) {
            await this.msalApp.loginRedirect(this.loginRedirectRequest);
        }
        // First attempt to get the token silently
        try {
            const authResponse = await this.msalApp.acquireTokenSilent(this.loginRequest);
            // login the user to AVE
            return authResponse;
        }
        catch {
            // Attempt to get the token through a redirect
            await this.msalApp.acquireTokenRedirect(this.loginRedirectRequest);
        }
    }
    logout() {
        this.msalApp.logoutRedirect();
    }
}
export default new AuthenticationService();
