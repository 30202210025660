import { defineComponent } from 'vue';
import SiteRoles from '@/components/layout/SiteRoles.vue';
export default defineComponent({
    emits: ['logout'],
    components: {
        SiteRoles
    },
    props: {
        routeList: {
            required: false,
            default: []
        }
    },
    computed: {
        userIsLoggedIn() {
            return this.$store.getters.userIsAuthorized;
        }
    }
});
