import { defineComponent } from 'vue';
import AppNotifications from '@/components/shared/AppNotifications.vue';
import SiteFooter from '@/components/layout/SiteFooter.vue';
import SiteHeader from '@/components/layout/SiteHeader.vue';
import { VendorRoutes } from '@/router/index';
export default defineComponent({
    inject: ['siteIsInMaintenance', 'maintenanceMessage'],
    name: 'VendorApp',
    components: {
        SiteHeader,
        SiteFooter,
        AppNotifications
    },
    computed: {
        hasUserData() {
            return this.$store.getters.userIsEmpty;
        },
        routes() {
            if (this.siteIsInMaintenance) {
                return [];
            }
            return VendorRoutes;
        }
    },
    created() {
        document.title = 'Adaptive Vehicles and Equipment';
        if (this.siteIsInMaintenance && this.$route.name !== 'Maintenance') {
            this.$router.replace({ name: 'Maintenance' });
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.$store.state.window.width = window.innerWidth;
            this.$store.state.window.height = window.innerHeight;
        });
    }
});
