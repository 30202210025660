export var DocumentAttachmentType;
(function (DocumentAttachmentType) {
    DocumentAttachmentType["ttiReviewSA"] = "1c12babb-5708-4491-8999-10985b28ef5b";
    DocumentAttachmentType["driverEvaluation"] = "657eaf7f-9a6b-44da-a4e9-8e6270b0b505";
    DocumentAttachmentType["carFaxReport"] = "25898b25-35ab-4be4-b10d-90fa4c089950";
    DocumentAttachmentType["driverLicense"] = "253e3774-7739-4f59-a3ff-aee1ce4c3bb1";
    DocumentAttachmentType["notes"] = "9619ecd5-89d4-4979-8f6d-c37a93b7409d";
    DocumentAttachmentType["twcIlc3408"] = "3b721fbb-30f1-4d4b-962c-d4e8f49c1287";
    DocumentAttachmentType["mechanicEvaluation"] = "309e96dd-9378-4b1b-8f21-dd3d1426a9ed";
    DocumentAttachmentType["fcaMedicalDocumentation"] = "f3e2cc43-781d-48de-ac5a-e0e83c725dc3";
    DocumentAttachmentType["vendorEstimate"] = "8773c5eb-5d8f-4a1a-bca0-0b4e489bbba8";
    DocumentAttachmentType["reviewLetter"] = "bfe806dc-6a46-43b0-8f12-c2b5b0d1ea22";
    DocumentAttachmentType["invoice"] = "5d6debf6-e1bb-4191-8316-208e2c6fd9db";
    DocumentAttachmentType["ttiInspectionSA"] = "4ca6a9bc-5c1f-4333-abca-a6984ba19bfd";
    DocumentAttachmentType["inspectionSheet"] = "0238924f-ecd6-4af9-8d37-7911e1037a14";
    DocumentAttachmentType["inspectionReport"] = "2e610ca1-4224-498d-b1be-7bc0935c3dea";
    DocumentAttachmentType["inspectionPhoto"] = "1ae96a8f-bff8-4152-9192-6bd7a6455db6";
    DocumentAttachmentType["supportingDocument"] = "62555093-88a1-4e56-82db-aec6add905d4";
    DocumentAttachmentType["changeRequestDocumentation"] = "bf098833-9543-4d29-9530-b9c28398b857";
})(DocumentAttachmentType || (DocumentAttachmentType = {}));
export var DocumentAttachmentTypeOption;
(function (DocumentAttachmentTypeOption) {
    DocumentAttachmentTypeOption["TTI Review SA"] = "1c12babb-5708-4491-8999-10985b28ef5b";
    DocumentAttachmentTypeOption["Driver Evaluation"] = "657eaf7f-9a6b-44da-a4e9-8e6270b0b505";
    DocumentAttachmentTypeOption["CarFax Report"] = "25898b25-35ab-4be4-b10d-90fa4c089950";
    DocumentAttachmentTypeOption["Driver License"] = "253e3774-7739-4f59-a3ff-aee1ce4c3bb1";
    DocumentAttachmentTypeOption["Notes"] = "9619ecd5-89d4-4979-8f6d-c37a93b7409d";
    DocumentAttachmentTypeOption["Mechanic Evalution"] = "309e96dd-9378-4b1b-8f21-dd3d1426a9ed";
    DocumentAttachmentTypeOption["FCA or Medical Documentation"] = "f3e2cc43-781d-48de-ac5a-e0e83c725dc3";
    DocumentAttachmentTypeOption["Review Letter"] = "bfe806dc-6a46-43b0-8f12-c2b5b0d1ea22";
    DocumentAttachmentTypeOption["Invoice"] = "5d6debf6-e1bb-4191-8316-208e2c6fd9db";
    DocumentAttachmentTypeOption["TTI Inspection SA"] = "4ca6a9bc-5c1f-4333-abca-a6984ba19bfd";
    DocumentAttachmentTypeOption["Inspection Sheets"] = "0238924f-ecd6-4af9-8d37-7911e1037a14";
    DocumentAttachmentTypeOption["Inspection Report"] = "2e610ca1-4224-498d-b1be-7bc0935c3dea";
    DocumentAttachmentTypeOption["Inspection Photo"] = "1ae96a8f-bff8-4152-9192-6bd7a6455db6";
    DocumentAttachmentTypeOption["Support Document"] = "62555093-88a1-4e56-82db-aec6add905d4";
    DocumentAttachmentTypeOption["Change Request Documentation"] = "bf098833-9543-4d29-9530-b9c28398b857";
})(DocumentAttachmentTypeOption || (DocumentAttachmentTypeOption = {}));
