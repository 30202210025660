import { defineComponent } from 'vue';
import { Toast } from 'bootstrap';
export default defineComponent({
    name: 'BSToast',
    emits: ['destroyToast'],
    props: {
        toastType: {
            required: true,
            type: String
        },
        message: {
            required: true,
            type: String
        },
        title: {
            required: false,
            type: String,
            default: ''
        }
    },
    data() {
        return {
            thisToast: Object,
            options: {
                animation: true,
                autohide: true,
                delay: 10000
            }
        };
    },
    mounted() {
        this.thisToast = new Toast(this.$el, this.options);
        this.thisToast.show();
        this.$el.addEventListener('hidden.bs.toast', (e) => {
            this.$emit('destroyToast');
        });
    }
});
